// @TODO: i18n
/* @TODO: {
  all of these need to be updated from tibbits to wrinkly
  add section for study tips
    say answers out lound even if it's fill in the blank
  add section for keeping in mind the end goal and not worrying about points
  add section for known issues (section headers in niv 2011 and let us know, and we'll remove them)
  add section for quizzing
} */
/**
 * Ryan O'Dowd
 * 2021-07-05
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../Globals';
import React from 'react';
import getStyles from './styles';
import oakGlobalObject from '../../common/utilities/oakGlobalObject';

const styles = Globals.platform === 'web' ? getStyles : getStyles();

// @TODO: images can be added to a lot of these (animated gifs) to make it easier to understand (host these on wrink.ly, though, so that the bundle size of the app doesn't bloat...and make sure there are two sets--one for android, and one for ios
// @TODO: add help docs for partners (only visible if they're logged in, and put at the top of the docs...actually, make them tab nav)
const faqs = (navigation, isAnonymousUser, Linking, Platform, View, PText, SpanText) => oakGlobalObject({
  sections: [
    //    {
    //      title: 'Getting Started',
    //      data: [
    //        {
    //          question: 'The basics',
    //          answer: (
    //            <View>
    //              {/* @TODO: update this */}
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Goals</SpanText> are collections of <SpanText style={styles.bold}>Passages</SpanText>.  The active <SpanText style={styles.bold}>Goal</SpanText> is displayed on the <SpanText style={styles.bold}>Home</SpanText> screen where you can start <SpanText style={styles.bold}>Practice</SpanText>, listen to <SpanText style={styles.bold}>Audio</SpanText>, and take <SpanText style={styles.bold}>Tests</SpanText>.  Keep your <SpanText style={styles.bold}>Streak</SpanText> alive by earning <SpanText style={styles.bold}>Peanuts</SpanText> everyday.  When you feel comfortable with all of your <SpanText style={styles.bold}>Practice</SpanText>, take a <SpanText style={styles.bold}>Test</SpanText> to earn <SpanText style={styles.bold}>Wrinkles</SpanText>.</PText>
    //            </View>
    //          ),
    //        },
    //        {
    //          question: 'Terminology', // @TODO: this could/should probably be combined with the "what are X?" questions?
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Goals: </SpanText>{"Comprised of one or more passages that you'd like to memorize, Goals are created to help track progress."}</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Passages: </SpanText>A passage is one verse up to one chapter of the Bible.  One or more passages can be put into a Goal to track your progress.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Peanuts: </SpanText>{"Peanuts are awarded at the end of most lessons based on how many correct answers you had, the difficulty settings, and other variables.  They are the best way to keep track of how much you've been practicing."}</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Wrinkles: </SpanText>{"While Peanuts indicate how much practice you're getting, Wrinkles reflect your level of mastery of the Passages.  A Wrinkle is just another name for a memorized verse."}</PText>
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //        {
    //          question: 'Screens',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Home: </SpanText>TODO</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Goals: </SpanText>TODO</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Practice: </SpanText>TODO</PText>{/* @TODO: change name */}
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Test: </SpanText>TODO</PText>{/* @TODO: change name */}
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>New Goal: </SpanText>TODO</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Feedback Chat: </SpanText>Have a question, a feature request, or other feedback?  Messages can be sent and received here.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Settings: </SpanText>Your notification preferences and light/dark mode can be managed on this screen.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>{"What's New: "}</SpanText>Learn about recent updates to Wrinkly.  New features and enhancements will be listed here.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Help: </SpanText>{"This is the page you're currently on.  Find information about common features and learn tips and tricks to make the most out of your experience with Wrinkly."}</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>About: </SpanText>The about page provides some background information about our company and links to rate and share with friends.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Side Drawer: </SpanText>The main menu accessible via the button in the top left on the Home screen.</PText>
    //              {isAnonymousUser && <PText style={styles.paragraph}><SpanText style={styles.bold}>Link Account: </SpanText>If you signed up with a guest account, your data will not be synced across devices.  To protect your data, you can link your account to an account you own (Apple, Google, Facebook, or your email address).  This will allow you to recover your data if you lose your phone or want to sign in to Wrinkly on a new device.</PText>}
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Goals',
    //      data: [
    //        {
    //          question: 'How do I create Goals?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>{'From the Side Drawer, press "All goals" and then the plus button in the bottom left to create a new goal.'}</PText>
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //        {
    //          question: 'How many goals should I create?  And how many passages should they have?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>{"How many active Goals you have at a time or how many Passages they have is totally up to you and what you're hoping to get out of Wrinkly.  We recommend grouping similar Passages into the same Goal so that you're learning all of them at the same time, but you can create them however you learn best."}</PText>
    //              <PText style={styles.paragraph}>{"We recommend creating Goals with 1-5 Passages depending on how many verses each Passage has.  There's no magic number here, but smaller Goals tend to be more approachable, and when they're completed, you'll still be prompted to review what you've mastered while you move on to new Goals as well."}</PText>
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Practice',
    //      data: [
    //        {
    //          question: 'What are the different types of practice?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>Changing the difficulty settings may change the number of verses displayed, how many clues are provide, etc.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Fill in the Blanks: </SpanText>One or a few verses from a Passage are displayed, but some of the words are removed.  The missing words are scrambled in a word bank below and need to be put back in the correct order.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Order the Verses: </SpanText>Several verses are displayed on the screen in a random order.  Your goal is to rearrange the verses to put them back in order.  (This game is available only for Passages with multiple verses.)</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Quotes: </SpanText>This is a matching game where the goal is to match the reference with the Passage.</PText>
    //              <PText style={styles.paragraph}><SpanText style={styles.bold}>Attribution: </SpanText>{"If you're working on a Passage that contains a lot of dialog, this games helps you to remember who said what (and how).  Match the attribution (e.g. \"He said\", \"Jesus answered\", \"they replied\") with the dialog quote"}</PText>
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Streaks',
    //      data: [
    //        {
    //          question: 'What are Streaks?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>{"Your streak is a quick way to see how many consecutive days you've made progress in Wrinkly."}</PText>
    //            </View>
    //          ),
    //        },
    //        {
    //          question: 'What if I miss a day?  Will I lose my streak?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>{'We know how important keeping your streak can be to stay motivated and consistent to reach your goals, so there is a way to repair broken streaks; if a day is missed, you have 10 days to make it up by earning additional peanuts.  From your Profile page, press the missing day in the Streaks chart, and press the "repair now" button to earn peanuts towards repair.'}</PText>{/* @TODO: 30 should be a configuration variable */}
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Testing',
    //      data: [
    //        {
    //          question: 'TODO',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>TODO</PText>
    //            </View>
    //          ),
    //          // @TODO: images
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Translations',
    //      data: [
    //        {
    //          question: "Why does my Bible not match Wrinkly even though I'm using the same translation?",
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>Most major translators are constantly improving their Bible verses to create the most accurate texts they can, and most of the translations available within Wrinkly have gone through several revisions over the years, so if there's a difference between what you're seeing in Wrinkly and a print version, it's probably because the translation in Wrinkly is the most updated version.  However, if you find a mistake in the text, please let us know via Feedback Chat.</PText> // @TODO: link to feedback chat
    //            </View>
    //          ),
    //        },
    //      ],
    //    },
    //    {
    //      title: 'Miscellaneous',
    //      data: [
    //        {
    //          question: 'Can I sync data between devices?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>{`${isAnonymousUser ? 'As long as you are not signed in as a guest' : 'Yes'}, data will be synced automatically between all devices you sign into, provided you sign in with the same account on each device.`}</PText>
    //              {isAnonymousUser && <PText style={styles.paragraph}>To link your guest account to an authentication provider, go to the Link Account screen (accessible via the Side Drawer) and link with Apple, Google, Facebook, or your email address.</PText>}
    //            </View>
    //          ),
    //        },
    //        {
    //          question: 'Why is it called "Wrinkly"?',
    //          answer: (
    //            <View>
    //              <PText style={styles.paragraph}>The initial idea came from the saying about your brain getting a wrinkle every time you learn something new.  The elephant mascot was a natural fit since elephants are wrinkly, and they never forget!</PText>
    //            </View>
    //          ),
    //        },
    //      ],
    //    },
    // {
    //   title: 'Classrooms',
    //   data: [
    //     {
    //       question: 'TODO',
    //       answer: (
    //         <View>
    //           <PText style={styles.paragraph}>Donors sponsor participants by pledging an amount per wrinkle earned.  Each memorized verse earns a wrinkle and is achieved by reaching level three for the verse.</PText>{/* @TODO: update */}
    //           <PText style={styles.paragraph}>{"Each level requires successfully typing/reciting the verse with no mistakes.  (Small spelling mistakes don't count against you!)  At least 24 hours must pass between each successful level-up, so if the campaign deadline is coming up, be sure to leave a few days to finish earning your wrinkles!"}</PText>{/* @TODO: update */}
    //         </View>
    //       ),
    //     },
    //   ],
    // },
    {
      title: 'Fundraising',
      data: [
        // @TODO: how do funraisers work?
        // @TODO: how can i start a fundraiser for my organization?
        {
          question: 'As a participant, how do I earn money for my organization?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Donors sponsor participants by pledging an amount per wrinkle earned.  Each memorized verse earns a wrinkle and is achieved by reaching level three for the verse.</PText>
              <PText style={styles.paragraph}>{"Each level requires successfully typing/reciting the verse with no mistakes.  (Small spelling mistakes don't count against you!)  At least 24 hours must pass between each successful level-up, so if the campaign deadline is coming up, be sure to leave a few days to finish earning your wrinkles!"}</PText>
            </View>
          ),
        },
        {
          question: 'As a participant, which verses should I memorize?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Some organizations/campaigns have specific verses for participants to memorize.  If you are unsure, please check with your organization.</PText>
              <PText style={styles.paragraph}>If your organization does not have a specific list, you can create your own goals and select verses manually, or you can use the Discover feature to pick from a curated list of existing passages.</PText>
            </View>
          ),
        },
        {
          question: 'As a participant, how can I track my progress?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Active campaigns are displayed on the Fundraising page in the mobile app.  From there you will be able to see how many wrinkles you have earned (level 3+) and how many are in-progress (level 1 or 2).</PText>
            </View>
          ),
        },
        {
          question: 'Can I be a sponsor and a participant?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'Yes!  Most campaigns are open to anyone, so if you would like to donate '}<i>and </i>{"memorize verses, that's great!"}</PText>
            </View>
          ),
        },
        {
          question: 'As a sponsor, how do I know how much to pledge per verse?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"There's no \"right\" number, and average pledge amounts vary with the age and number of participants in the campaign.  If you don't know what to pick for your first campaign, we recommend $2 as a good starting point for the pledge and selecting a maximum donation amount (an amount that will not be exceeded, regardless of participation) that is right for you."}</PText>
            </View>
          ),
        },
        {
          question: 'As a sponsor, how do I follow a campaign?',
          answer: (
            <View>
              <PText style={styles.paragraph}>At the end of the campaign, sponsors will receive a summary of the participation and impact--how many lessons and verses were completed and how much of your pledge was met.  If you wish to add to your donation upon the conclusion of the campaign, you will have the opportunity to do so before final pledges are collected.</PText>
            </View>
          ),
        },
        //        {
        //          question: 'How does Wrinkly get verses to stick?',
        //          answer: (
        //            <View>
        //              <PText style={styles.paragraph}>{'To earn a wrinkle (i.e. for a verse to be considered "memorized"), users must complete 3 successful tests for the passage, and at least 24 hours must elapse between each test.  This prevents a user from reviewing a verse quickly and relying on short-term memory to pass the tests without committing anything to long-term memory.'}</PText>
        //            </View>
        //          ),
        //        },
        //        {
        //          question: 'As a sponsor, how do I know that participants are actually memorizing and not just typing in answers?',
        //          answer: (
        //            <View>
        //              <PText style={styles.paragraph}>{"Ultimately, without proctored tests, there is no way to verify if a participant is testing honestly or not.  However, entering the exact verse from the Bible during testing still requires time to be spent in the Word, so while it's something Wrinkly does not condone, it's still time well spent."}</PText>
        //            </View>
        //          ),
        //        },
      ],
    },
  ],
});

export default faqs;
