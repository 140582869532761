/*
 * Ryan O'Dowd
 * 2024-08-19
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  dialogContent: {
    // @TODO:
    width: 500,
  },
  titleAndAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabled: {
    color: Globals.colors.gray,
    opacity: 0.5,
    cursor: 'not-allowed',
    // @TODO: disable mouse events
  },
  joinCodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  joinCode: { // @TODO: abstract with Classroom/styles.web.js for student table
    padding: 6,
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: '600',
  },
  code: {
    padding: 2,
    backgroundColor: Globals.colors.primaryExtraLight,
    fontSize: 12,
    borderRadius: 6,
  },
  emails: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // @TODO: minHeight: 100,
  },
  emailInputText: {
    display: 'flex',
    width: 'fit-content',
    margin: 2,
  },
  newEmail: {
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
  },
  alreadyInvitedEmail: {
    backgroundColor: Globals.colors.complete,
    color: Globals.colors.primaryExtraDark,
  },
  alreadyAcceptedEmail: {
    backgroundColor: Globals.colors.alert,
    color: Globals.colors.white,
  },
  legend: {
    paddingTop: 16,
    lineHeight: 2,
  },
  legendText: {
    padding: 6,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: '600',
  },
};

export default styles;
