/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  myMessageContainer: {
    justifyContent: 'flex-end',
  },
  containerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    padding: 8,
    borderRadius: 8,
    margin: 4,
    fontSize: 14,
    backgroundColor: Globals.colors.otherNote,
    color: Globals.colors.black,
  },
  botMessageContainer: {
    justifyContent: 'center',
  },
  myMessageContainerInner: {
    backgroundColor: Globals.colors.myNote,
  },
  botMessageContainerInner: {
    justifyContent: 'center',
    fontStyle: 'italic',
    backgroundColor: 'transparent',
    color: Globals.colors.gray,
  },
  noteBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    marginBottom: 8,
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  user: {
    color: Globals.colors.completeBg,
    fontSize: 10,
    fontStyle: 'italic',
    textAlign: 'right',
  },
  time: {
    color: Globals.colors.gray,
    fontSize: 11,
    fontStyle: 'italic',
    textAlign: 'right',
  },
};

export default styles;
