/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../../Globals';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Tooltip,
} from '@mui/material';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';

const MESSAGE_TIME_FORMAT = "MMM d, yyyy '@' h:mmaaaaa'm'";

const Note = (props) => {
  const oakUser = useSelector((state) => state.oakUser);

  const isMyMessage = props.user_id === oakUser.id;
  const isBotMessage = props.user_id === Globals.wrinklyBotId;

  return (
    <div
      style={{
        ...styles.container, ...(isMyMessage ? styles.myMessageContainer : {}), ...(isBotMessage ? styles.botMessageContainer : {}),
      }}
    >
      {/* @TODO: i18n datefnsformat */}
      <Tooltip title={dateFnsFormat(new Date(props.created_at), MESSAGE_TIME_FORMAT)} placement={isMyMessage ? 'left' : 'right'}>
        <div
          style={{
            ...styles.containerInner, ...(isMyMessage ? styles.myMessageContainerInner : {}), ...(isBotMessage ? styles.botMessageContainerInner : {}),
          }}
        >
          <span style={styles.noteBody}>{props.notes.split('\n').map((line, index) => <span key={index} style={styles.line}>{line}</span>)}</span>
          {!isBotMessage &&
            <span style={styles.rightWrapper}>
              <span
                style={{
                  ...styles.user,
                  ...(props.user_id === oakUser.id ? styles.myMessageContainer : {}),
                }}
              >
                {props.user?.username}
              </span>
            </span>
          }
        </div>
      </Tooltip>
    </div>
  );
};

Note.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  created_at: PropTypes.string.isRequired,
  user_id: PropTypes.number.isRequired,
  i18n_id: PropTypes.number.isRequired,

  notes: PropTypes.string,
  parent_note_id: PropTypes.number,
  upvoted: PropTypes.number,
  user: PropTypes.object,
};

export default Note;
