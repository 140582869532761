/**
 * Ryan O'Dowd
 * 2024-03-10
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  headerText: { // @TODO: abstract with edit dialogs
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 16,
    fontFamily: Globals.fontFamilies.secondary,
    fontWeight: 'bold',
    color: Globals.colors.primaryDark,
    marginTop: 32,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  pickerAndButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  pickerWrapper: {
    display: 'flex',
    width: 160,
  },
  buttonWrapper: {
    marginLeft: 8,
  },
};

export default styles;
