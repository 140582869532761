/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const title = {
  fontWeight: 'bold',
  fontSize: '1.15em',
  color: Globals.colors.accent,
};

const lightBackground = {
  color: Globals.colors.accentDark,
};
const styles = {
  bulletPointsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bulletPoint: {
    marginBottom: 16,
  },
  title,
  lightBackground,
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  organizationContactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contactHeader: {
    ...title,
    ...lightBackground,
  },
  contactText: {
    fontSize: '0.90em',
    marginTop: 0,
  },

  organizationCard: {
    minWidth: 100,
    maxWidth: 300,
  },
  cardMedia: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    width: 'calc(100% - 32px)',
    objectFit: 'contain',
    backgroundColor: Globals.colors.primaryExtraDark,
  },
  cardContent: {
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.primaryExtraDark,
    paddingTop: 4,
  },

  contactWrapper: { // @TODO: abstract with LandingPage
    minWidth: '50%',
    flex: 1,
  },
  downloadCtaWrapper: { // @TODO: abstract with LandingPage
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },
};

export default styles;
