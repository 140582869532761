/*
 * Ryan O'Dowd
 * 2020-11-29
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Footer from '../../common/components/OakFooter';
import Header from '../../components/Header';
import NavigationTabs from '../../components/NavigationTabs';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const WebPageStub = (props) => {
  return (
    <div style={styles.outerContainer}>
      <Header navigationTabs={!props.hideTabs ? <NavigationTabs /> : null} />
      <div style={styles.container}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

WebPageStub.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,

  hideTabs: PropTypes.bool,
};

export default WebPageStub;
