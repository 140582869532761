/**
 * Ryan O'Dowd
 * 2022-12-26
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import i18nFun from '../../i18n';
import oakGlobalObject from './oakGlobalObject';
import platformUtilities from './platformUtilities';
import {
  store,
} from '../../Store';
import {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';

function memoAreEqual(prevProps, nextProps) {
  for (const pk in nextProps) {
    if (['function', 'boolean', 'number', 'string', 'symbol', 'bigint', 'undefined'].includes(typeof nextProps[pk])) {
      if (prevProps[pk] !== nextProps[pk]) {
        return false;
      }
    } else if (Array.isArray(nextProps[pk])) {
      /* @TODO:
      if (prevProps[pk]?.length !== nextProps[pk].length) {
        return false;
      }
      */
      if (JSON.stringify(prevProps[pk]) !== JSON.stringify(nextProps[pk])) {
        return false;
      }
    } else if (typeof nextProps[pk] === 'object') {
      if (JSON.stringify(prevProps[pk]) !== JSON.stringify(nextProps[pk])) {
        return false;
      }
    } else {
      // @TODO: what other types are there? throw error
      return false;
    }
  }

  return true;
}

function camelCaseToHumanReadable(s) {
  let retVal = s.replace(/([A-Z])/g, ' $1');
  retVal = `${retVal[0].toUpperCase()}${retVal.slice(1)}`;
  return retVal;
}

function isSecondOlderOrSameAsFirst(firstVersion, secondVersion) {
  if (!firstVersion || !secondVersion) {
    return true;
  }

  try {
    const [fvMajor, fvMinor, fvPatch] = firstVersion.split('.');
    const [svMajor, svMinor, svPatch] = secondVersion.split('.');
    if (+fvMajor > +svMajor) {
      return true;
    } else if (+fvMajor === +svMajor && +fvMinor > +svMinor) {
      return true;
    } else if (+fvMajor === +svMajor && +fvMinor === +svMinor && +fvPatch >= +svPatch) {
      return true;
    }
  } catch (e) {
    platformUtilities.recordError(e);
  }

  return false;
}

function useI18n(localeCode) {
  const userLocaleCode = useSelector((state) => state.oakUser?.locale_code || 'en');
  localeCode = localeCode || userLocaleCode;

  const localStaticI18n = useMemo(() => {
    return i18nFun(localeCode);
  }, [localeCode]);

  const i18nUpdatedTimestamp = useSelector((state) => state.i18nUpdatedTimestamp);

  return useMemo(() => {
    const remoteDynamicI18n = store.getState().i18n?.[localeCode]?.i18n?.reduce((acc, i) => {
      if (i.is_function) {
        acc[i.key] = (...args) => {
          let index = 0;
          return i.value.replace(/\{\{\}\}/gi, (match) => {
            const arg = args[index];
            index++;
            if (!arg) {
              return '';
            }

            return arg;
          });
        };
      } else {
        acc[i.key] = i.value;
      }
      return acc;
    }, {}) || {};

    const allI18n = {
      ...localStaticI18n,
      ...remoteDynamicI18n,
    };

    return (key, ...params) => {
      const value = allI18n[key];
      if (value === undefined) {
        if (Object.keys(allI18n).length > 50) { // @TODO: don't hardcode 50...there will be some local translations and the locale object...should be less than 50 for a while, but change this at some point
          // don't throw error if this locale i18n translations haven't been fetched yet
          const errorMessage = `\`${key}\` is not a valid key for i18n`;
          console.error(errorMessage);
          platformUtilities.recordError(new Error(errorMessage));
        }
        return '';
      } else if (typeof value === 'function') {
        return value(...params);
      }

      return value;
    };
  }, [localStaticI18n, localeCode, i18nUpdatedTimestamp]); // @TODO: need to run this every time i18nUpdatedTimestamp is updated since we're getting a snapshot of the store instead of using useselector...get rid of eslint error
}

export default oakGlobalObject({
  camelCaseToHumanReadable,
  isSecondOlderOrSameAsFirst,
  memoAreEqual,
  useI18n,

  getHeaderHeight: platformUtilities.getHeaderHeight,
  isIphoneWithIsland: platformUtilities.isIphoneWithIsland,
  isIphoneWithCutout: platformUtilities.isIphoneWithCutout,
  linkUserAndCredential: platformUtilities.linkUserAndCredential,
  recordError: platformUtilities.recordError,
  useBackButton: platformUtilities.useBackButton,
  useStyles: platformUtilities.useStyles,
  rebuildEStyleSheet: platformUtilities.rebuildEStyleSheet,
});
