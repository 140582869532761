/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import CPassage from '../../classes/Passage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Globals from '../../Globals';
import {
  PRACTICE_ROUTE,
} from '../../actions';
import PassageDisplay from '../PassageDisplay';
import PropTypes from 'prop-types';
import Text from '../../common/components/OakText';
// @TODO: import WrinkleBadge from '../WrinkleBadge';
import commonStyles from '../../commonStyles';
import {
  add as dateFnsAdd,
} from 'date-fns';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../utilities';

const Passage = (props) => {
  const [_isSelectionModeOn, setIsSelectionModeOn] = useState(false);

  const settingsPreferredVerseSelectionMethod = useSelector((state) => state.settingsPreferredVerseSelectionMethod);

  const [passage, wrinkles] = useSelector((state) => { // @TODO: using goalId is a leaky abstraction // @TODO: redux should be flatter with plans (goals) and passages being separat reducers instead of nesting passages inside of goals
    // @TODO: goals now have modules instead of passages: const _p = state.goals[props.goalId].passages.find((p) => p.id === props.id);
    // @TODO: console.log(JSON.stringify(state.goals[props.goalId]));
    // @TODO: let _p = { // @TODO: remove
    // @TODO:   id: 1,
    // @TODO:   translation_id: 1,
    // @TODO:   book_id: 1,
    // @TODO:   chapter_number: 1,
    // @TODO:   start_verse_number: 1,
    // @TODO:   end_verse_number: 31,
    // @TODO: };
    // @TODO: console.log('qqqqqqqqq');
    // @TODO: console.log({goalId: props.goalId});
    // @TODO: console.log({passageId: props.id});
    // @TODO: console.log({passageId: props.id});
    /* @TODO:
    state.goals[props.goalId].modules.forEach((module) => { // @TODO: this is hacky...shouldn't need to look through goals to get passages...should it be its own reducer?
      module.assignments.forEach((assignment) => {
        if (assignment.assignment_object.passage?.id === props.id) {
          _p = assignment.assignment_object.passage;
        }
      });
    });
    */
    // @TODO: const _pc = CPassage.createFromObject(_p);
    // @TODO: return [_pc, _pc.getWrinkles()]; // @TODO: need to return passage and wrinkles together because calling .getWrinkles() can return a different value...this means that when leveling up, it's possible (probable/guaranteed) that the UI will not update to reflect the new level achieved without a re-render being triggered
    return [props.passage || {}, []]; // @TODO: return [props.passage || {}, props.passage?.getWrinkles() || []]; // @TODO: need to return passage and wrinkles together because calling .getWrinkles() can return a different value...this means that when leveling up, it's possible (probable/guaranteed) that the UI will not update to reflect the new level achieved without a re-render being triggered
  }, (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)); // @TODO: is JSON.stringify sufficient and/or performant enough?

  const passageVerses = utilities.useGetVersesForPassages([passage])[passage.id] || []; // @TODO: this doesn't get recalculated when Bible is deleted locally (edge case, but something to consider)

  // @TODO: const isTestOpen = !!wrinkles.length && wrinkles.some((wrinkle) => (wrinkle.level_number ?? 0) < Globals.maxProgressLevel && new Date() >= dateFnsAdd(new Date(wrinkle.date_last_passed ?? 0), {days: 1})); // @TODO: abstract to a method on a Wrinkle class
  const isPassageCompleted = false; // @TODO: !!wrinkles.length && wrinkles.every((wrinkle) => (wrinkle.level_number || 0) >= Globals.maxProgressLevel);

  let globalScale = useSelector((state) => Globals.fontScales[state.settingsPreferredScale] ?? 1);
  if (isNaN(globalScale)) {
    // "medium" was originally "normal", so i think there's an issue getting the global scale above; (this requires a redux migration to fix, but i'm worried about other non-allowed values...would be better to have a utility function, or to save the _actual_ scale decimal in redux instead of a string that maps to the scale value)
    globalScale = 1;
  }

  let passageWrinkleLevelNumber = 1000000000;
  let hasVersesInProgress = false;
  /* @TODO:
  wrinkles.forEach((w) => {
    if (w.level_number > 0 && w.level_number < Globals.maxProgressLevel) {
      hasVersesInProgress = true;
    }
    passageWrinkleLevelNumber = Math.min(w.level_number, passageWrinkleLevelNumber);
  });
  */

  return (
    <Accordion
      style={styles.accordionInner}// @TODO: change
      defaultExpanded={false}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{/* @TODO: flip direction when open */}
        <Text style={commonStyles.listItemMainText}>{utilities.prettyPassageName(passage)}</Text>
        {/* @TODO:
        onPress={() => {
          setIsSelectionModeOn(false);
        }}
        variant={isPassageCompleted ? 'accent' : 'primary'}
        */}
      </AccordionSummary>
      <AccordionDetails>
        <span style={styles.versesWrapper}>
          <PassageDisplay
            key={passage.id}
            selectedTranslation={passage.translation_id}
            passages={[passage]}
            hideLicensing={true}
            showFormatting={true}
            showFootnotes={true}
            showHeadings={true}
            hideNumbersTextToggle={true}
            isReadFeature={true}// @TODO: not always...
            showWrinkleBadges={true}// @TODO: probably not for teachers
            alwaysShowSelectionMenu={true}// @TODO: would prefer this not to be true...but there's jumping when selecting otherwise
            doesTranslationAffectWrinkleStatus={false}
            hideCompare={true}
            hideAddToGoal={true}
            verseNumbersOnly={settingsPreferredVerseSelectionMethod === 'numbers'}
            bottomBorderColorOverride={isPassageCompleted ? Globals.colors.accent : null}
            onSelectionModeToggle={(isOn) => setIsSelectionModeOn(isOn)}
            isPressDisabled={props.isPressDisabled}
          />
        </span>
        {/* @TODO:
        <div style={styles.foo}>
          <div style={styles.headerWrapper}>
            <div style={styles.chapterMetadataWrapper}>
              <div style={styles.firstRow}>
                <Text style={commonStyles.listItemMainText}>{utilities.prettyPassageName(passage)}</Text>
              </div>
            </div>
          </div>
          <div style={styles.rightActions}>
            {(!!props.normalizedScore || hasVersesInProgress) &&
              <div>
                {/* @TODO:
                <IconButton
                  style={[
                    styles.passageActionButton,
                    !isTestOpen && styles.closedTestIcon,
                    (!!wrinkles.length && wrinkles.every((wrinkle) => (wrinkle.level_number || 0) >= Globals.maxProgressLevel)) && styles.levelFiveButton,
                    _isSelectionModeOn && styles.disabledButton,
                  ]}
                  icon='test-tube'
                  iconColor={_isSelectionModeOn ? Globals.colors.gray : ((!!wrinkles.length && wrinkles.every((wrinkle) => (wrinkle.level_number || 0) >= Globals.maxProgressLevel)) ? `${Globals.colors.primaryExtraDark}77` : (isTestOpen ? Globals.colors.accentLight : Globals.colors.lightGray))}
                  size={22 * globalScale}
                  onPress={() => {
                    if (_isSelectionModeOn) {
                      return;
                    }
                    props.navigation.navigate(PRACTICE_ROUTE, {
                      passages: [passage],
                      isTest: true,
                    });
                  }}
                />
                */}
        {/* @TODO:
                {(!_isSelectionModeOn && hasVersesInProgress && passageWrinkleLevelNumber < Globals.maxProgressLevel) &&
                  <WrinkleBadge
                    isTestOpen={isTestOpen}
                    levelToDisplay={passageWrinkleLevelNumber}
                    absoluteTop={-4}
                    absoluteRight={-4}
                  />
                }
                */}
        {/* @TODO: add option for "go to passage in Read feature"...should this be in selection menu? /}
              </div>
            }
            {/* @TODO:
            <IconButton
              style={[
                styles.passageActionButton,
                (!!wrinkles.length && wrinkles.every((wrinkle) => (wrinkle.level_number || 0) >= Globals.maxProgressLevel)) && styles.levelFiveButton,
                _isSelectionModeOn && styles.disabledButton,
              ]}
              icon='school'
              iconColor={_isSelectionModeOn ? Globals.colors.gray : ((!!wrinkles.length && wrinkles.every((wrinkle) => (wrinkle.level_number || 0) >= Globals.maxProgressLevel)) ? `${Globals.colors.primaryExtraDark}77` : Globals.colors.accentLight)}
              size={22 * globalScale}
              onPress={() => {
                if (_isSelectionModeOn) {
                  return;
                }
                props.navigation.navigate(PRACTICE_ROUTE, {
                  passages: [passage],
                });
              }}
            />
            /}
          </div>
        </div>
        */}
      </AccordionDetails>
    </Accordion>
  );
};

Passage.propTypes = {
  id: PropTypes.number.isRequired,
  goalId: PropTypes.number.isRequired,
  normalizedScore: PropTypes.number.isRequired,

  isExpanded: PropTypes.bool,
  isPressDisabled: PropTypes.bool,
};

export default Passage;
