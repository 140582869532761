/*
 * Ryan O'Dowd
 * 2022-04-18
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  sendMessage,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const ContactForm = (props) => {
  const dispatch = useDispatch();

  const [_firstNameText, setFirstNameText] = useState('');
  const [_lastNameText, setLastNameText] = useState('');
  const [_emailText, setEmailText] = useState('');
  const [_messageText, setMessageText] = useState('');
  const [_formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div style={styles.container}>
      <p style={props.textStyles}>{props.leadingText}</p>
      {_formSubmitted
        ? (
          <p style={styles.thanksText}>Thanks for the message!</p>
        ) : (
          <>
            <div style={styles.row}>
              <TextField
                style={styles.formField}
                label='First name'
                variant='outlined'
                required={true}
                value={_firstNameText}
                onChange={(e) => setFirstNameText(e.target.value)}
              />
              <TextField
                style={styles.formField}
                label='Last name'
                variant='outlined'
                required={true}
                value={_lastNameText}
                onChange={(e) => setLastNameText(e.target.value)}
              />
            </div>
            <div style={styles.row}>
              <TextField
                style={styles.formField}
                label='Email address'
                type='email'
                variant='outlined'
                required={true}
                value={_emailText}
                onChange={(e) => setEmailText(e.target.value)}
              />
            </div>
            <div style={styles.row}>
              <TextField
                style={styles.formField}
                label='Message'
                variant='outlined'
                multiline={true}
                rows={4}
                value={_messageText}
                onChange={(e) => setMessageText(e.target.value)}
              />
            </div>
            <div style={styles.row}>
              <Button
                style={styles.formField}
                variant='contained'
                disabled={!(_firstNameText && _lastNameText && _emailText)}
                onClick={() => {
                  // @TODO: form validation for required fields
                  dispatch(sendMessage(_firstNameText, _lastNameText, _emailText, _messageText));
                  setFormSubmitted(true);
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )
      }
    </div>
  );
};

ContactForm.propTypes = {
  leadingText: PropTypes.string.isRequired,

  textStyles: PropTypes.object,
};

export default ContactForm;
