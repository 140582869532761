/**
 * Ryan O'Dowd
 * 2024-02-12
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import CPassage from '../../../../classes/Passage';
import Passage from '../../../../components/Passage';
import PropTypes from 'prop-types';
import React, {
} from 'react';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../../../utilities';

const VerseMemory = (props) => {
  const i18n = utilities.useI18n();

  const passage = CPassage.createFromObject(props.passage); // @TODO: i feel like the CPassage should be the one passed in...
  const minigames = useSelector((state) => state.minigames);

  return (
    <div style={styles.container}>
      <div style={styles.infoWrapper}>
        <span style={styles.infoText}>Students must {props.must_memorize ? 'memorize' : 'practice'}{!!props.must_memorize && props.num_lessons_to_complete > 0 && ' and practice'} these verses.{!!props.num_lessons_to_complete && <span>{`  At least ${props.num_lessons_to_complete} lesson${props.num_lessons_to_complete > 1 ? 's' : ''} must be completed.`}</span>}</span>
        <span style={styles.infoText}>Game difficulty: {props.game_difficulty ?? 'student choice'}</span>{/* @TODO: update difficulty */}
        <span style={styles.infoText}>Games available: {props.game_types_enabled?.length ? Object.values(minigames).filter((g) => props.game_types_enabled.includes(g.id)).map((g) => g.name).join(', ') || props.game_types_enabled.join(', ') : 'student choice'}</span>{/* @TODO: update game types allowed */}
      </div>

      <Passage
        id={props.passage_id}
        passage={passage}
        goalId={props.goalId} // @TODO: need this?
        normalizedScore={0.8}// @TODO: update
        isExpanded={false}
        isPressDisabled={true}
      />
    </div>
  );
};

VerseMemory.propTypes = {
  id: PropTypes.number.isRequired,
  passage_id: PropTypes.number.isRequired,
  passage: PropTypes.object.isRequired, // @TODO: shape
  goalId: PropTypes.number.isRequired,
  must_memorize: PropTypes.number.isRequired,

  game_difficulty: PropTypes.oneOf(['easy', 'medium', 'hard']),
  num_lessons_to_complete: PropTypes.number,
  game_types_enabled: PropTypes.arrayOf(PropTypes.number),
};

export default VerseMemory;
