/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Campaign from './Campaign';
import CampaignPreview from './CampaignPreview';
import React from 'react';
import WebPageStub from '../WebPageStub';
import styles from './styles';
import {
  useParams,
} from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';

const MyCampaigns = (props) => {
  const user = useSelector((state) => state.oakUser);

  const organizationCampaigns = useSelector((state) => state.organizationCampaigns);

  const {
    campaignSlug,
  } = useParams();

  // @TODO: redirect to /campaigns/<slug of first campaign in `organizationCampaigns`> if at `/campaigns`

  if (!user) {
    return (
      <WebPageStub hideTabs={true}>
        <p>{"TODO: user should already be authenticated if they're redirected here"}</p>
      </WebPageStub>
    );
  }

  const selectedCampaign = organizationCampaigns.find((c) => c.slug === campaignSlug) || organizationCampaigns[0];

  return (
    <WebPageStub hideTabs={true}>
      {/* @TODO: add partner logo to left side of header */}
      {/* @TODO: add sign out menu to the right side of header */}
      <div style={styles.container}>
        <div style={styles.section}>
          <div style={styles.sectionInner}>
            <h2>My Campaigns</h2>
            <div style={styles.campaignPreviewsWrapper}>{/* @TODO: don't need to show this if there's only one active campaign and inactive campaigns are hidden */}
              {organizationCampaigns.map((c) => <CampaignPreview key={c.id} {...c} isSelected={selectedCampaign.id === c.id} />)}
            </div>
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionInner}>
            <Campaign {...selectedCampaign} />
          </div>
        </div>
        {/* @TODO: create a form to capture/insert new campaign info automatically
        <div style={styles.section}>
          <div style={styles.sectionInner}>
            <h2>Configuration</h2>
            <h3>Campaign details</h3>
            <p>@TODO: name</p>{/* @TODO: can't pick a date in the past /}
            <p>@TODO: start date</p>{/* @TODO: can't pick a date in the past /}
            <p>@TODO: end date</p>
            <p>{"@TODO: fundraising goal (can show warnings if it looks like there aren't enough sponsors to reach the goal based on average wrinkles...i'll know these numbers better after a few campaigns)"}</p>
            <p>@TODO: payment info (just contact info right now, but eventually add direct-deposit info...even if not saved, maybe a button to initiate transfer)</p>
            <h3>Sponsor landing page</h3>
            <p>@TODO: description of organization (250 characters)</p>
            <p>@TODO: description of campaign (500 characters)</p>
            <p>@TODO: upload logo</p>
            <p>@TODO: organization url</p>
            <p>@TODO: checkbox to indicate whether this should be publicly listed on the website or a private fundraiser only for those with the link</p>
            <p>{"@TODO: changes here have to be approved by me (espcially since they're public, need to make sure they're appropriate and not irrelevant marketing, e.g.; also, sponsors can't have the dates changing on them, so changes here are 'requests sent to me to update manually if they make sense')"}</p>
          </div>
        </div>
        */}
      </div>
    </WebPageStub>
  );
};

export default MyCampaigns;
