/**
 * Ryan O'Dowd
 * 2022-11-30
 * © Copyright 2023 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    fontFamily: Globals.fontFamilies.primary,
  },
};

export default styles;
