/*
 * Ryan O'Dowd
 * 2023-10-26
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  websiteSectionOverride: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: Globals.isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
  },
  statWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: Globals.isMobile ? 0 : 32,
    paddingRight: Globals.isMobile ? 0 : 32,
  },
  leftWrapper: {
    maxWidth: 800, // @TODO: 1000 - 200...make this dynamic (this could be a prop for WebsiteSection)
    // @TODO: paddingLeft: 100,
  },
  rightWrapper: {
    maxWidth: 800, // @TODO: 1000 - 200...make this dynamic (this could be a prop for WebsiteSection)
    // @TODO: paddingRight: 100,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  statText: {
    fontSize: '3em',
    fontWeight: '3em',
    color: Globals.colors.accent,
    marginBottom: -8,
    paddingLeft: 12,
    paddingRight: 12,
  },
  image: {
    maxWidth: 150,
    maxHeight: 180,
    width: 200,
    height: 'auto',
    // @TODO: aspectRatio: 1.0,
    objectFit: 'contain',
  },
};

export default styles;
