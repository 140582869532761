/*
 * Ryan O'Dowd
 * 2024-05-18
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px - 35px - 114px)', // @TODO: header and footer heights; check mobile; don't hardcode; no idea what the 114px is
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialog: {
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
  },
  releaseNotesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 200,
    overflow: 'auto',
  },
  note: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    paddingLeft: 16,
    textIndent: -16,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 4,
  },
};

export default styles;
