/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const PartnerLogo = (props) => {
  return (
    <span
      style={styles.container}
      onClick={() => {
        window.location = props.orgUrl;
      }}
    >
      <img
        style={styles.image}
        src={props.imgSrc}
      />
    </span>
  );
};

PartnerLogo.propTypes = {
  orgUrl: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default PartnerLogo;
