/*
 * Ryan O'Dowd
 * 2024-03-03
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDrag,
  useDrop,
} from 'react-dnd';
import Assignment from './Assignment';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditAssignmentDialog from '../EditAssignmentDialog';
import EditIcon from '@mui/icons-material/Edit';
import EditModuleDialog from '../EditModuleDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Globals from '../../../Globals';
import PropTypes from 'prop-types';
// @TODO: import PublishedStatus from '../PublishedStatus';
import styles from './styles';
import update from 'immutability-helper';
import {
  updateModule,
} from '../../../actions';
import {
  useDispatch,
} from 'react-redux';
import utilities from '../../../utilities';

const Module = (props) => {
  const dispatch = useDispatch();

  const dragRef = useRef(null);
  const previewRef = useRef(null); // @TODO: https://stackoverflow.com/questions/68558511/using-a-handle-with-react-dnd

  const [_isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [_isNewAssignmentDialogOpen, setIsNewAssignmentDialogOpen] = useState(false);
  const [_expanded, setExpanded] = useState(false);

  const [_assignments, setAssignments] = useState(props.module.assignments);
  useEffect(() => {
    setAssignments(props.module.assignments);
  }, [props.module.assignments]);

  const moveAssignment = useCallback((dragIndex, hoverIndex) => { // @TODO: duplicate this for modules
    setAssignments((prevModules) =>
      update(prevModules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevModules[dragIndex]],
        ],
      }),
    );
  }, []);

  const [{handlerId}, drop] = useDrop({
    accept: 'module', // @TODO: constants
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveModule(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      props.onReorderModules();
    },
  });
  const [{isDragging}, drag, preview] = useDrag({
    type: 'module', // @TODO: move to constants?
    item: () => {
      return {id: props.module.id, index: props.index};
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const draggableStyles = {
    opacity: isDragging ? 0 : 1,
    // @TODO: other styles
  };
  drag(dragRef);
  drop(preview(previewRef));

  return (
    <Paper
      ref={previewRef}
      data-handler-id={handlerId}
      style={{
        ...styles.accordionWrapper,
        ...styles.coloredAccordion,
        ...draggableStyles,
      }}
    >
      <Accordion
        style={styles.accordionInner}// @TODO: change
        expanded={_expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{/* @TODO: flip direction when open */}
          <span
            ref={dragRef}
            style={styles.dragIconWrapper}
          >
            <DragIndicatorIcon
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </span>
          <span style={styles.editRow}>
            <span style={styles.fullTitleWrapper}>
              <h2 style={styles.moduleName}>{props.module.name}</h2>{/* @TODO: <Editable textValue={module.name} label='Module name' id={module.id} model='module' field='name' title='Edit module name' />{/* @TODO: onedit, don't toggle accordion */}{/* @TODO: maybe don't need this...make custom dialogs for new/edit module and new/edit assignment */}
              <span style={styles.subTitle}>{utilities.prettyAvailablityDateRange(props.module.date_available, props.module.date_deadline)}</span>
            </span>
            <span style={styles.actionsWrapper}>
              {_expanded &&
                <Tooltip title='Edit module'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    sx={{color: Globals.colors.white}}
                    onClick={(event) => {
                      event.stopPropagation(); // @TODO: not working?
                      setIsEditDialogOpen(true);
                    }}
                  >
                    <EditIcon color={Globals.colors.white} />
                  </IconButton>
                </Tooltip>
              }
              {/* @TODO: only goals have draft/published status right now
              <PublishedStatus status={props.module.status} module={props.module} />
              */}
            </span>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {_assignments.filter((assignment) => assignment.assignment_type !== 'video').map((assignment, index) => { // @TODO: remove the filter
            return (
              <Assignment
                key={assignment.id}
                goal={props.goal}
                classroomGoal={props.classroomGoal}
                module={props.module}
                assignment={assignment}
                moveAssignment={moveAssignment}
                index={index}
                onReorderAssignments={() => {
                  dispatch(updateModule(props.classroomGoal.classroom_id, props.goal.id, props.module.id, undefined, undefined, undefined, undefined, _assignments.map((m) => m.id)));
                }}
              />
            );
          })}
          <Paper style={styles.accordionWrapper}>
            <Accordion
              style={styles.accordionInner}// @TODO: change
              defaultExpanded={false}
              // @TODO: disabled={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={() => setIsNewAssignmentDialogOpen(true)}
              >{/* @TODO: flip direction when open */}
                <h3 style={styles.assignmentName}>+ Add new assignment</h3>{/* @TODO: change tag type? and styles */}
                {/* @TODO:
                <TextField// @TODO: make this white...label should match (or be close to) existing module names
                  value={_newModuleName}
                  onChange={(e) => setNewModuleName(e.target.value)}
                  placeholder=''
                  label='+ Add new assignment'// @TODO: improve ui
                  variant='standard'
                />
                {_newModuleName &&
                  <React.Fragment>
                    <IconButton
                      style={{...styles.editIconButton, ...styles.editIconButtonCheck}}
                      onClick={() => {
                        // @TODO: implement back-end call
                      }}
                      variant='contained'// @TODO:
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      style={{...styles.editIconButton, ...styles.editIconButtonClose}}
                      onClick={() => {
                        setNewModuleName('');
                      }}
                      variant='contained'
                    >
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>
                }
                */}
              </AccordionSummary>
            </Accordion>
          </Paper>
        </AccordionDetails>
      </Accordion>
      {_isNewAssignmentDialogOpen && <EditAssignmentDialog classroomGoal={props.classroomGoal} goal={props.goal} module={props.module} isNew={true} onClose={() => setIsNewAssignmentDialogOpen(false)} />}
      {_isEditDialogOpen && <EditModuleDialog classroomGoal={props.classroomGoal} goal={props.goal} module={props.module} onClose={() => setIsEditDialogOpen(null)} />}
    </Paper>
  );
};

Module.propTypes = {
  goal: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape
  module: PropTypes.object.isRequired, // @TODO: shape
  moveModule: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onReorderModules: PropTypes.func.isRequired,
};

export default Module;
