// @TODO: fine-tune copy
/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
// @TODO: tiny partner images
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from '@mui/material';
import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ContactForm from '../../common/components/ContactForm';
import DownloadLinks from '../../components/DownloadLinks';
import {
  Link,
} from 'react-router-dom';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import {
  fetchOrganizations,
} from '../../actions';
import styles from './styles';

const Fundraising = (props) => {
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.organizations);

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const _renderBulletPoint = (title, body, lightBackground) => {
    return <span style={styles.bulletPoint}><span style={{...styles.title, ...(lightBackground ? styles.lightBackground : {})}}>{title}</span><br />{body}</span>;
  };

  const _renderOrganization = (organization) => {
    return (
      <Card sx={styles.organizationCard}>
        <CardActionArea
          component={Link}
          to={`/organizations/${organization.slug}`}
        >
          <CardMedia
            style={styles.cardMedia}
            component='img'
            height='140'
            image={organization.logo_url}
            alt={`${organization.name} logo`}
          />
          <CardContent style={styles.cardContent}>
            <h3>{organization.name}</h3>
            <p>{organization.description}</p>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <WebPageStub>
      <WebsiteSection
        isDarkBackground={true}
        title='Fundraising with purpose'
      >
        <p>{"Wrinkly is ideal for summer camps, missions trips, youth groups, schools, and any other group that is raising funds for events, travel, or other missional activities.  It's incredibly rewarding to help Christ-centered organizations raise money, to provide a way for sponsors to feel involved, and to watch participants spend more time in Scripture.  We love supporting these types of organizations and would love for you to join us!"}</p>
        <p>Join Wrinkly today as an organization, sponsor, or participant.</p>
      </WebsiteSection>

      <WebsiteSection
        title='For organizations'
      >{/* @TODO: make sure this looks good on mobile */}
        <div style={styles.inner}>
          <Grid container spacing={2} style={styles.sideBySide}>
            <Grid item xs={12} sm={6} style={styles.bulletPointsWrapper}>
              {/* @TODO: hash anchor link
              {_renderBulletPoint('Apply', <>Use <a href='#contact'>the form below to contact us</a>{" with information about your organization, and we'll establish your account and get your first campaign started."}<>)}
              */}
              {_renderBulletPoint('Apply', "Use the form here to contact us with information about your organization, and we'll establish your account and get your first campaign started.", true)}
              {_renderBulletPoint('Track', "Get insights into participant progress, sponsors' pledges, and campaign efficacy.", true)}
              {_renderBulletPoint('Sit back', "Wrinkly handles all of the tracking, invoicing, and payments so you don't have to.", true)}
              {_renderBulletPoint('Earn', 'At the end of the campaign, you will receive a check with the proceeds.', true)}
              {/* @TODO: something about feeling good about the impact as well */}
            </Grid>
            <Grid item xs={12} sm={6} style={styles.organizationContactWrapper}>
              <span style={styles.contactHeader}>Enroll your organization</span>
              <ContactForm
                leadingText='Please include a brief description of your organization, the campaign you are planning, and a link to your website if you have one.'
                textStyles={styles.contactText}
              />{/* @TODO: make more fields for the info I'm looking for instead of just telling them what to include in the body of the message */}
            </Grid>
          </Grid>
        </div>
      </WebsiteSection>

      <WebsiteSection
        isDarkBackground={true}
        title='For sponsors'
      >
        <div style={styles.bulletPointsWrapper}>
          {_renderBulletPoint('Pick an origanization', 'Summer camps, church groups, schools, and other groups are looking for sponsors.')}
          {_renderBulletPoint('Determine a pledge amount', 'Pick an amount to donate per verse memorized up to a specified threshold.')}
          {_renderBulletPoint('Donate with confidence', 'Wrinkly uses many techniques to make learning easier and to help memorized verses stick for a long time.  You can be certain that your donation is going to a good cause and that participants are spending meaningful time in the Word.')}
          {_renderBulletPoint('Receive a report with your impact', "At the end of the campaign, you will receive a report detailing participants' achievements and your impact for the organization.")}
        </div>
      </WebsiteSection>

      <WebsiteSection
        title='For participants'
      >
        <div style={styles.bulletPointsWrapper}>
          {_renderBulletPoint('Join an origanization.', 'Accept an invitation from your group organizer.', true)}
          {_renderBulletPoint('Use Wrinkly to learn Bible verses.', 'Each verse memorized during the campaign will earn money for your organization.', true)}
        </div>
      </WebsiteSection>

      {!!Object.values(organizations).filter((o) => o.is_public).length &&
        <WebsiteSection
          isDarkBackground={true}
          title='Partners'
        >
          <Grid container spacing={2}>
            {Object.values(organizations).filter((o) => o.is_public).reverse().map((o) => { // @TODO: order by most recent campaign to least recent
              return (
                <Grid key={o.id} item xs={12} sm={6} md={4} lg={3}>{/* @TODO: add stuff for other screen sizes */}
                  {_renderOrganization(o)}
                </Grid>
              );
            })}
          </Grid>
        </WebsiteSection>
      }

      <WebsiteSection
        id='contact'
        title='Contact us'
      >
        <div style={styles.contactAndDownloadWrapper}>
          <div style={styles.contactWrapper}>
            <ContactForm leadingText="If you are an organization, sponsor, or participant interested in Wrinkly, we'd love to hear from you." />
          </div>
          <div style={styles.downloadCtaWrapper}>
            <h2>Download now</h2>
            <span style={styles.downloadLinks}>
              <DownloadLinks />
            </span>
          </div>
        </div>
      </WebsiteSection>
    </WebPageStub>
  );
};

export default Fundraising;
