/*
 * Ryan O'Dowd
 * 2022-07-29
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    margin: 8,
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    textAlign: 'left',
  },
  errorAlert: {
    backgroundColor: Globals.colors.alert,
    color: Globals.colors.white,
  },
  thankYou: {
    marginTop: 0,
    fontWeight: 'bold',
  },
};

export default styles;
