/*
 * Ryan O'Dowd
 * 2024-03-03
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import React, {
  useRef,
  useState,
} from 'react';
import {
  useDrag,
  useDrop,
} from 'react-dnd';
import Assignments from '../../../Assignment/Assignments'; // @TODO: should this be a different path?
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CampaignIcon from '@mui/icons-material/Campaign';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditAssignmentDialog from '../../EditAssignmentDialog'; // @TODO: should probably move this location
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PropTypes from 'prop-types';
// @TODO: import PublishedStatus from '../../PublishedStatus';
import SchoolIcon from '@mui/icons-material/School';
import TestTubeIcon from 'mdi-react/TestTubeIcon';
import styles from './styles';
import utilities from '../../../../utilities';

const Assignment = (props) => {
  const dragRef = useRef(null);
  const previewRef = useRef(null);

  const [_isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [_expanded, setExpanded] = useState(false);

  let TypeIcon = SchoolIcon; // assume assignment.assignment_type === 'verse_memory' and not must_memorize
  if (props.assignment.assignment_type === 'verse_memory' && props.assignment.assignment_object.must_memorize) {
    TypeIcon = TestTubeIcon;
  } else if (props.assignment.assignment_type === 'reading') {
    TypeIcon = AutoStoriesIcon;
  } else if (props.assignment.assignment_type === 'lecture') {
    TypeIcon = CampaignIcon;
  } else if (props.assignment.assignment_type === 'video') {
    TypeIcon = OndemandVideoIcon;
  }

  const [{handlerId}, drop] = useDrop({
    accept: 'assignment', // @TODO: constants
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveAssignment(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      props.onReorderAssignments();
    },
  });
  const [{isDragging}, drag, preview] = useDrag({
    type: 'assignment', // @TODO: move to constants?
    item: () => {
      return {id: props.assignment.id, index: props.index};
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const draggableStyles = {
    opacity: isDragging ? 0 : 1,
    // @TODO: other styles
  };
  drag(dragRef);
  drop(preview(previewRef));

  return (
    <Paper
      // @TODO: ref={dragPreview}
      ref={previewRef}
      data-handler-id={handlerId}
      style={{...styles.accordionWrapper, ...draggableStyles}}
    >
      <Accordion
        style={styles.accordionInner}// @TODO: change
        expanded={_expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
        // @TODO: get rid of the height increase on open (other accordions too)
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{/* @TODO: flip direction when open */}
          <span
            ref={dragRef}
            style={styles.dragIconWrapper}
          >
            <DragIndicatorIcon
              style={styles.dragIcon}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </span>
          <span style={styles.editRow}>
            <span style={styles.topRow}>
              <TypeIcon style={styles.typeIcon} />
              <span style={styles.fullTitleWrapper}>
                <h3 style={styles.assignmentName}>{props.assignment.name}</h3>{/* @TODO: change tag type? and styles */}
                <span style={styles.subTitle}>{utilities.prettyAvailablityDateRange(props.assignment.date_available, props.assignment.date_deadline)}</span>
              </span>
            </span>
            <span style={styles.actionsWrapper}>
              {_expanded &&
                <Tooltip title='Edit assignment'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsEditDialogOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
              {/* @TODO: only goals have draft/published status right now
              <PublishedStatus status={props.assignment.status} assignment={props.assignment} />
              */}
            </span>
          </span>
        </AccordionSummary>
        <AccordionDetails style={styles.assignmentDetails}>
          {props.assignment.assignment_type === 'video' && <Assignments.Video {...props.assignment.assignment_object} goalId={props.goal.id} />}
          {props.assignment.assignment_type === 'lecture' && <Assignments.Lecture {...props.assignment.assignment_object} goalId={props.goal.id} />}
          {props.assignment.assignment_type === 'verse_memory' && <Assignments.VerseMemory {...props.assignment.assignment_object} goalId={props.goal.id} />}
          {props.assignment.assignment_type === 'reading' && <Assignments.Reading {...props.assignment.assignment_object} goalId={props.goal.id} />}
        </AccordionDetails>
      </Accordion>
      {_isEditDialogOpen && <EditAssignmentDialog classroomGoal={props.classroomGoal} goal={props.goal} module={props.module} assignment={props.assignment} onClose={() => setIsEditDialogOpen(false)} />}
    </Paper>
  );
};

Assignment.propTypes = {
  goal: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape
  module: PropTypes.object.isRequired, // @TODO: shape
  assignment: PropTypes.object.isRequired, // @TODO: shape
  index: PropTypes.number.isRequired,
  onReorderAssignments: PropTypes.func.isRequired,
};

export default Assignment;
