/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchI18n,
  fetchI18nNotes,
  fetchLocales,
  saveNewTranslations,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OakAuth from '../../common/containers/OakAuth';
import Select from '@mui/material/Select';
// @TODO: import SortIcon from '@mui/icons-material/Sort';
import TextField from '@mui/material/TextField';
import Translation from './Translation';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import commonStyles from '../../commonStyles';
import {
  firebase,
} from '../../Globals';
import styles from './styles';
import {
  useParams,
} from 'react-router-dom';
import utilities from '../../utilities';

const JUST_IN_CASE_ARGS = Array(10).fill('<>');
const GENERAL_I18N_IDS = [964, 965, 966]; // @TODO: dynamic

const I18n = (props) => {
  const dispatch = useDispatch();

  const {
    localeCode,
  } = useParams();

  const [_newTranslationText, setNewTranslationText] = useState('');

  const locales = useSelector((state) => state.locales);
  const reduxI18nOther = useSelector((state) => state.i18n[localeCode]);
  const locale = locales[localeCode];

  const newTranslationsReturnValue = useSelector((state) => state.newTranslationsReturnValue);

  const i18nEn = utilities.useI18n('en');
  const i18nOther = utilities.useI18n(locale?.code);

  const [_isAuthOpen, setIsAuthOpen] = useState(false);
  const [_isAddTranslationDialogOpen, setIsAddTranslationDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLocales());
    dispatch(fetchI18n('en', true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchI18n(localeCode, true));
  }, [dispatch, localeCode]);

  const oakUser = useSelector((state) => state.oakUser);
  const isAdmin = oakUser?.id === 2;

  useEffect(() => {
    // @TODO: currently need to fetch all to get own votes without having to open notes: if (_isNotesOpen) {
    // @TODO: }
    const authUnsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser && locale?.code) {
        dispatch(fetchI18nNotes(locale.code, true));
      }

      return () => authUnsubscribe();
    });
  }, [dispatch, oakUser, locale?.code]);

  const notes = useSelector((state) => state.i18nNotes);

  const [_searchText, setSearchText] = useState('');
  const [_unreviewedCollapsed, setUnreviewedCollapsed] = useState(true);

  if (!locale) {
    // @TODO: redirect to login or home (or maybe just redirect to /i18n/en)
    return <span>no locale</span>;
  }

  const _arrayToEnglishList = (arr) => {
    return Object.values(locales).map((l) => l.english_name).join(', ').replace(/,(?!.*,)/gmi, Object.values(locales).length < 3 ? ' and' : ', and');
  };

  const searchTerms = _searchText.toLocaleLowerCase().split(' ');
  const filteredI18ns = (reduxI18nOther?.i18n || []).filter((otherI18n) => {
    if (otherI18n.disabled_at) {
      return false;
    }

    if (!searchTerms.length) {
      return true;
    }

    // add args just in case it's a function
    const enValue = i18nEn(otherI18n.key, ...JUST_IN_CASE_ARGS).toLocaleLowerCase();
    const otherValue = i18nOther(otherI18n.key, ...JUST_IN_CASE_ARGS).toLocaleLowerCase();

    return searchTerms.every((searchTerm) => {
      if (otherI18n.key.toLocaleLowerCase().includes(searchTerm)) {
        return true;
      }

      if (enValue.includes(searchTerm)) {
        return true;
      }

      if (otherValue.includes(searchTerm)) {
        return true;
      }

      return false;
    });
  }).map((otherI18n) => {
    let mostRecentVote = (notes[otherI18n.id] || []).filter((n) => n.user_id === oakUser?.id && n.upvoted !== null).pop()?.upvoted;
    if (mostRecentVote === undefined) {
      // can't do `|| -1` because `0` is a valid value
      mostRecentVote = -1;
    }

    return {
      ...otherI18n,
      mostRecentVote,
    };
  }).sort((a, b) => {
    if (GENERAL_I18N_IDS.includes(a.id)) {
      return -1;
    }
    if (GENERAL_I18N_IDS.includes(b.id)) {
      return 1;
    }

    // sort by general feedback, then no-vote, then downvoted, then upvoted, then updated_at
    if (a.mostRecentVote === b.mostRecentVote) {
      return (new Date(b.updated_at).getTime()) - (new Date(a.updated_at).getTime());
    }

    return a.mostRecentVote - b.mostRecentVote;
  });

  const isShowMoreVisible = !_searchText && _unreviewedCollapsed && filteredI18ns.filter((i) => i.mostRecentVote === -1).length > 5;

  return ( // @TODO: would be cool to have international flags in background or something
    <WebPageStub>
      <WebsiteSection
        isDarkBackground={true}
        title={
          <span style={styles.titleWrapper}>
            <h1 style={styles.h1}>Wrinkly translations</h1>
            <span style={styles.verseWrapper}>{/* @TODO: convert this to Passage component */}
              <span style={styles.verseBody}>{'Then Jesus came to them and said, "All authority in heaven and on earth has been given to me. Therefore go and make disciples of '}<span style={styles.bold}>all nations</span>{', baptizing them in the name of the Father and of the Son and of the Holy Spirit, and teaching them to obey everything I have commanded you. And surely I am with you always, to the very end of the age."'}</span>
              <span style={styles.reference}>Matthew 28:18-20, NIV</span>
            </span>
          </span>
        }
      >
        <p>{`Thanks so much for your help to translate Wrinkly!  With help from translators like you, Wrinkly is available for users who speak ${_arrayToEnglishList(Object.values(locales))}!`}</p>
        {!oakUser
          ? (
            <React.Fragment>
              <p>To view translations and provide feedback, please sign in:
                <Button
                  style={styles.signInButton}
                  color='secondary'
                  variant='contained'
                  onClick={() => setIsAuthOpen(true)}
                >
                  Sign in
                </Button>
              </p>
              <OakAuth
                appName='Wrinkly'
                // @TODO: authTypes={['anonymous', 'google', 'apple', 'email']}
                onClose={() => setIsAuthOpen(false)}
                isOpen={_isAuthOpen}
                skipDescription={true}
              />
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <p>{'If a translation is correct, please give it a "thumbs up".  If it is incorrect or you have a suggestion for a better translation, please click the "thumbs down" button.'}</p>
              <p>If you have any questions or comments in addition to the vote, they can be added by clicking the notes icon next to the thumbs up/down icons.</p>
              <p>Please be picky--any improvements to vocabulary, grammar, spelling, and colloquialisms are all welcome.  We and native users thank you for all improvements, both big and small!</p>
            </React.Fragment>
          )
        }
      </WebsiteSection>

      {!!oakUser &&
        <WebsiteSection
          title=''
          isDarkBackground={false}
        >
          {/* @TODO: group by section (and toggle isDarkBackground) and put in accordion? */}
          <div style={styles.controlsWrapper}>
            <FormControl style={styles.localeSelectWrapper}>{/* @TODO: min width should be max width of options */}
              <InputLabel id='demo-simple-select-label'>Locale</InputLabel>
              <Select
                labelId='locale-select-label'
                id='locale-select'
                value={locale?.code}
                label='Locale'
                onChange={(event) => { window.location = `/i18n/${event.target.value}`; }}
              >
                {Object.values(locales).map((l) => {
                  return (
                    <MenuItem key={l.code} value={l.code}>{`${l.name} (${l.code})`}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div style={{...commonStyles.searchBarWrapper, ...styles.fullWidth}}>{/* @TODO: abstract with help */}
              <TextField
                style={commonStyles.searchBar}
                value={_searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder=''
                fullWidth
                autoFocus={true}
                autoCapitalize='none'
                label='Search all text'
                variant='standard'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='clear search text'
                        onClick={() => setSearchText('')}
                        edge='end'
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {isAdmin &&
              <span style={styles.sortButtonWrapper}>
                <IconButton
                  aria-label='add-translation'
                  onClick={() => setIsAddTranslationDialogOpen(true)}
                  edge='end'
                >
                  <AddIcon />
                </IconButton>
              </span>
            }
            {/* @TODO:
            <span style={styles.sortButtonWrapper}>
              <IconButton
                aria-label='sort'
                onClick={() => {
                  // @TODO: implement
                  // @TODO: sort by group/screen, updated_at, etc.
                }}
                edge='end'
              >
                <SortIcon />
              </IconButton>
            </span>
            <span>
              <IconButton
                aria-label='sort'
                onClick={() => {
                  // @TODO: implement
                  // @TODO: filters to hide completed translations
                }}
                edge='end'
              >
                <FilterIcon />
              </IconButton>
            </span>
            */}
          </div>
          {/* @TODO: add general discussion board here */}
          {isShowMoreVisible
            ? (
              <React.Fragment>
                {filteredI18ns.slice(0, 5).map((i, index) => {
                  return (
                    <Translation
                      key={i.id}
                      i18nId={i.id}
                      i18nKey={i.key}
                      mostRecentVote={i.mostRecentVote}
                      englishValue={i18nEn(i.key, ...JUST_IN_CASE_ARGS)}
                      locale={locale}
                      localeValue={i.value}
                      searchTerms={searchTerms}
                      isGeneralFeedback={GENERAL_I18N_IDS.includes(i.id)}
                    />
                  );
                })}
                <span
                  style={styles.showAllToggle}
                  onClick={() => {
                    setUnreviewedCollapsed(!_unreviewedCollapsed);
                  }}
                >
                  {`show all unreviewed (${filteredI18ns.filter((i) => i.mostRecentVote === -1).length})`}
                </span>
                {filteredI18ns.filter((i) => i.mostRecentVote !== -1).map((i, index) => {
                  return (
                    <Translation
                      key={i.id}
                      i18nId={i.id}
                      i18nKey={i.key}
                      mostRecentVote={i.mostRecentVote}
                      englishValue={i18nEn(i.key, ...JUST_IN_CASE_ARGS)}
                      locale={locale}
                      localeValue={i.value}
                      searchTerms={searchTerms}
                      isGeneralFeedback={GENERAL_I18N_IDS.includes(i.id)}
                    />
                  );
                })}
              </React.Fragment>
            )
            : (
              filteredI18ns.map((i, index) => {
                return (
                  <Translation
                    key={i.id}
                    i18nId={i.id}
                    i18nKey={i.key}
                    mostRecentVote={i.mostRecentVote}
                    englishValue={i18nEn(i.key)}
                    locale={locale}
                    localeValue={i.value}
                    searchTerms={searchTerms}
                    isGeneralFeedback={GENERAL_I18N_IDS.includes(i.id)}
                  />
                );
              })
            )
          }
          {!filteredI18ns.length &&
            <p style={styles.emptyScreenText}>{/* @TODO: move to commonStyles */}
              {`No search results for "${_searchText}"`}
            </p>
          }
        </WebsiteSection>
      }

      {_isAddTranslationDialogOpen &&
        <Dialog
          open={true}
          onClose={() => setIsAddTranslationDialogOpen(false)}
        >
          <DialogTitle id='alert-dialog-title-todo'>
            Add translation
          </DialogTitle>
          <DialogContent>
            <span>
              JSON in the format:
              <pre>
                {`{
  "psalmText": {
    "en": "\\"I have hidden your word in my heart that I might not sin against you.\\"",
    "es": "\\"En mi corazón atesoro tus dichos para no pecar contra ti.\\"",
    "de": "\\"In meinem Herzen habe ich dein Wort verwahrt, auf daß ich nicht wider dich sündige.\\""
  },
  "psalmReference": {
    "en": "Psalm 119:11 NIV",
    "es": "Salmos 119:11 NVI",
    "de": "Psalm 119:11 ELB"
  }
}`}
              </pre>
            </span>
            <TextField
              style={styles.addTranslationTextInput}
              label='JSON'
              variant='outlined'
              multiline
              minRows={7}
              maxRows={14}
              required={true}
              value={_newTranslationText}
              onChange={(e) => setNewTranslationText(e.target.value)}
            />
            <p>{newTranslationsReturnValue}</p>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='text'
              autoFocus={false}
              onClick={() => setIsAddTranslationDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              autoFocus={false}
              onClick={() => {
                dispatch(saveNewTranslations(_newTranslationText));
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      }

    </WebPageStub>
  );
};

export default I18n;
