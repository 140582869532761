/**
 * Ryan O'Dowd
 * 2024-03-03
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

// @TODO: delete ones that aren't used
const styles = {
  container: {
    flex: 1,
    maxHeight: '80%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxWidth: Globals.maxWidth,
    // @TODO: max height too? make sure there's a little margin on all sides
    paddingBottom: 0,
    color: Globals.colors.primaryExtraDark,
  },
  moduleName: {
    margin: 0,
    padding: 0,
    color: Globals.colors.primaryExtraLight,
  },
  editIconButton: {
    margin: 4,
    color: Globals.colors.primaryExtraLight,
  },
  editIconButtonCheck: {
    marginLeft: 8,
    color: Globals.colors.primaryExtraDark,
    backgroundColor: Globals.colors.accent,
  },
  editIconButtonClose: {
    backgroundColor: Globals.colors.alert,
  },
  accordionWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    transform: 'translate3d(0, 0, 0)', // https://github.com/react-dnd/react-dnd/issues/832#issuecomment-442071628
  },
  coloredAccordion: {
    // @TODO: gradient not working in production
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  accordionInner: {
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.white,
  },
  dragIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    cursor: 'grab',
  },
  assignmentDetails: {
    color: Globals.colors.primaryExtraDark,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  assignmentName: {
    margin: 0,
    padding: 0,
    color: Globals.colors.primaryExtraDark,
  },
  typeIcon: {
    marginRight: 8,
    color: Globals.colors.primary,
  },
  editRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  fullTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitle: {
    padding: 0,
    margin: 0,
    color: Globals.colors.primaryExtraDark,
    lineHeight: 1,
  },
  subTitle: {
    color: Globals.colors.accentDark,
    fontSize: '0.8em',
    lineHeight: 1,
  },
  actionsWrapper: { // @TODO: abstract with assignment styles
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  userTypeButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 24,
    marginBottom: 16,
  },
  nameEmailWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameTextField: {
    marginLeft: 16,
    marginRight: 8,
  },
  emailTextField: {
    marginLeft: 8,
    marginRight: 16,
  },
  formError: {
    color: Globals.colors.alert,
    fontSize: 12,
    height: 20,
  },
  infoIcon: {
    color: Globals.colors.primary,
    fontSize: 16,
  },
  joinBody: {
    marginBottom: 16,
  },
  joinText: {
    lineHeight: 1.8,
  },
  code: {
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    fontWeight: '600',
    letterSpacing: 4,
    borderRadius: 8,
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 8,
  },
  organizationName: {
    fontWeight: '600',
  },
  downloadLinksWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 16,
  },
  paymentButtonGroup: {
    marginTop: 8,
    marginBottom: 8,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
  sectionContent: {
    marginTop: 4,
    paddingRight: 16,
    paddingLeft: 16,
  },
  specialText: {
    fontWeight: 'bold',
  },
  submitButton: {
    marginTop: 8,
  },
  customInput: {
    margin: 0,
    padding: 4,
    border: 'none',
    borderBottom: '2px solid',
    borderColor: Globals.colors.primary,
    width: 70,
    fontSize: '0.875rem', // @TODO: taken from mui
    outline: 'none',
    textAlign: 'right',
    backgroundColor: 'transparent',
  },

  // @TODO: abstract with Campaign/styles
  headerMultiline: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    lineHeight: 1,
  },
  caps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  wrinkleText: {
    color: Globals.colors.accentDark,
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 'initial',
  },
  blackText: {
    color: Globals.colors.black,
  },

  bold: {
    fontWeight: 'bold',
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  totalCell: {
    border: 'none',
    borderTop: '2px solid',
    borderColor: Globals.colors.black,
  },
  helpIcon: {
    color: Globals.colors.primary,
    fontSize: 18,
    cursor: 'pointer',
  },
};

export default styles;
