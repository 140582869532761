/*
 * Ryan O'Dowd
 * 2024-01-24
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    textDecoration: 'none',
    // @TODO: marginBottom: 64,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.primaryExtraDark,
    paddingTop: 4,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    cursor: 'pointer',
    width: 300,
    height: 140,
  },
  nameAndStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  goalName: {
    marginTop: 8,
    marginBottom: 0,
    fontSize: 24,
  },
  goalDates: {
    color: Globals.colors.accent,
    fontSize: 14,
  },
  icon: {
    marginTop: 8,
    marginLeft: 8,
  },
  publishedIcon: {
    color: Globals.colors.accentDark,
  },
  draftIcon: {
    color: Globals.colors.warnIncorrect,
  },
  goalMetadataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  goalMetadata: {
    fontStyle: 'italic',
    fontSize: 14,
  },
};

export default styles;
