/**
 * Ryan O'Dowd
 * 2022-07-23
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    flex: 1,
    maxHeight: '80%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxWidth: 400,
    paddingBottom: 0,
    color: Globals.colors.primaryExtraDark,
  },
  fullTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitle: {
    color: Globals.colors.primaryExtraDark,
    lineHeight: 1,
  },
  subTitle: {
    color: Globals.colors.accentDark,
    fontSize: '0.8em',
    lineHeight: 1,
  },
  userTypeButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 24,
    marginBottom: 16,
  },
  nameEmailWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameTextField: {
    marginLeft: 16,
    marginRight: 8,
  },
  emailTextField: {
    marginLeft: 8,
    marginRight: 16,
  },
  formError: {
    color: Globals.colors.alert,
    fontSize: 12,
    height: 20,
  },
  infoIcon: {
    color: Globals.colors.primary,
    fontSize: 16,
  },
  joinBody: {
    marginBottom: 16,
  },
  joinText: {
    lineHeight: 1.8,
  },
  code: {
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    fontWeight: '600',
    letterSpacing: 4,
    borderRadius: 8,
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 8,
  },
  organizationName: {
    fontWeight: '600',
  },
  downloadLinksWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 16,
  },
  paymentButtonGroup: {
    marginTop: 8,
    marginBottom: 8,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
  sectionContent: {
    marginTop: 4,
    paddingRight: 16,
    paddingLeft: 16,
  },
  specialText: {
    fontWeight: 'bold',
  },
  submitButton: {
    marginTop: 8,
  },
};

export default styles;
