/**
 * Ryan O'Dowd
 * 2022-11-30
 * © Copyright 2023 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const OakText = (props) => {
  const {
    style,
    children,
    ...restProps
  } = props;

  return (
    <span
      style={{...styles.container, ...(Array.isArray(style) ? {...style} : style)}}
      {...restProps}
    >
      {children}
    </span>
  );
};

OakText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]).isRequired,

  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default OakText;
