/**
 * Ryan O'Dowd
 * 2022-03-13
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  applyMiddleware,
  createStore, // @TODO: createStore is deprecated in favor of redux toolkit: https://redux.js.org/usage/migrating-to-modern-redux; wait...according to the docs, "createStore will continue to work indefinitely, and will not ever be removed. But, today we want all Redux users to be using Redux Toolkit for all of their Redux logic."
} from 'redux';
import {
  createMigrate,
  persistReducer,
  persistStore,
} from 'redux-persist';
import Globals from './Globals';
import PlatformGlobals from './PlatformGlobals';
import {
  apiMiddleware,
} from 'redux-api-middleware';
import rootReducer from './reducers';
import {
  thunk,
} from 'redux-thunk';

let storage = {};
if (Globals.platform === 'web') {
  storage = require('redux-persist/lib/storage').default;
} else {
  // this is native (`platform` is `ios` or `android`)
  storage = require('@react-native-async-storage/async-storage').default;
}

const migrations = {
  1: (state) => {
    // adding "what's new" notification preference
    const newState = {...state};
    if (newState.settingsNotificationPreferences) {
      newState.settingsNotificationPreferences[PlatformGlobals.channelIds?.WHATS_NEW] = 1;
    }
    return newState;
  },
  2: (state) => {
    // verses reducer now has objects of shape {verseId, verseText, heading, footnotes} at the leaf node for verseNumber instead of raw text only
    const newState = {...state};
    newState.verses = {}; // it would be better to see which verses are already downloaded and to refetch them, but this is way easier and less error prone
    newState.wrinkles = {}; // used to be an array...keying by verseId is going to be better overall
    return newState;
  },
  3: (state) => {
    // introducting modules instead of passages in goals
    const newState = {...state};
    newState.goals = {};
    return newState;
  },
};

const persistConfig = {
  key: 'wrinklyRoot',
  version: 3, // @NOTE: only change this when the store should be wiped before rehydrated (or there's a migration); incrementing this is better than having users need to uninstall and reinstall because the reducer shape has changed
  storage,
  blacklist: [
    'fetches',
    'walkthrough', // @TODO: is this still used?
    'peanutsSevenDays',
    'loadingStates',
    'newTranslationsReturnValue', // @TODO: is this still used?
    'versionSupport',
    'goalPreviews',
  ],
  migrate: createMigrate(migrations, {debug: false}),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, applyMiddleware(thunk, apiMiddleware));
const configureStore = () => {
  // @TODO: const store = applyMiddleware(thunk, apiMiddleware)(createStore)(persistedReducer, {});
  const persistor = (callback) => persistStore(store, null, callback); // @TODO: this callback is causing the `Warning: Cannot update a component from inside the function body of a different component.` error. i think this is what i want to do though...wish i could supress the warning, but i need to be able to keep light/dark mode accurate

  // @NOTE: uncomment when store needs reset; IMPORTANT: do NOT leave uncommented during a deployment
  // persistor().purge();

  return {
    store,
    persistor,
  };
};

export default configureStore;
