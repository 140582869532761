/*
 * Ryan O'Dowd
 * 2022-09-24
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    // @TODO: marginBottom: 64,
  },
  selectedIndicator: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: Globals.colors.accent,
  },
  campaignName: {
    marginTop: 8,
    marginBottom: 0,
  },
  organizationName: {
    fontSize: 14,
  },
  startAndEndDates: {
    paddingTop: 8,
    fontSize: 12,
    color: Globals.colors.accentLight, // @TODO: maybe different if past the dates
  },
  downloadLinks: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  tableWrapper: {
    padding: 8,
    margin: 8,
    color: Globals.colors.primaryExtraLight,
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: 32,
  },
  sectionInner: {
    width: '100%',
    maxWidth: 1000,
  },
  rightSection: {
    textAlign: 'right',
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  contactWrapper: {
    minWidth: '50%',
    flex: 1,
  },
  downloadCtaWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },

  headerCell: {
    fontWeight: 'bold',
  },
  totalCell: {
    fontWeight: 'bold',
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  progressBar: {
    width: 100,
  },
  infinityProgress: {
    fontSize: 20,
    color: Globals.colors.complete,
  },
  progressCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  chartWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  chartInner: {
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.white,
  },
  h3: {
    margin: 0,
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 12,
    margin: 'auto',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    // @TODO: elevation
  },
  chartTooltip: {
    backgroundColor: Globals.colors.primaryDark,
    color: Globals.colors.primaryDark,
  },
  tooltipWrinkles: {
    color: Globals.colors.gray,
  },

  // @TODO: abstract footer stuff
  footerWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
    color: Globals.colors.white,
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  footerImage: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  footerButton: {
    marginLeft: 8,
  },

  campaignCard: {
    minWidth: 300,
    maxWidth: 300,
  },
  cardMedia: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    width: 'calc(100% - 32px)',
    objectFit: 'contain',
    backgroundColor: Globals.colors.primaryExtraDark,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.primaryExtraDark,
    paddingTop: 4,
  },
};

export default styles;
