/**
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Lecture from './Lecture';
import Reading from './Reading';
import VerseMemory from './VerseMemory';
import Video from './Video';

const Assignments = {
  Lecture,
  Reading,
  VerseMemory,
  Video,
};

export default Assignments;
