/**
 * Ryan O'Dowd
 * 2022-07-23
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const BORDER_RADIUS = 12;

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  option: {
    display: 'flex',
    width: '100%',
    height: 20,
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    color: Globals.colors.white,
    backgroundColor: Globals.colors.primaryDark,
    borderColor: Globals.colors.primaryExtraDark,
    borderStyle: 'solid',
    padding: 10,
    borderWidth: 1,
    opacity: 0.8,
  },
  first: {
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
  },
  middle: {
    borderLeft: 'none',
  },
  last: {
    borderTopRightRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    borderLeft: 'none',
  },
  selected: {
    opacity: 1.0,
    color: Globals.colors.primaryExtraDark,
    backgroundColor: Globals.colors.accent,
  },
  customAmountText: {
    fontSize: 12,
  },
  customInput: {
    margin: 0,
    padding: 4,
    border: 'none',
    borderBottom: '2px solid',
    borderColor: Globals.colors.primary,
    width: 220,
    fontSize: 16,
    outline: 'none',
  },
};

export default styles;
