/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  outerContainer: { // @TODO: abstract with home
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    backgroundColor: Globals.colors.primaryExtraLight,
    color: Globals.colors.primaryExtraDark,
  },
  tabsWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
  },
  tab: {
    color: Globals.colors.primaryExtraLight,
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px - 35px - 114px)', // @TODO: header and footer heights; check mobile; don't hardcode; no idea what the 114px is
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
  },
  campaignName: {
    marginTop: 0,
    marginBottom: 0,
  },
  startAndEndDates: {
    display: 'block',
    fontSize: 16,
    color: Globals.colors.accentDark, // @TODO: maybe different if past the dates
    marginBottom: 32,
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  logoWrapper: {
    display: 'block',
    maxWidth: 128,
    height: 64,
    maxHeight: 64,
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWrapper: {
  },
  text: {
    marginTop: 0,
  },
  imagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
  },
  image: {
    borderRadius: 32,
    borderStyle: 'solid',
    borderWidth: 5,
    borderColor: Globals.colors.primaryLight,
    width: 205,
    margin: 16,
  },
  statsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  statText: {
    fontSize: '3em',
    fontWeight: '3em',
    color: Globals.colors.accent,
    marginBottom: -8,
    paddingLeft: 12,
    paddingRight: 12,
  },
  downloadLinks: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  // @TODO: abstract with AdditionalDonationDialog/styles
  headerMultiline: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    lineHeight: 1,
  },
  caps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  wrinkleText: {
    color: Globals.colors.accentDark,
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 'initial',
  },
  blackText: {
    color: Globals.colors.black,
  },

  tableWrapper: {
    padding: 8,
    margin: 8,
    color: Globals.colors.primaryExtraLight,
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: 32,
  },
  sectionInner: {
    width: '100%',
    maxWidth: 1000,
  },
  rightSection: {
    textAlign: 'right',
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  contactWrapper: {
    minWidth: '50%',
    flex: 1,
  },
  downloadCtaWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },

  headerCell: {
    fontWeight: 'bold',
  },
  totalCell: {
    fontWeight: 'bold',
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  progressBar: {
    width: 100,
  },
  infinityProgress: {
    fontSize: 20,
    color: Globals.colors.complete,
  },
  progressCell: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  chartWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  chartInner: {
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.white,
  },
  h3: {
    margin: 0,
  },
  chart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 12,
    margin: 'auto',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    // @TODO: elevation
  },
  chartTooltip: {
    backgroundColor: Globals.colors.primaryDark,
    color: Globals.colors.primaryDark,
  },
  tooltipWrinkles: {
    color: Globals.colors.gray,
  },

  // @TODO: abstract footer stuff
  footerWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
    color: Globals.colors.white,
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  footerImage: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  footerButton: {
    marginLeft: 8,
  },
  dialog: {
    // @TODO:
  },
  dialogContent: {
    // @TODO:
    width: 500,
  },
  code: {
    padding: 2,
    backgroundColor: Globals.colors.primaryExtraLight,
    fontSize: 12,
    borderRadius: 6,
  },
  copyIcon: {
    fontSize: 12,
    cursor: 'pointer',
  },
  dialogSubSectionHeaderText: {
    color: Globals.colors.primaryExtraDark,
    marginBottom: 0,
    paddingBottom: 0,
  },
  joinCode: {
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    fontWeight: '600',
    letterSpacing: 4,
    borderRadius: 8,
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 8,
  },
  emails: {
    minHeight: 100,
  },
  newEmail: {
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
  },
  alreadyInvitedEmail: {
    backgroundColor: Globals.colors.complete,
    color: Globals.colors.primaryExtraDark,
  },
  alreadyAcceptedEmail: {
    backgroundColor: Globals.colors.alert,
    color: Globals.colors.white,
  },
  legend: {
    paddingTop: 16,
    lineHeight: 2,
  },
  legendText: {
    padding: 6,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: '600',
  },
};

export default styles;
