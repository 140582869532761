/*
 * Ryan O'Dowd
 * 2023-09-04
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  articleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  articleSectionHeader: {
    marginBottom: 4,
  },
  articleParagraph: {
    marginTop: 4,
  },
  attributionWrapper: {
    marginTop: 64,
  },
  attributionText: {
    fontStyle: 'italic',
    fontSize: 14,
  },
  downloadCtaWrapper: { // @TODO: abstract with LandingPage
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },
};

export default styles;
