/*
 * Ryan O'Dowd
 * 2023-09-04
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import DownloadLinks from '../../components/DownloadLinks';
import React from 'react';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';

const Articles = (props) => {
  return (
    <WebPageStub>
      <WebsiteSection
        title='The Case for Scripture Memory in the Digital Age'
      >{/* @TODO: make sure this looks good on mobile */}
        <div style={styles.articleWrapper}>
          <p style={styles.articleParagraph}>{`It wasn't long ago that many of us knew the phone numbers of most family members and a handful of close friends.  Now, it's common not to have any phone numbers memorized.  As technology becomes more ubiquitous, our reliance on it increases.  Why commit something to memory if it’s only a few keystrokes away?`}</p>

          <p style={styles.articleParagraph}>{`Even though some information is easily accessible, there still are benefits to committing things to memory.  For example, memorizing multiplication tables is foundational to understanding numbers and more complex mathematical concepts.  Learning vocabulary is foundational for language development.  Similarly, memorizing Scripture is a spiritually foundational tool and a practice worth prioritizing.  Today, we'll focus on four reasons for this.`}</p>

          <h3 style={styles.articleSectionHeader}>{`It's Mandated`}</h3>
          <p style={styles.articleParagraph}>{`When God had given Moses the 10 Commandments, part of Moses’s message to the Israelites was, “These commandments that I give you today are to be on your hearts. Impress them on your children. Talk about them when you sit at home and when you walk along the road, when you lie down and when you get up.  Tie them as symbols on your hands and bind them on your foreheads.  Write them on the doorframes of your houses and on your gates.” Deuteronomy 6:6-9 NIV.  This was a direct command to ensure that God’s Law is to be at the core of all thoughts and decisions.`}</p>

          <p style={styles.articleParagraph}>{`When Joshua took over after Moses's death, God told him, "Keep this Book of the Law always on your lips; meditate on it day and night, so that you may be careful to do everything written in it.  Then you will be prosperous and successful." Joshua 1:8 NIV`}</p>

          <p style={styles.articleParagraph}>{`These passages indicate how fundamental God’s commands should be to our lives.  They’re not just rules to follow but a lifestyle by which to abide.`}</p>

          <h3 style={styles.articleSectionHeader}>{`It’s Transformative`}</h3>
          <p style={styles.articleParagraph}>{`"I have hidden your word in my heart that I might not sin against you." Psalm 119:11 NIV.  Knowing God’s Word improves our discernment.`}</p>

          <p style={styles.articleParagraph}>{`"For the mouth speaks what the heart is full of.  A good man brings good things out of the good stored up in him, and an evil man brings evil things out of the evil stored up in him." Matthew 12:34b-35 NIV.  Just like a healthy diet is good for the body, the more we fill our hearts and minds with God's Word, the less room there is for things that are not of God.  Beyond that, it will also transform our hearts and minds to be more like Him.`}</p>

          <p style={styles.articleParagraph}>{`2 Timothy 3 ends with, "All Scripture is God-breathed and is useful for teaching, rebuking, correcting and training in righteousness, so that the servant of God may be thoroughly equipped for every good work." 2 Timothy 3:16-17.  God gave us the Scriptures to teach us His commands and direct us in the way we should behave, but He also gave them to help us to know Him better.  We don’t meditate on His Word only to remember His commands but also to learn His heart.`}</p>

          <h3 style={styles.articleSectionHeader}>{`It’s Healthy`}</h3>
          <p style={styles.articleParagraph}>{`The brain benefits of memorization are well documented.  (`}<a href='https://www.tutorhunt.com/resource/22593/blog/in-praise-of-memorization-10-proven-brain-benefits/'>Here</a>{` is a brief overview of a few.)  Just like consistent, physical exercise keeps the body healthy, consistent memory challenges keep the brain active and help to mitigate cognitive decline.  Even if it's memorizing a verse or two per month, consistently working on committing Scripture to memory is a healthy exercise at every age.`}</p>

          <h3 style={styles.articleSectionHeader}>{`It's Fun`}</h3>
          <p style={styles.articleParagraph}>{`Working towards a goal can be fun.  Challenge a friend or two to join you in a specific memory plan.  Maybe your goal is to meditate on one verse each week for a month, to memorize a Psalm, or to commit a whole book to memory.  Whatever the goal, having accountability can increase your chances of success and make the journey more enjoyable.`}</p>

          <p style={styles.articleParagraph}>{`Consider making Scripture memory part of your regular routine of Bible study and worship.  It doesn't have to be a large time commitment; devoting 5-10 minutes per day meditating on Scripture is a great place to start.  Replacing time spent scrolling on social media will fit into your existing daily routine, and it's a much more productive use of time and brain power!`}</p>

          <div style={styles.attributionWrapper}>
            <span style={styles.attributionText}>Author: <b>{`Ryan O'Dowd`}</b></span><br />{/* @TODO: add bio somewhere */}
            <span style={styles.attributionText}>This article was also published to <a href='https://www.curriculumtrak.com/blog/the-case-for-scripture-memory-in-the-digital-age'>Curriculum Trak</a> on September 12, 2023.</span>
          </div>
        </div>
      </WebsiteSection>

      <WebsiteSection
        isDarkBackground={true}
      >
        <div style={styles.downloadCtaWrapper}>
          <h2>Try Wrinkly</h2>
          <span style={styles.downloadLinks}>
            <DownloadLinks />
          </span>
        </div>
      </WebsiteSection>
    </WebPageStub>
  );
};

export default Articles;
