/*
 * Ryan O'Dowd
 * 2023-10-26
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  pageTitle: {
    color: Globals.colors.primaryExtraDark,
    textAlign: 'center',
  },
  statsParagraph: { // @TODO: combine with LandingPage/styles.web.js
    marginBottom: 16,
  },
  statText: { // @TODO: combine with LandingPage/styles.web.js
    fontSize: '1.5em',
    fontWeight: '3em',
    color: Globals.colors.primaryLight,
    marginBottom: -4,
    paddingLeft: 4,
    paddingRight: 4,
  },
  downloadCtaSection: {
    marginTop: 32,
  },
  downloadCtaWrapper: { // @TODO: abstract with LandingPage
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 64,
  },
};

export default styles;
