/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Globals.colors.primaryExtraDark,
    color: Globals.colors.white,
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 4,
    marginLeft: 8,
    marginBottom: 4,
    alignItems: 'center',
    // @TODO: height: 72,
  },
  menuSectionHeader: {
    marginTop: 12,
    marginLeft: 16,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 'bold',
  },
  nested: (levels) => {
    return {
      marginLeft: levels * 16,
    };
  },
  logoAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'initial',
    color: 'white',
  },
  logo: {
    width: 38,
    height: 38,
    marginRight: 8,
  },
  appTitle: {
    fontSize: Globals.isMobile ? 32 : 42,
    fontWeight: '800',
    margin: 0,
    // @TODO: fontFamily: Globals.fonts.primary,
  },
  navigationTabsWrapper: {
    marginTop: 2,
    marginLeft: 8,
    marginBottom: 2,
    marginRight: 8,
  },
  navigationTabsWrapperMobile: {
    flex: 1,
    justifySelf: 'flex-start',
    marginLeft: 0,
  },
  betaText: {
    alignSelf: 'flex-start',
    fontSize: 12,
    paddingTop: 8,
    fontWeight: '800',
    cursor: 'pointer',
  },
  buttons: {
    // @TODO: position: 'absolute',
    // @TODO: right: 8,
  },
  loginButton: {
    color: 'white',
  },
  userImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    marginRight: 12,
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  accountText: {
    fontStyle: 'italic',
  },
  usernameText: {
    marginTop: -6,
    fontSize: '0.9rem',
  },
  emailText: {
    marginTop: -6,
    fontSize: '0.75rem',
  },
  planText: {
    fontSize: '0.9rem',
  },
  iconMenuItemText: {
    marginLeft: 8,
  },
};

export default styles;
