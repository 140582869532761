/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  titleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  h1: {
    margin: 0,
    minWidth: 400,
  },
  verseWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: '100',
    fontStyle: 'italic',
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    color: Globals.colors.extraLightGray,
    borderLeftColor: Globals.colors.primaryExtraLight,
    marginTop: 16,
    padding: 8,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
  },
  verseBody: {
  },
  bold: {
    fontWeight: '900',
    color: Globals.colors.accent,
  },
  reference: {
    fontStyle: 'initial',
    fontWeight: '600',
    fontSize: 16,
    marginTop: 4,
  },
  signInButton: {
    marginLeft: 8,
  },
  showAllToggle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Globals.colors.primary,
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 32,
    fontSize: 14,
  },
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 8,
  },
  localeSelectWrapper: {
    display: 'flex',
  },
  fullWidth: {
    flex: 1,
    justifySelf: 'center',
  },
  sortButtonWrapper: {
    width: 40,
    padding: 8,
  },
  addTranslationTextInput: {
    width: '100%',
  },
};

export default styles;
