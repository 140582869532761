/**
 * Ryan O'Dowd
 * 2022-07-23
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import CurrencyInput from 'react-currency-input-field';
import PropTypes from 'prop-types';
import styles from './styles';
import utilities from '../../../utilities';

const OakButtonGroup = (props) => {
  const [_customAmount, setCustomAmount] = useState(0);
  const [_tempCustomAmount, setTempCustomAmount] = useState(0);
  const [_customSelected, setCustomSelected] = useState(false);
  const [_isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);

  return (
    <div style={{...styles.container, ...props.containerStyles}}>
      {props.options.map((option, index) => {
        return (
          <span
            key={`${option.value}${props.selectedValue}`}// @TODO: there has to be a better way to force a rerender when the selectedValue changes...
            style={{
              ...styles.option,
              ...((!_customSelected && props.selectedValue === option.value) ? styles.selected : {}),
              ...(index === 0 ? styles.first : {}),
              ...((!props.includeCustomCurrencyInput && index === props.options.length - 1) ? styles.last : {}),
              ...(((index !== 0) && (index !== props.options.length - 1)) ? styles.middle : {}),
            }}
            onClick={() => {
              setCustomSelected(false);
              props.onSelect(option.value);
            }}
          >
            <span>{option.title}</span>
          </span>
          // @TODO: info marker with descriptions if available
        );
      })}
      {props.includeCustomCurrencyInput && (
        <React.Fragment>
          <span
            key='customInput'
            style={{
              ...styles.option,
              ...(_customSelected ? styles.selected : {}),
              ...styles.last,
            }}
            onClick={() => {
              setCustomSelected(true);
              setIsCustomDialogOpen(true);
              props.onSelect(_customAmount);
            }}
          >
            {_customAmount
              ? <span>{utilities.prettyCurrency(_customAmount)}</span>
              : <span style={styles.customAmountText}>Custom amount</span>
            }
          </span>
          <Dialog
            style={styles.customInputDialog}
            open={_isCustomDialogOpen}
            fullScreen={false}
            onClose={() => setIsCustomDialogOpen(false)}
          >
            <DialogTitle id='alert-dialog-title'>
              Set custom amount
            </DialogTitle>
            <DialogContent style={styles.innerContainer}>
              <CurrencyInput
                style={styles.customInput}
                placeholder='Enter custom amount here'
                disableGroupSeparators={true}
                allowNegativeValue={false}
                autoFocus={true}// @TODO: doesn't work
                decimalsLimit={2}
                decimalScale={2}
                onValueChange={(value, name) => {
                  setTempCustomAmount(+value);
                }}
                prefix='$'
                disableAbbreviations={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                onClick={() => {
                  setIsCustomDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setCustomAmount(_tempCustomAmount * 100);
                  props.onSelect(_tempCustomAmount * 100);
                  setIsCustomDialogOpen(false);
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </div>
  );
};

OakButtonGroup.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,

  includeCustomCurrencyInput: PropTypes.bool,
  containerStyles: PropTypes.object,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OakButtonGroup;
