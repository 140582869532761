/*
 * Ryan O'Dowd
 * 2024-03-10
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  AdapterDateFns,
} from '@mui/x-date-pickers/AdapterDateFnsV3';
import CloseIcon from '@mui/icons-material/Close';
import {
  DatePicker,
} from '@mui/x-date-pickers/DatePicker';
import {
  LocalizationProvider,
} from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const DateRange = (props) => {
  return (
    <div>
      <span style={styles.headerText}>Dates available{props.headerRight}</span>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <span style={styles.innerContainer}>
          <span style={styles.pickerAndButtonWrapper}>
            <span style={styles.pickerWrapper}>
              <DatePicker
                label='Date available'
                value={props.dateAvailable}
                onChange={(newValue) => props.setDateAvailable(newValue)}
                minDate={props.minDateAvailable}
                maxDate={props.maxDateAvailable}
              />
            </span>
            {!props.hideClear &&
              <span style={styles.buttonWrapper}>
                <Tooltip title='Clear available from date'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() => props.setDateAvailable(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          </span>
          <span style={styles.pickerAndButtonWrapper}>
            <span style={styles.pickerWrapper}>
              <DatePicker
                label='Due date'
                value={props.dateDeadline}
                onChange={(newValue) => props.setDateDeadline(newValue)}
                minDate={props.minDateDeadline}
                maxDate={props.maxDateDeadline}
              />
            </span>
            {!props.hideClear &&
              <span style={styles.buttonWrapper}>
                <Tooltip title='Clear due date'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() => props.setDateDeadline(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          </span>
        </span>
      </LocalizationProvider>
    </div>
  );
};

DateRange.propTypes = {
  setDateAvailable: PropTypes.func.isRequired,
  setDateDeadline: PropTypes.func.isRequired,

  headerRight: PropTypes.node,
  dateAvailable: PropTypes.object,
  dateDeadline: PropTypes.object,
  minDateAvailable: PropTypes.object,
  maxDateAvailable: PropTypes.object,
  minDateDeadline: PropTypes.object,
  maxDateDeadline: PropTypes.object,
  hideClear: PropTypes.bool,
};

export default DateRange;
