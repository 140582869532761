/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import CPassage from '../../../classes/Passage';
import Globals from '../../../Globals';
// @TODO: import Popover from 'react-native-popover-view';
import PropTypes from 'prop-types';
/* @TODO:
import {
  Skeleton,
} from '@rneui/themed';
*/
import Text from '../../../common/components/OakText';
import {
  Tooltip,
} from '@mui/material';
// @TODO: import WrinkleBadge from '../../../components/WrinkleBadge';
import commonStyles from '../../../commonStyles';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../../utilities';

const Verse = (_p) => {
  const props = {
    defaultTextColor: Globals.colors.primaryExtraDark,
    ..._p,
  };

  const isDarkMode = useSelector((state) => state.settingsLightDarkMode === 'dark');

  const [_visibleFootnote, setVisibleFootnote] = useState({}); // @TODO: useState(null);
  const [_isWrinkleBadgeOpen, setIsWrinkleBadgeOpen] = useState(false);

  const footnoteRefs = useRef({});

  const [bookApiId, chapterNumber, verseNumber] = props.verseApiId.split('.');

  const wrinkle = utilities.useGetWrinkleForVerseApiId(props.verseApiId, props.doesTranslationAffectWrinkleStatus && props.translationId);

  const book = utilities.getBookFromApiId(bookApiId);

  const passageId = -1;
  const verse = utilities.useGetVersesForPassages([new CPassage(
    passageId,
    +props.translationId,
    +book.id,
    +chapterNumber,
    +verseNumber,
    +verseNumber,
  )], props.skipFetch)[passageId][0];

  const verseContent = useMemo(() => {
    const numLeadingSpaces = verse.verseText?.search(/\S/) || 0;
    let content = verse.verseText?.trimStart() || '';
    if (!props.showFormatting) {
      content = utilities.removeVerseFormatting(content);
    }
    const contentToRender = [];
    let prevPosition = 0;
    if (props.showFootnotes) {
      verse.footnotes?.forEach((footnote) => { // @TODO: shouldn't need null checking
        contentToRender.push(content.substring(prevPosition, footnote.position_in_verse_text - numLeadingSpaces));
        prevPosition = footnote.position_in_verse_text - numLeadingSpaces;

        contentToRender.push(
          <Tooltip// @TODO: this shouldn't be on a new line
            key={footnote.id}
            // @TODO: style={commonStyles.popoverTitle}
            title={
              <React.Fragment>
                {/* @TODO:
                <span style={commonStyles.popoverTitle}>
                  {utilities.prettyPassageName({
                    translation_id: props.selectedTranslation.id,
                    book_id: props.selectedChapter.book_id,
                    chapter_number: props.selectedChapter.chapter_number,
                    start_verse_number: props.passage.start_verse_number + verseNumberOffset, // @TODO: this isn't working...also, why isn't `verseNumber` working?
                    end_verse_number: props.passage.start_verse_number + verseNumberOffset, // @TODO: this isn't working...also, why isn't `verseNumber` working?
                  })}
                </span>
                */}
                <span style={commonStyles.popoverText}>{footnote.body}</span>
              </React.Fragment>
            }
          >
            <span
              key={footnote.id}
              ref={(el) => {
                footnoteRefs.current[footnote.id] = el;
              }}
              style={styles.footnoteIndicatorWrapper}
              onClick={() => setVisibleFootnote(footnote)}
            >
              <Text style={styles.footnoteIndicator}>{`[${footnote.indicator}]`}</Text>
            </span>
          </Tooltip>
        );
      });
    }

    if (props.showFootnotes && verse.footnotes?.length) { // @TODO: shouldn't need null checking
      contentToRender.push(content.substring(prevPosition));
    } else {
      contentToRender.push(content);
    }

    return contentToRender;
  }, [verse, props.showFootnotes, props.showFormatting, styles]);

  const _getExtraVerseWrapperStyles = () => {
    let extraStyles = {};
    if (wrinkle?.level_number >= Globals.maxProgressLevel) {
      extraStyles = styles.completedVerseWrapper;
    } else if (wrinkle?.level_number >= 1) {
      extraStyles = styles.inProgressVerseWrapper;
    } else if (wrinkle?.level_number === 0) {
      extraStyles = styles.unstartedVerseWrapper;
    }

    return extraStyles;
  };

  const _getExtraVerseNumsOnlyWrapperStyles = () => {
    let extraStyles = {};
    if (props.isSelectionModeOn) {
      return extraStyles;
    }

    if (wrinkle?.level_number >= Globals.maxProgressLevel) {
      extraStyles = styles.numsOnlyCompletedVerse;
    } else if (wrinkle?.level_number >= 1) {
      extraStyles = styles.numsOnlyInProgressVerse;
    } else if (wrinkle?.level_number === 0) {
      extraStyles = styles.numsOnlyUnstartedVerse;
    }

    return extraStyles;
  };

  const _getExtraVerseNumsOnlyTextStyles = () => {
    let extraStyles = {};
    if (wrinkle?.level_number >= Globals.maxProgressLevel) {
      extraStyles = styles.numsOnlyCompletedVerseText;
    } else if (wrinkle?.level_number >= 1) {
      extraStyles = styles.numsOnlyInProgressVerseText;
    } else if (wrinkle?.level_number === 0) {
      extraStyles = styles.numsOnlyUnstartedVerseText;
    }

    return extraStyles;
  };

  const _getExtraVerseTextStyles = () => {
    if (wrinkle?.level_number >= Globals.maxProgressLevel) {
      return styles.completedVerseText;
    } else if (wrinkle?.level_number >= 1) {
      return styles.inProgressVerseText;
    } else if (wrinkle?.level_number === 0) {
      return styles.unstartedVerseText;
    }

    return {};
  };

  if (props.isLoading) {
    return (
      <div style={styles.skeletonWrapper}>
        {/* @TODO:
        {(props.showHeadings && +verseNumber === 1) && (
          <Skeleton
            style={styles.skeletonHeading}
            animation='wave'
          />
        )}
        <Skeleton
          style={styles.skeletonVerseLine1}
          animation='wave'
        />
        <Skeleton
          style={styles.skeletonVerseLine2}
          animation='wave'
        />
        <Skeleton
          style={styles.skeletonVerseLine3}
          animation='wave'
        />
        */}
      </div>
    );
  }

  if (props.verseNumbersOnly) {
    return (
      <div style={styles.verseNumbersWrapper}>
        <span
          style={{
            ...styles.numsOnlyVerseNumber,
            ..._getExtraVerseNumsOnlyWrapperStyles(),
            ...((props.isSelected && styles.numsOnlySelectedVerse) || {}),
            ...props.numsOnlyStyle,
          }}
          onClick={props.onPressVerse || (() => {
            setTimeout(() => {
              // allow this component to open the wrinkle badge but not close it
              setIsWrinkleBadgeOpen(false);
            }, 100);
            setIsWrinkleBadgeOpen(true);
          })}
        >
          <Text
            style={{
              ...styles.verseNumberText,
              ..._getExtraVerseNumsOnlyTextStyles(),
            }}
          >
            {verseNumber}
          </Text>
        </span>
        {/* @TODO:
        {(props.showWrinkleBadge && wrinkle && !props.isSelectionModeOn) &&
          <WrinkleBadge
            isOpen={_isWrinkleBadgeOpen}
            wrinkle={wrinkle}
            absoluteTop={-4}
            absoluteRight={-4}
          />
        }
        */}
      </div>
    );
  }

  return (
    <div style={styles.container}>
      {(props.showHeadings && verse.heading) &&
        <Text style={{...styles.verseWrapper, ...styles.verseHeading}}>{verse.heading}</Text>
      }{/* @TODO: heading can have footnote in it */}
      <span
        onClick={props.onPressVerse}
      >
        <div
          style={{
            ...styles.verseWrapper,
            ..._getExtraVerseWrapperStyles(),
            ...((props.isSelected && styles.selectedVerseWrapper) || {}),
            ...((!props.showFormatting && styles.verseWrapperNoFormatting) || {}), // if there's no formatting, provide a little separation at least
          }}
        >
          <Text style={{...styles.verseText, ...props.verseTextStyles}}>
            <Text
              style={{
                ...styles.verseNumber,
                ..._getExtraVerseTextStyles(),
                ...((props.isSelected && styles.selectedVerseNumber) || {}),
              }}
            >
              {verseNumber}
            </Text>{/* @TODO: update styles or whatever to make sure this displays after leading spaces...this should be the case for individual verses as well */}
            <Text
              style={{
                ...styles.typedVerse,
                color: props.defaultTextColor, // @TODO: why isn't this working?
                ..._getExtraVerseTextStyles(),
                ...((props.isSelected && styles.selectedVerseText) || {}),
                ...((props.textColorOverride && {color: props.textColorOverride}) || {}),
              }}
            >
              {/* @TODO: this should be done in the script to populate db, not on front end
              {verse.verseText.replaceAll(/\n {2}$/g, '\n\n  ') || ''}
              */}
              {/* @TODO: if verse doesn't exist in this translation, Bible Gateway surrounds the verse number in []...not sure if i need to do that if the footnote is visible, but it's an idea */}
              {verseContent.map((vc) => {
                if (typeof vc === 'string') {
                  return vc.split('\n').map((vc2, i) => (
                    <React.Fragment key={`vc2-${i}`}>
                      {vc2}
                      <br />
                    </React.Fragment>
                  ));
                }
                return vc;
              })}
            </Text>
          </Text>
          {/* @TODO:
          {props.showFootnotes && _visibleFootnote &&
            <Popover
              isVisible={!!_visibleFootnote}
              onRequestClose={() => setVisibleFootnote(null)}
              popoverStyle={commonStyles.popover}
              from={{current: footnoteRefs.current[_visibleFootnote.id]}}
            >
          */}
          {/* @TODO:
            </Popover>
          }
          */}
          {/* @TODO:
          {(props.showWrinkleBadge && wrinkle) && (
            <div style={styles.wrinkleBadgeWrapper}>
              <WrinkleBadge
                wrinkle={wrinkle}
                positionRelative={true}
                badgeSize={28}
              />
            </div>
          )}
          */}
        </div>
      </span>
    </div>
  );
};

Verse.propTypes = {
  showFormatting: PropTypes.bool.isRequired,
  showHeadings: PropTypes.bool.isRequired,
  showFootnotes: PropTypes.bool.isRequired,
  showWrinkleBadge: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  translationId: PropTypes.number.isRequired,
  verseApiId: PropTypes.string.isRequired,
  doesTranslationAffectWrinkleStatus: PropTypes.bool.isRequired,

  skipFetch: PropTypes.bool, // true if fetching is handled by an ancestor
  onPressVerse: PropTypes.func,
  isSelectionModeOn: PropTypes.bool,
  defaultTextColor: PropTypes.oneOf([Globals.colors.white, Globals.colors.primaryExtraDark]), // @TODO: do the same thing for verse numbers
  isLoading: PropTypes.bool,
  verseNumbersOnly: PropTypes.bool,
  textColorOverride: PropTypes.string,
  numsOnlyStyle: PropTypes.object,
  verseTextStyles: PropTypes.object,
};

export default Verse;
