/**
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../../Globals';

const styles = {
  container: {
    display: 'flex',
    height: 250,
    width: '100%',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 250,
  },
};

export default styles;
