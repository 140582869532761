/*
 * Ryan O'Dowd
 * 2022-08-09
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from '@mui/material';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchAdminAnalytics,
  fetchBooks,
  fetchWebStatsMsSpent,
  fetchWebStatsNumVersesReviewed,
  getRequestHeaders,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Globals from '../../Globals';
import Header from '../../components/Header';
import PassagesOfTheDayTable from './PassagesOfTheDayTable';
import WhatsNewTable from './WhatsNewTable';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import utilities from '../../utilities';

const _renderDateTick = (p) => { // @TODO: make its own component
  const date = new Date(new Date(p.payload.value).getTime() + 1000 * 60 * 60 * 5); // @TODO: add 5 hours for est
  return (
    <g transform={`translate(${p.x},${p.y})`}>
      <text style={styles.tickText} x={0} y={0} dy={10} fill={Globals.colors.lightGray}>
        <tspan textAnchor='middle' x='0'>
          {dateFnsFormat(date, 'eee')}
        </tspan>
        {/* @TODO:
        <tspan textAnchor='middle' x='0' dy='14'>
          {dateFnsFormat(date, 'M/d')}
        </tspan>
        */}
      </text>
    </g>
  );
};

const Admin = (props) => {
  const dispatch = useDispatch();

  const [_userData, setUserData] = useState([]);
  const [_lessonData, setLessonData] = useState([]);

  const adminAnalytics = useSelector((state) => state.adminAnalytics);
  const msSpent = useSelector((state) => state.webStatsMsSpent);
  const versesReviewed = useSelector((state) => state.webStatsNumVersesReviewed);

  useEffect(() => {
    const _fetchData = async () => {
      const response = await fetch(`${Globals.apiUrl}/admin/analytics/data`, {
        // @TODO: doesn't work for web: headers: await getRequestHeaders(), // @TODO: is this how we do auth for fetch api?
      });
      const payload = await response.json();
      setUserData(payload.user_data);
      setLessonData(payload.lesson_data);
    };

    _fetchData();
    dispatch(fetchBooks());
    dispatch(fetchAdminAnalytics());
    dispatch(fetchWebStatsMsSpent()); // @TODO: this shouldn't be a live stat...maybe update every month or so, but i don't want people calling this api on every page load
    dispatch(fetchWebStatsNumVersesReviewed()); // @TODO: this shouldn't be a live stat...maybe update every month or so, but i don't want people calling this api on every page load
  }, [dispatch]);

  return (
    <div style={styles.outerContainer}>
      <Header />
      <div style={styles.container}>
        <div style={styles.section}>
          <div style={styles.sectionInner}>
            <h2>Analytics</h2>

            <Paper style={styles.chartSection}>
              <Accordion
                style={styles.chartInner}
                defaultExpanded={true}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h3 style={styles.h3}>User stats</h3>
                </AccordionSummary>
                <AccordionDetails>
                  {!_userData.length
                    ? <p>Loading...</p>// @TODO: prettier
                    : (
                      <ResponsiveContainer
                        style={styles.chartWrapper}
                        width='100%'
                        height={400}
                      >
                        <LineChart // @TODO: can abstract this to be reusable
                          style={styles.chart}
                          data={_userData}
                          margin={{
                            top: 8,
                            right: 8,
                            left: 8,
                            bottom: 8,
                          }}
                        >
                          <YAxis width={32} yAxisId='numNewUsers' stroke={Globals.colors.primary} />
                          <YAxis width={24} yAxisId='numXau' stroke={Globals.colors.white} />
                          <XAxis
                            dataKey='date'
                            interval={1}
                            tick={(p) => _renderDateTick(p)}
                          />
                          <RechartsTooltip
                            contentStyle={styles.tooltip}
                            formatter={(num, payload) => {
                              return utilities.humanReadableScore(num);
                            }}
                            labelFormatter={(label, payload) => {
                              return <span style={styles.tooltipDate}>{dateFnsFormat(new Date(new Date(label).getTime() + 1000 * 60 * 60 * 5), 'eee M/d')}</span>; // @TODO: add 5 hours to make it est
                            }}
                          />
                          <CartesianGrid strokeDasharray='4' />
                          <Legend />
                          {/* @TODO: add average lines for each metric */}
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='numNewUsers' dataKey='num_new_users' stroke={Globals.colors.primary} name='New users' />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='numXau' dataKey='num_dau' stroke={Globals.colors.accent} name='DAU' />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='numXau' dataKey='num_wau' stroke={Globals.colors.complete} name='WAU' />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='numXau' dataKey='num_mau' stroke={Globals.colors.alert} name='MAU' />
                          <Brush
                            dataKey='date'
                            height={10}
                            // @TODO: y={10}
                            stroke={Globals.colors.primaryDark}
                            startIndex={_userData.length - 28}
                            travellerWidth={20}
                            tickFormatter={(t) => {
                              const date = new Date(new Date(t).getTime() + 1000 * 60 * 60 * 5); // @TODO: add 5 hours for est
                              return dateFnsFormat(date, 'M/d');
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    )
                  }
                  <div style={styles.totalsHeader}>Lifetime totals</div>
                  <div style={styles.totalsWrapper}>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Users:</span>
                      <span style={styles.statText}>{adminAnalytics.num_users}</span>{/* @TODO: move to users graph */}
                    </span>
                  </div>
                  {/* @TODO:
                  <p>TODO: week/month/year/custom range views</p>
                  */}
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper style={styles.chartSection}>
              <Accordion
                style={styles.chartInner}
                defaultExpanded={true}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <h3 style={styles.h3}>Progress stats</h3>
                </AccordionSummary>
                <AccordionDetails>
                  {!_lessonData.length
                    ? <p>Loading...</p>// @TODO: prettier
                    : (
                      <ResponsiveContainer
                        style={styles.chartWrapper}
                        width='100%'
                        height={400}
                      >
                        <LineChart
                          style={styles.chart}
                          width={900}// @TODO: should be 100%
                          height={400}
                          data={_lessonData}
                          margin={{
                            top: 8,
                            right: 8,
                            left: 8,
                            bottom: 8,
                          }}
                        >
                          {/* @TODO:
                          <YAxis width={40} yAxisId='wrinkles' stroke={Globals.colors.complete} />
                          <YAxis type='number' width={40} yAxisId='msSpent' stroke={Globals.colors.primary} />
                          <YAxis type='number' width={40} yAxisId='verses' stroke={Globals.colors.complete} />
                          <YAxis type='number' width={40} yAxisId='lessons' stroke={Globals.colors.alert} />
                          <YAxis type='number' width={40} yAxisId='peanuts' stroke={Globals.colors.accent} />
                          */}
                          <XAxis
                            dataKey='date'
                            interval={1}
                            tick={(p) => _renderDateTick(p)}
                          />
                          <RechartsTooltip
                            contentStyle={styles.tooltip}
                            formatter={(num, label) => {
                              if (label === 'Time') {
                                return utilities.humanReadableTimeDashboard(num);
                              }
                              return utilities.humanReadableScore(num);
                            }}
                            labelFormatter={(label, payload) => {
                              return <span style={styles.tooltipDate}>{dateFnsFormat(new Date(new Date(label).getTime() + 1000 * 60 * 60 * 5), 'eee M/d')}</span>; // @TODO: off by one add 5 hours to make it est
                            }}
                          />
                          <CartesianGrid strokeDasharray='4' />
                          <Legend />
                          {/* @TODO: add average lines for each metric */}
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='peanuts' name='Peanuts' dataKey='num_peanuts' stroke={Globals.colors.accent} />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='lessons' name='Lessons' dataKey='num_lessons' stroke={Globals.colors.alert} />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='verses' name='Verses Reviewed' dataKey='num_verses' stroke={Globals.colors.complete} />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='versesLeveledUp' name='Verses Leveled Up' dataKey='num_verses_leveled_up' stroke={Globals.colors.qqq} />
                          <Line type='monotone' activeDot={{r: 6}} yAxisId='msSpent' name='Time' dataKey='num_ms_spent' stroke={Globals.colors.primary} />
                          <Brush
                            dataKey='date'
                            height={10}
                            // @TODO: y={10}
                            stroke={Globals.colors.primaryDark}
                            startIndex={_lessonData.length - 28}
                            travellerWidth={20}
                            tickFormatter={(t) => {
                              const date = new Date(new Date(t).getTime() + 1000 * 60 * 60 * 5); // @TODO: add 5 hours for est
                              return dateFnsFormat(date, 'M/d');
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    )
                  }
                  <div style={styles.totalsHeader}>Lifetime totals</div>
                  <div style={styles.totalsWrapper}>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Peanuts earned:</span>
                      <span style={styles.statText}>{utilities.humanReadableNumber(adminAnalytics.num_peanuts)}</span>
                    </span>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Lessons completed:</span>
                      <span style={styles.statText}>{utilities.humanReadableNumber(adminAnalytics.num_lessons)}</span>
                      {/* @TODO: drill down for difficulty */}
                    </span>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Questions asked:</span>
                      <span style={styles.statText}>{utilities.humanReadableNumber(adminAnalytics.num_questions_asked)}</span>
                      {/* @TODO: option to drill down for more specifics...like type of quesitons for example */}
                    </span>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Questions skipped:</span>
                      <span style={styles.statText}>{utilities.humanReadableNumber(adminAnalytics.num_skipped_questions)}</span>
                    </span>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Verses reviewed:</span>
                      <span style={styles.statText}>{utilities.humanReadableNumber(versesReviewed)}</span>
                    </span>
                    <span style={styles.statWrapper}>
                      <span style={styles.statLabel}>Time spent:</span>
                      <span style={styles.statText}>{utilities.humanReadableTime(msSpent)}</span>
                    </span>
                  </div>
                  {/* @TODO:
                  <p>TODO: week/month/year/custom range views</p>
                  */}
                </AccordionDetails>
              </Accordion>
            </Paper>
          </div>
        </div>

        <div style={styles.section}>
          <div style={styles.sectionInner}>
            <h2>Administration</h2>

            <Paper style={styles.chartSection}>
              <Accordion
                style={styles.chartInner}
                defaultExpanded={false}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <h3 style={styles.h3}>Manage Passages of the Day</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <PassagesOfTheDayTable />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper style={styles.chartSection}>
              <Accordion
                style={styles.chartInner}
                defaultExpanded={false}
              >{/* @TODO: this should probably be its own component */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <h3 style={styles.h3}>{`Manage "What's New?" notes`}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <WhatsNewTable />
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* @TODO:
            <Paper style={styles.chartSection}>
              <Accordion
                style={styles.chartInner}
                defaultExpanded={false}
              >{/* @TODO: this should probably be its own component /}
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <h3 style={styles.h3}>Review reported tests</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p>TODO</p>
                </AccordionDetails>
              </Accordion>
            </Paper>
            */}

          </div>
        </div>

      </div>
    </div>
  );
};

export default Admin;
