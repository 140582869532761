/**
 * Ryan O'Dowd
 * 2024-03-08
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CPassage from '../../classes/Passage';
import Globals from '../../Globals';
// @TODO: import OakHeader from '../../components/Header';
import PassageDisplay from '../../components/PassageDisplay';
import PropTypes from 'prop-types';
// @TODO: import ScreenWrapper from '../../common/containers/ScreenWrapper';
// @TODO: import Searchbar from '../../common/components/OakSearchbar';
import styles from './styles';
import {
  updateUserSettings,
} from '../../actions';
import utilities from '../../utilities';

const PassageSelection = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.oakUser);
  const settingsPreferredVerseSelectionMethod = useSelector((state) => state.settingsPreferredVerseSelectionMethod);
  const translations = useSelector((state) => state.translations.filter((t) => {
    return Globals.adminAuthIds.includes(user.auth_id) || t.disabled === 0;
  }), (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)); // @TODO: is JSON.stringify sufficient and/or performant enough?
  const preferredTranslation = utilities.usePreferredTranslation();

  const books = useSelector((state) => state.books);
  const [_selectedBook, setSelectedBook] = useState(books.find((book) => book.id === props.passage?.book_id) || {});
  const [_selectedChapterNumber, setSelectedChapterNumber] = useState(props.passage?.chapter_number || '');
  const [_selectedVerseNums, setSelectedVerseNums] = useState((props.passage?.start_verse_number && props.passage?.end_verse_number) ? [...Array(props.passage.end_verse_number - props.passage.start_verse_number + 1)].map((_, index) => index + props.passage.start_verse_number) : []);

  const selectedChapter = useSelector((state) => state.chapters.find((chapter) => chapter.book_id === _selectedBook.id && chapter.chapter_number === _selectedChapterNumber) || state.chapters[0]); // @TODO: better default value

  const passages = useMemo(() => {
    return [new CPassage(-1, preferredTranslation.id, _selectedBook.id, _selectedChapterNumber, 1, selectedChapter.number_of_verses || 1)];
  }, [preferredTranslation.id, _selectedBook.id, _selectedChapterNumber, selectedChapter.number_of_verses]);

  return (
    <div>
      <span style={styles.passageTitle}>{props.passage && utilities.prettyPassageName(props.passage)}</span>{/* @TODO: better ui */}{/* @TODO: this shouldn't change until the save button is clicked */}
      {!props.isPassageLocked && !!books.length &&
        <React.Fragment>
          <div style={styles.bookAndChapterWrapper}>

            <span style={styles.nameWrapper}>
              <FormControl>
                <InputLabel id='type'>Preferred translation</InputLabel>
                <Select
                  labelId='type'
                  value={user.preferred_translation_id}
                  label='Preferred translation'
                  onChange={(e) => {
                    dispatch(updateUserSettings({preferred_translation_id: e.target.value}));
                    props.onSelect(new CPassage(
                      -1,
                      e.target.value,
                      _selectedBook.id,
                      _selectedChapterNumber,
                      _selectedVerseNums.length ? Math.min(..._selectedVerseNums) : 0,
                      _selectedVerseNums.length ? Math.max(..._selectedVerseNums) : 0,
                    ));
                  }}
                >
                  {translations.map((t) => (
                    <MenuItem
                      key={t.id}
                      value={t.id}
                    >
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </span>

            {/* @TODO:
            {books.filter((b) => _selectedBook.id ? b.id === _selectedBook.id : true).map((book) => _renderBook(book))}
            */}
            <Autocomplete
              disablePortal
              id='book-selection'
              options={books}
              getOptionLabel={(option) => option.name || ''}
              value={_selectedBook}
              onChange={(event, value) => setSelectedBook(value || {})}
              sx={{width: 300}}// @TODO: move to styles
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label='Book'
                  />
                );
              }}
            />
            {!!_selectedBook.id &&
              <Autocomplete
                disablePortal
                id='chapter-selection'
                options={[...Array(Object.keys(_selectedBook.chapters_and_verses).length)].map((_, index) => index + 1)}
                getOptionLabel={(option) => `${option}`}
                value={_selectedChapterNumber}
                onChange={(event, value) => setSelectedChapterNumber(+value)}
                sx={{width: 120}}// @TODO: move to styles
                disableClearable={true}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label='Chapter'
                    />
                  );
                }}
              />
            }
          </div>
          {!!_selectedBook.id && !!_selectedChapterNumber &&
            <div style={styles.versesWrapper}>
              {/* @TODO: option to select all verses in chapter */}
              <PassageDisplay
                onUpdateVerseSelection={(verseNums) => {
                  setSelectedVerseNums(verseNums);
                  props.onSelect(new CPassage(
                    -1,
                    preferredTranslation.id,
                    _selectedBook.id,
                    _selectedChapterNumber,
                    verseNums.length ? Math.min(...verseNums) : 0,
                    verseNums.length ? Math.max(...verseNums) : 0,
                  ));
                }}
                passages={passages}
                allVersesSelected={_selectedVerseNums.length === selectedChapter.number_of_verses}
                selectedVerseNums={_selectedVerseNums}
                hideAddToGoal={true}
                hideTest={true}
                hideLesson={true}
                hideLicensing={true}
                showFormatting={false}
                showFootnotes={false}
                showHeadings={true}
                doesTranslationAffectWrinkleStatus={false}
                verseNumbersOnly={settingsPreferredVerseSelectionMethod === 'numbers'}
                alwaysShowSelectionMenu={true}
                showWrinkleBadges={false}
              />
            </div>
          }
        </React.Fragment>
      }
    </div>
  );
};

PassageSelection.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isPassageLocked: PropTypes.bool.isRequired, // @TODO: shape

  passage: PropTypes.object, // @TODO: shape
};

export default PassageSelection;
