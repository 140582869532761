/*
 * Ryan O'Dowd
 * 2023-02-01
 * © Copyright 2023 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import HelpIcon from '@mui/icons-material/Help';
import OakTable from '../../../common/components/OakTable';
import PropTypes from 'prop-types';
import {
  fetchPledgeForToken,
} from '../../../actions';
import styles from './styles';
import utilities from '../../../utilities';

const AdditionalDonationDialog = (props) => {
  const dispatch = useDispatch();

  const [_additionalDollars, setAdditionalDollars] = useState(null);
  const [_defaultCurrencyInputValue, setDefaultCurrencyInputValue] = useState(null);
  const [_submitted, setSubmitted] = useState(false);

  const pledge = useSelector((state) => {
    for (const ps of Object.values(state.pledges)) {
      for (const p of ps) {
        if (p.token === props.token) {
          if (_defaultCurrencyInputValue === null) {
            setDefaultCurrencyInputValue(p.additional_donation_cents / 100);
          }
          return p;
        }
      }
    }

    return {};
  });

  const campaign = useSelector((state) => {
    return state.organizationCampaigns.find((oc) => oc.id === pledge.campaign_id) || {};
  });

  const organization = useSelector((state) => {
    for (const o of Object.values(state.organizations)) {
      if (o.id === campaign.organization_id) {
        return o;
      }
    }

    return {};
  });

  useEffect(() => {
    dispatch(fetchPledgeForToken(props.token));
  }, [dispatch, props.token]);

  const totalCentsSoFar = Math.min(pledge.max_donation_cents, pledge.num_wrinkles_earned_for_campaign * pledge.cents_per_wrinkle);

  return (
    <Dialog
      style={styles.container}
      maxWidth={'lg'}
      open={true}
      onClose={() => props.onClose()}
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.fullTitleWrapper}>
          {/* @TODO: do back-end fetch to get pledge and sponsor information to display in this dialog for user to see/edit */}
          <span style={styles.mainTitle}>{organization.name}</span>
          <span style={styles.subTitle}>{campaign.name}</span>
        </span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <OakTable
          tableTitle='My pledges'
          open={true}
          // @TODO: prop to remove accordion
          hidePagination={true}
          rowData={[
            [
              {
                id: 'name_head',
                content: 'Name',
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'name_value',
                content: <Tooltip title={pledge.sponsor?.email}><span>{pledge.sponsor?.display_name}</span></Tooltip>,
              },
            ],
            [
              {
                id: 'pledge_amount_head',
                content: <span key={0} style={styles.headerMultiline}>Pledge amount<br /><span key={1} style={{...styles.wrinkleText, ...styles.blackText}}>(per verse)</span></span>,
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'pledge_amount_value',
                content: utilities.prettyCurrency(pledge.cents_per_wrinkle),
              },
            ],
            [
              {
                id: 'max_donaation_head',
                content: <span key={2} style={styles.headerMultiline}>Max donation<br /><span key={3} style={styles.wrinkleText}>(verses)</span></span>,
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'max_donaation_value',
                content: <span style={styles.caps}><span style={styles.moneyCap}>{utilities.prettyCurrency(pledge.max_donation_cents)}</span><span style={styles.wrinkleText}>({Math.floor(pledge.max_donation_cents / pledge.cents_per_wrinkle)})</span></span>,
              },
            ],
            [
              {
                id: 'total_head',
                content: <span key={4} style={styles.headerMultiline}>Total so far<br /><span key={5} style={styles.wrinkleText}>(verses)</span></span>,
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'total_value',
                content: <span style={styles.caps}><span style={styles.moneyCap}>{utilities.prettyCurrency(totalCentsSoFar)}</span><span style={styles.wrinkleText}>({pledge.num_wrinkles_earned_for_campaign})</span></span>,
              },
            ],
            /* @TODO: premature optimization:
            [
              {
                id: 'status_head',
                content: 'Status',
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'status_value',
                content: pledge.status,
              }, // @TODO: make this pretty (colors)
            ],
            */
            [
              {
                id: 'additional_head',
                content: <span style={styles.rowWrapper}>Additional donation&nbsp;<Tooltip title={`An extra donation for ${organization.name} added to your per-verse pledges`}><HelpIcon style={styles.helpIcon} /></Tooltip></span>,
                styles: styles.bold,
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'additional_value',
                content: (pledge.status === 'pledged'
                  ? (
                    <CurrencyInput
                      style={styles.customInput}
                      defaultValue={_defaultCurrencyInputValue}
                      disableGroupSeparators={true}
                      allowNegativeValue={false}
                      // @TODO: autoFocus={true}// @TODO: doesn't work
                      decimalsLimit={2}
                      decimalScale={2}
                      onValueChange={(value, name) => {
                        setAdditionalDollars(value);
                      }}
                      prefix='$'
                      disableAbbreviations={true}
                    />
                  )
                  : (
                    <span style={styles.caps}>{utilities.prettyCurrency(pledge.additional_donation_cents)}</span>
                  )
                ),
              },
            ],
            [
              {
                id: 'grand_total_head',
                content: 'Total dontation',
                styles: {...styles.bold, ...styles.totalCell},
                tableCellProps: {variant: 'head'},
              },
              {
                id: 'grand_total_value',
                styles: {...styles.bold, ...styles.totalCell},
                content: <span style={styles.caps}>{utilities.prettyCurrency(totalCentsSoFar + (((_additionalDollars || 0) * 100) || pledge.additional_donation_cents))}</span>,
              },
            ],
          ]}
        />
        {_submitted && <p>Thank you for your additional support!</p>}
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={() => props.onClose()}
        >
          Close
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={_additionalDollars === null || _additionalDollars * 100 === pledge.additional_donation_cents}
          onClick={() => {
            props.postAdditionalDonation(_additionalDollars * 100);
            setSubmitted(true);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdditionalDonationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  postAdditionalDonation: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default AdditionalDonationDialog;
