/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Link,
  useParams,
} from 'react-router-dom';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchOrganization,
  postAdditionalDonation,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import AdditionalDonationDialog from './AdditionalDonationDialog';
import {
  Button,
} from '@mui/material';
import {
  Elements,
} from '@stripe/react-stripe-js';
import Globals from '../../Globals';
import JoinDialog from './JoinDialog';
import PaymentStatus from './PaymentStatus';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import {
  format as dateFnsFormat,
} from 'date-fns';
import {
  loadStripe,
} from '@stripe/stripe-js';
import queryString from 'query-string';
import styles from './styles';
import utilities from '../../utilities';

const CAMPAIGN_DATE_FORMAT = 'MMM d, yyyy';

const stripePromise = loadStripe(Globals.stripeKey);

const Organization = (props) => {
  const dispatch = useDispatch();
  const i18n = utilities.useI18n();

  const {
    organizationSlug,
  } = useParams();

  const queryParams = queryString.parse(window.location.search, {ignoreQueryPrefix: true});

  const [_isAdditionalDonationDialogOpen, setIsAdditionalDonationDialogOpen] = useState(queryParams.token);
  const [_openCampaign, setOpenCampaign] = useState(null); // @TODO: initialize to something if redirected from an email link or something, for example
  const [_stripeClientSecret, setStripeClientSecret] = useState(null);
  const [_show404, setShow404] = useState(false);

  const organization = useSelector((state) => state.organizations[organizationSlug] || {});

  useEffect(() => {
    setTimeout(() => setShow404(true), 4000);
  }, []);

  useEffect(() => {
    if (organization.id) {
      const _getSecret = async () => {
        const response = await fetch(`${Globals.apiUrl}/secret/${organization.id}`);
        const {client_secret: stripeClientSecret} = await response.json();
        setStripeClientSecret(stripeClientSecret);
      };

      _getSecret();
    }
  }, [organization]);

  useEffect(() => {
    dispatch(fetchOrganization(organizationSlug));
  }, [dispatch, organizationSlug]);

  if (!organization.name && _show404) {
    return (
      <WebPageStub>
        <div style={styles.errorTextWrapper}>
          <p style={{...styles.errorText, ...styles.bold}}>This organization could not be found.</p>
          <p style={styles.errorText}>Please check back later or visit the <Link to='/fundraising'>fundraising page</Link> to search partners.</p>
        </div>
      </WebPageStub>
    );
  }

  const currentCampaigns = (organization.campaigns?.filter((c) => new Date(c.end_datetime).getTime() > new Date().getTime()) || []).filter((campaign) => campaign.is_public); // @TODO: filter on backend?
  const pastCampaigns = (organization.campaigns?.filter((c) => new Date(c.end_datetime).getTime() <= new Date().getTime()) || []).filter((campaign) => campaign.is_public); // @TODO: filter on backend?

  return (
    <WebPageStub>
      {_stripeClientSecret &&
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: _stripeClientSecret,
          }}
        >
          <PaymentStatus />
        </Elements>
      }

      <WebsiteSection
        title={
          <span style={styles.titleWrapper}>
            <h1 style={styles.h1}>{organization.name || 'Loading...'}</h1>
            {(!Globals.isMobile && organization.logo_url) &&
              <a
                style={styles.logoLink}
                href={organization?.website_url}
              >
                <img// @TODO: replace with ../../components/PartnerLogo?
                  style={styles.logo}
                  src={organization.logo_url}
                  alt={`${organization.name} logo`}
                />
              </a>
            }
          </span>
        }// @TODO: update...use suspense for this?
      >
        <p>{organization.description}</p>{/* @TODO: update */}
      </WebsiteSection>

      {organization.name &&
        <WebsiteSection
          isDarkBackground={true}
          title={(
            <span style={styles.currentCampaignsTitleRow}>
              <span>Current campaigns</span>
              <Button
                style={styles.campaignAction}
                variant='outlined'
                color='secondary'
                component={Link}
                to='/help?q=fundraising'
              >
                Fundraising FAQs
              </Button>
            </span>
          )}
        >
          {!currentCampaigns.length
            ? (
              <p><span style={styles.organizationName}>{organization.name} </span>has no active campaigns at the moment.  Please check back later.</p>
            ) : (
              currentCampaigns.map((campaign, index) => { // @TODO: map over all campaigns from backend
                return (
                  <div
                    key={campaign.id}
                    style={{...styles.campaignWrapper, ...(index === 0 ? {} : styles.campaignSeparator)}}
                  >
                    <p style={styles.campaignName}>{campaign.name}</p>
                    <p style={styles.campaignDate}>Runs from {dateFnsFormat(new Date(campaign.start_datetime), CAMPAIGN_DATE_FORMAT, {locale: i18n('locale')})} to {dateFnsFormat(new Date(campaign.end_datetime), CAMPAIGN_DATE_FORMAT, {locale: i18n('locale')})}</p>{/* @TODO: update */}{/* @TODO: dates should be utc? */}{/* @TODO: dyanmic (e.g. "ran from ..." or "running from") */}
                    <p>
                      {campaign.description}
                      {campaign.url &&
                        <>
                          &nbsp;
                          <a
                            style={styles.learnMoreLink}
                            href={campaign.url}
                          >
                            Learn more
                          </a>
                        </>
                      }
                    </p>
                    {/* @TODO: countdown here (either until start or end) */}
                    <Button
                      style={styles.campaignAction}
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        setOpenCampaign(campaign);
                      }}
                    >
                      Join campaign
                    </Button>
                  </div>
                );
              })
            )
          }
        </WebsiteSection>
      }

      {/* @TODO: this could be abstracted with current campaigns above */}
      {organization.name && pastCampaigns.length &&
        <WebsiteSection
          isDarkBackground={false}
          title={(
            <span style={styles.currentCampaignsTitleRow}>
              <span>Past campaigns</span>
            </span>
          )}
        >
          {pastCampaigns.map((campaign, index) => { // @TODO: map over all campaigns from backend
            return (
              <div
                key={campaign.id}
                style={{...styles.campaignWrapper, ...(index === 0 ? {} : {...styles.campaignSeparator, ...styles.pastCampaignSeparator})}}
              >
                <p style={styles.campaignName}>{campaign.name}</p>
                <p style={{...styles.campaignDate, ...styles.pastCampaignDate}}>Ran from {dateFnsFormat(new Date(campaign.start_datetime), CAMPAIGN_DATE_FORMAT, {locale: i18n('locale')})} to {dateFnsFormat(new Date(campaign.end_datetime), CAMPAIGN_DATE_FORMAT, {locale: i18n('locale')})}</p>{/* @TODO: update */}{/* @TODO: dates should be utc? */}{/* @TODO: dyanmic (e.g. "ran from ..." or "running from") */}
                <p>
                  {campaign.description}
                  {campaign.url &&
                    <>
                      &nbsp;
                      <a
                        style={{...styles.learnMoreLink, ...styles.pastLearnMoreLink}}
                        href={campaign.url}
                      >
                        Learn more
                      </a>
                    </>
                  }
                </p>
                {/* @TODO: maybe a button to open campaign results if they can be public?
                <Button
                  style={styles.campaignAction}
                  variant='contained'
                  color='secondary'
                  disabled={true}
                  onClick={() => {
                    setOpenCampaign(campaign);
                  }}
                >
                  Campaign ended
                </Button>
                */}
              </div>
            );
          })}
        </WebsiteSection>
      }

      {(_openCampaign && _stripeClientSecret) && // @TODO: show loading indicator if _openCampaign but !_stripeClientSecret
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: _stripeClientSecret,
            appearance: {
              theme: 'stripe',
              variables: {
                colorPrimary: Globals.colors.primary,
                colorBackground: Globals.colors.white,
                colorText: Globals.colors.primaryExtraDark,
                colorDanger: Globals.colors.alert,
                fontFamily: Globals.fontFamilies.primary,
                spacingUnit: '4px',
                borderRadius: '8px',
              },
            },
          }}
        >
          <JoinDialog
            organization={organization}
            campaign={_openCampaign}
            stripeClientSecret={_stripeClientSecret}
            onClose={() => setOpenCampaign(null)}
          />
        </Elements>
      }

      {_isAdditionalDonationDialogOpen &&
        <AdditionalDonationDialog
          postAdditionalDonation={(additionalDonationCents) => {
            dispatch(postAdditionalDonation(queryParams.token, additionalDonationCents));
          }}
          token={queryParams.token}
          onClose={() => setIsAdditionalDonationDialogOpen(false)}
        />
      }

      {/* @TODO: if no campaigns for this org, display something like "coming soon?" */}

      {/* @TODO: note if this organization has private campaigns "log in to view more (if you're a participant)" */}

      {/* @TODO:
      <WebsiteSection
        title='Results'// @TODO: dyanmic ("progress", "results", and something if it hasn't started yet)
      >
        <p>{"TODO: statistics (nothing too specific though...no money amounts, just maybe wrinkles? or peanuts? or hours and verses reviewed like on the landing page? that's probably the best option...but definitely wrinkles so that sponsors can track it) (if none available yet, hide this section? show something else?)"}</p>
        <p>TODO: testimonials</p>
      </WebsiteSection>
      */}
    </WebPageStub>
  );
};

export default Organization;
