/**
 * Ryan O'Dowd
 * 2024-03-08
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  passageTitle: {
    display: 'flex',
    fontSize: 18,
    marginBottom: 16,
    fontWeight: 'bold',
  },
  bookAndChapterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 16,
  },
  versesWrapper: {
    display: 'flex',
    width: '100%',
  },
  selectedChapter: {
    // @TODO: backgroundColor: Globals.colors.primaryExtraDark,
    backgroundColor: 'transparent',
  },
};

export default styles;
