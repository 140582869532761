/**
 * Ryan O'Dowd
 * 2022-03-13
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  DELETE,
  FETCH_REQUEST_OBJECT,
  GET,
  PATCH,
  POST,
  PUT,
  SET_OAK_USER,
  SET_WHATS_NEW_NOTES,
  fetchChatMessages,
  fetchWhatsNew,
  getFetchTypes,
  getRequestHeaders,
  logout,
  markAllMessagesRead,
  saveFcmToken,
  saveInfoToRedux,
  sendChatMessage,
  sendMessage,
  setFeedbackChatDraftText,
  setLightDarkMode,
  setWhatsNewLastReadTimestamp,
  setWhatsNewLastReadVersion,
  updateLoadingState,
} from './common/actions';
import {
  addMinutes,
  format as dateFnsFormat,
} from 'date-fns';
import Globals from './Globals';
import {
  RSAA,
} from 'redux-api-middleware';

// action types
export const SET_ACTIVE_GOAL_ID = 'SET_ACTIVE_GOAL_ID';
export const SET_BOOKS = 'SET_BOOKS';
export const SET_MINIGAMES = 'SET_MINIGAMES';
export const SET_BOOK_NAMES = 'SET_BOOK_NAMES';
export const SET_CHAPTERS = 'SET_CHAPTERS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_GOAL = 'SET_GOAL';
export const SET_GOALS = 'SET_GOALS';
export const SET_GOAL_PREVIEW = 'SET_GOAL_PREVIEW';
export const TOGGLE_MINIGAME_ACTIVE = 'TOGGLE_MINIGAME_ACTIVE';
export const SET_VERSES_LAST_UPDATED = 'SET_VERSES_LAST_UPDATED';
export const SET_BOOK_SORT_METHOD = 'SET_BOOK_SORT_METHOD';
export const SET_PREFERRED_TRANSLATION_ID = 'SET_PREFERRED_TRANSLATION_ID';
export const SET_PREFERRED_VERSE_SELECTION_METHOD = 'SET_PREFERRED_VERSE_SELECTION_METHOD';
export const SET_PREFERRED_SCALE = 'SET_PREFERRED_SCALE';
export const SET_ALL_ASSIGNMENT_PEANUTS = 'SET_ALL_ASSIGNMENT_PEANUTS';
export const SET_SEVEN_DAYS_PEANUTS = 'SET_SEVEN_DAYS_PEANUTS';
export const SET_ASSIGNMENT_PEANUTS = 'SET_ASSIGNMENT_PEANUTS';
export const SET_VERSES_REVIEWED = 'SET_VERSES_REVIEWED';
export const SET_MS_SPENT = 'SET_MS_SPENT';
export const SET_BADGES = 'SET_BADGES';
export const SET_NEW_BADGES = 'SET_NEW_BADGES';
export const CLEAR_ALL_NEW_BADGES = 'CLEAR_ALL_NEW_BADGES';
export const SET_STREAKS = 'SET_STREAKS';
export const UPDATE_GOAL_SETTINGS = 'UPDATE_GOAL_SETTINGS';
export const SET_NUM_LESSONS_TODAY = 'SET_NUM_LESSONS_TODAY';
export const ADD_COMPLETED_LESSON = 'ADD_COMPLETED_LESSON';
export const REMOVE_COMPLETED_LESSON = 'REMOVE_COMPLETED_LESSON';
export const SET_NOTIFICATION_PREFERENCE = 'SET_NOTIFICATION_PREFERENCE';
export const SET_DAILY_REMINDER_TIME = 'SET_DAILY_REMINDER_TIME';
export const SET_PASSAGE_OF_THE_DAY_NOTIFICATION_TIME = 'SET_PASSAGE_OF_THE_DAY_NOTIFICATION_TIME';
export const SET_SCHEDULED_STREAK_REMINDER_IDS = 'SET_SCHEDULED_STREAK_REMINDER_IDS';
export const SET_SCHEDULED_DAILY_GOALS_REMINDER_IDS = 'SET_SCHEDULED_DAILY_GOALS_REMINDER_IDS';
export const SET_SCHEDULED_PASSAGE_OF_THE_DAY_NOTIFICATION_IDS = 'SET_SCHEDULED_PASSAGE_OF_THE_DAY_NOTIFICATION_IDS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_PLEDGES = 'SET_PLEDGES';
export const SET_PLEDGE = 'SET_PLEDGE';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_PARTICIPANT_CAMPAIGNS = 'SET_PARTICIPANT_CAMPAIGNS';
export const SET_ORGANIZATION_CAMPAIGNS = 'SET_ORGANIZATION_CAMPAIGNS';
export const SET_WRINKLES = 'SET_WRINKLES';
export const SET_TEST_DRAFT = 'SET_TEST_DRAFT';
export const CLEAR_TEST_DRAFT = 'CLEAR_TEST_DRAFT';
export const SET_PUBLIC_GOALS = 'SET_PUBLIC_GOALS';
export const SET_VERSES = 'SET_VERSES';
export const UPDATE_VERSES = 'UPDATE_VERSES';
export const CLEAR_VERSES_CACHE = 'CLEAR_VERSES_CACHE';
export const SET_LOCALES = 'SET_LOCALES';
export const SET_I18N = 'SET_I18N';
export const SET_I18N_NOTES = 'SET_I18N_NOTES';
export const UPDATE_I18N_NOTES = 'UPDATE_I18N_NOTES';
export const SET_NEW_TRANSLATIONS_RETURN_VALUE = 'SET_NEW_TRANSLATIONS_RETURN_VALUE';
export const SET_I18N_UPDATED = 'SET_I18N_UPDATED';
export const SET_READING_BOOKMARK_CHAPTER_ID = 'SET_READING_BOOKMARK_CHAPTER_ID';
export const SET_SUB_GOAL_SELECTION = 'SET_SUB_GOAL_SELECTION';
export const SET_SUB_GOAL_REVIEW_PERCENTAGE = 'SET_SUB_GOAL_REVIEW_PERCENTAGE';
export const SET_YEAR_IN_REVIEW_STATS = 'SET_YEAR_IN_REVIEW_STATS';
export const SET_LAST_FETCH_VERSES_TIMESTAMP = 'SET_LAST_FETCH_VERSES_TIMESTAMP';
export const SET_PASSAGE_OF_THE_DAY = 'SET_PASSAGE_OF_THE_DAY';
export const SET_ALL_PASSAGES_OF_THE_DAY = 'SET_ALL_PASSAGES_OF_THE_DAY';
export const ADD_TO_READING_HISTORY = 'ADD_TO_READING_HISTORY';
export const REMOVE_FROM_READING_HISTORY = 'REMOVE_FROM_READING_HISTORY';
export const CLEAR_READING_HISTORY = 'CLEAR_READING_HISTORY';
export const SET_PASSAGE = 'SET_PASSAGE';
export const SET_VERSION_SUPPORT = 'SET_VERSION_SUPPORT';
export const SET_TAGS = 'SET_TAGS';
export const SET_ADMIN_ANALYTICS = 'SET_ADMIN_ANALYTICS';
export const ADD_TO_SUPER_SEARCH_HISTORY = 'ADD_TO_SUPER_SEARCH_HISTORY';
export const REMOVE_FROM_SUPER_SEARCH_HISTORY = 'REMOVE_FROM_SUPER_SEARCH_HISTORY';
export const CLEAR_SUPER_SEARCH_HISTORY = 'CLEAR_SUPER_SEARCH_HISTORY';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

// schools
export const SET_CLASSROOMS = 'SET_CLASSROOMS';
export const SET_ALL_CLASSROOM_GOAL_METADATA = 'SET_ALL_CLASSROOM_GOAL_METADATA';
export const SET_CLASSROOM_GOAL_METADATA = 'SET_CLASSROOM_GOAL_METADATA';
export const SET_CLASSROOM_STUDENTS = 'SET_CLASSROOM_STUDENTS';

// navigation routes
export const ABOUT_ROUTE = 'About';
export const APP_ROUTE = 'App';
export const ASSIGNMENT_ROUTE = 'Assignment';
export const GOAL_ROUTE = 'Goal';
export const PRACTICE_ROUTE = 'Practice';
export const FEEDBACK_CHAT_ROUTE = 'FeedbackChat';
export const HELP_ROUTE = 'Help';
export const GOALS_ROUTE = 'Goals';
export const NEW_GOAL_ROUTE = 'NewGoal';
export const PASSAGE_SELECTION_ROUTE = 'PassageSelection';
export const SETTINGS_ROUTE = 'Settings';
export const LINK_ACCOUNT_ROUTE = 'LinkAccount';
export const PROFILE_ROUTE = 'Profile';
export const FRIENDS_ROUTE = 'Friends';
export const FUNDRAISING_ROUTE = 'Fundraising';
export const WHATS_NEW_ROUTE = 'WhatsNew';
export const DISCOVER_ROUTE = 'Discover';
export const GROUPS_ROUTE = 'Groups';
export const READ_ROUTE = 'Read';
export const QUIZZING_ROUTE = 'Quizzing';
export const YEAR_IN_REVIEW_ROUTE = 'YearInReview';

// friends
export const SET_FRIENDS = 'SET_FRIENDS';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PUBLIC_USERS_SEARCHED = 'PUBLIC_USERS_SEARCHED';
export const BLOCK_USER = 'BLOCK_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const SET_LAST_VIEWED_FOLLOWERS_TIMESTAMP = 'SET_LAST_VIEWED_FOLLOWERS_TIMESTAMP';

function _jsDateToMysqlDate(jsDate) {
  if (!jsDate) {
    return null;
  }

  return dateFnsFormat(addMinutes(jsDate, jsDate.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
}

// action enums
export const BOOK_SORT_METHODS = [
  'bibleOrder',
  'alphabetically',
];

export function clearAllNewBadges() {
  return {
    type: CLEAR_ALL_NEW_BADGES,
  };
}

// @MARK: api calls
export function apiRequestRemoteLogging(subject, body) { // @TODO: common/actions?
  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('body', body);
  const endpoint = `${Globals.apiUrl}/remote_logging`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, '', ''),
      },
    });
  };
}

export function apiSaveLesson(userId, uuid, goalId, passageId, assignmentId, difficulty, millisecondsSpent, peanutsEarned, numberOfQuestions, numberOfVerses, minigameIds, repairStreakDate, adHocPassage, testPassedVerseNums, dateCompleted, passageOfTheDayId, streakDateTz, error, numberOfSkippedQuestions) {
  const formData = new FormData();
  formData.append('uuid', uuid);
  if (goalId) {
    formData.append('goal_id', goalId);
  }
  if (passageId) {
    formData.append('passage_id', passageId);
  }
  if (adHocPassage) {
    formData.append('ad_hoc_passage', JSON.stringify(adHocPassage));
  }
  if (testPassedVerseNums) {
    formData.append('test_passed_verse_nums', JSON.stringify(testPassedVerseNums));
  }
  formData.append('difficulty', difficulty);
  if (assignmentId) {
    formData.append('assignment_id', assignmentId);
  }
  formData.append('milliseconds_spent', millisecondsSpent);
  formData.append('peanuts_earned', peanutsEarned);
  formData.append('number_of_questions', numberOfQuestions);
  formData.append('number_of_verses_reviewed', numberOfVerses);
  formData.append('minigame_ids', JSON.stringify(minigameIds));
  formData.append('tz_date', streakDateTz);
  formData.append('date_completed', dateCompleted ?? Date.now());
  if (passageOfTheDayId) {
    formData.append('passage_of_the_day_id', passageOfTheDayId);
  }
  if (repairStreakDate) {
    formData.append('repair_streak_date', repairStreakDate);
  }
  if (error) {
    formData.append('error', error);
  }
  formData.append('number_of_skipped_questions', numberOfSkippedQuestions);
  const endpoint = `${Globals.apiUrl}/lessons`;
  return async (dispatch, getState) => {
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ASSIGNMENT_PEANUTS),
      },
    });
    if (response.error) {
      // @TODO: this function signature can change between versions, so i think it'd make sense to do something like on error -> if lesson is older than 7 days -> send to me for manual insertion -> delete this lesson (don't save it for retry later)
      dispatch(addCompletedLesson({ // @TODO: can these args be dynamic by doing `...args`?
        uuid,
        goalId,
        passageId,
        difficulty,
        millisecondsSpent,
        peanutsEarned,
        numberOfQuestions,
        numberOfVerses,
        minigameIds,
        repairStreakDate,
        adHocPassage,
        testPassedVerseNums,
        dateCompleted,
        passageOfTheDayId,
        streakDateTz,
        error: JSON.stringify(response),
        numberOfSkippedQuestions,
      }));
    } else {
      // @TODO: can most of these calls be replaced by adding more to the response of the save POST?
      dispatch(removeCompletedLesson(uuid));
      dispatch(fetchStreaks());
      dispatch(fetchSevenDaysPeanuts(userId));
      dispatch(fetchTodaysLessons());
      dispatch(fetchUser());
      dispatch(fetchGoals());
      dispatch(fetchWrinkles()); // @TODO: shouldn't have to do this here...rely on response from save call
      dispatch(fetchPassageOfTheDay());
    }
  };
}

export function fetchTodaysLessons() {
  const midnight = new Date();
  midnight.setHours(0, 0, 0, 0);
  const endpoint = `${Globals.apiUrl}/num_lessons_today?users_midnight=${midnight.valueOf()}`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_NUM_LESSONS_TODAY),
      },
    });
  };
}

export function fetchWebStatsNumVersesReviewed() {
  const endpoint = `${Globals.apiUrl}/web_stats/num_verses_reviewed`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_VERSES_REVIEWED),
      },
    });
  };
}

export function fetchStreaks() {
  const endpoint = `${Globals.apiUrl}/streaks`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_STREAKS),
      },
    });
  };
}

export function fetchUserBadges() {
  const endpoint = `${Globals.apiUrl}/badges`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_BADGES),
      },
    });
  };
}

export function checkForNewBadges() {
  const endpoint = `${Globals.apiUrl}/check_for_new_badges`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_NEW_BADGES),
      },
    });
  };
}

export function fetchWebStatsMsSpent() {
  const endpoint = `${Globals.apiUrl}/web_stats/ms_spent`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_MS_SPENT),
      },
    });
  };
}

export function fetchAdminAnalytics() {
  const endpoint = `${Globals.apiUrl}/admin/analytics`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ADMIN_ANALYTICS),
      },
    });
  };
}

export function fetchAssignmentPeanuts() {
  const endpoint = `${Globals.apiUrl}/assignment_peanuts`;
  return async (dispatch, getState) => {
    dispatch({ // @TODO: if this fails, need to save this somewhere to retry again when we're back online
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ALL_ASSIGNMENT_PEANUTS),
      },
    });
  };
}

export function fetchSevenDaysPeanuts(userId) {
  const endpoint = `${Globals.apiUrl}/seven_days_peanuts/${userId}`;
  return async (dispatch, getState) => {
    dispatch({ // @TODO: if this fails, need to save this somewhere to retry again when we're back online
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_SEVEN_DAYS_PEANUTS),
      },
    });
  };
}

export function apiUpdateUser(updatedFields) {
  const formData = new FormData();
  Object.entries(updatedFields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  const endpoint = `${Globals.apiUrl}/user`;
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_OAK_USER),
      },
    });
  };
}

export function updateUserSettings(updatedFields) {
  const formData = new FormData();
  Object.entries(updatedFields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  const endpoint = `${Globals.apiUrl}/user/settings`;
  return async (dispatch, getState) => {
    if (Object.keys(updatedFields).includes('preferred_translation_id')) {
      // in case we're offline, setting preferred translation id will save it to local storage to still work without updating back end
      dispatch(setPreferredTranslationId(updatedFields.preferred_translation_id));
    }
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_USER_SETTINGS),
      },
    });
  };
}

export function setI18nUpdated() { // @TODO: not a huge fan of this being in redux, but it works for now
  return {
    type: SET_I18N_UPDATED,
  };
}

export function apiCreateClassroomGoal(classroomId, goalTitle, dateAvailable, dateDeadline, callback) { // @TODO: rename actions...don't need to prepend with api
  const ACTION_CREATOR_NAME = 'apiCreateClassroomGoal';
  const insertJson = {
    title: goalTitle,
    date_available: _jsDateToMysqlDate(dateAvailable),
    date_deadline: _jsDateToMysqlDate(dateDeadline),
  };
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(insertJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    callback?.(response.payload.classroom_goal_metadata.id);
  };
}

export function apiAdminCreateClassroomGoal(classroomId, pipeDelimitedText) { // @TODO: rename actions...don't need to prepend with api
  const ACTION_CREATOR_NAME = 'apiAdminCreateClassroomGoal';
  const endpoint = `${Globals.apiUrl}/admin/classrooms/${classroomId}/goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify({pipe_delimited_text: pipeDelimitedText}),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiUpdateGoal(goalId, goalTitle, goalDescription, goalType, moduleIds, passageIds, dateAvailable, dateDeadline, callbackToDispatch, status, callback) { // @TODO: web uses moduleIds, mobile uses passageIds...these should probably be combined, at least when group/reading goals are editable client-side // @TODO: this needs to change to moduleIds? // @TODO: seems like callbackToDispatch is only being used to fetch wrinkles...maybe just make that part of the response?
  const ACTION_CREATOR_NAME = 'apiUpdateGoal';
  const updateJson = {};
  if (goalTitle) {
    updateJson.title = goalTitle;
  }
  if (goalDescription) {
    updateJson.description = goalDescription;
  }
  if (goalType) {
    updateJson.goal_type = goalType;
  }
  if (moduleIds) {
    updateJson.module_order = moduleIds;
  }
  if (passageIds) {
    updateJson.passage_ids = passageIds;
  }
  if (dateAvailable !== undefined) { // null is a valid value
    updateJson.date_available = dateAvailable ? _jsDateToMysqlDate(dateAvailable) : null;
  }
  if (dateDeadline !== undefined) { // null is a valid value
    updateJson.date_deadline = dateDeadline ? _jsDateToMysqlDate(dateDeadline) : null;
  }
  if (status) {
    updateJson.status = status;
  }
  // @TODO: if no key/value pairs in json, don't make request
  const endpoint = `${Globals.apiUrl}/goals/${goalId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    if (callbackToDispatch) {
      dispatch(callbackToDispatch());
    }
    if (callback) {
      callback();
    }
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiUpdateClassroomGoal(classroomId, goalId, updateKeyValues, userIdsToAssign) {
  const ACTION_CREATOR_NAME = 'apiUpdateClassroomGoal';
  const updateJson = {};
  if (updateKeyValues.status) {
    updateJson.status = updateKeyValues.status;
  }
  if (updateKeyValues.canChooseTranslation !== undefined) {
    updateJson.can_choose_translation = +updateKeyValues.canChooseTranslation;
  }
  if (updateKeyValues.isPublic !== undefined) {
    updateJson.is_public = +updateKeyValues.isPublic;
  }
  if (userIdsToAssign) {
    updateJson.user_ids_to_assign = userIdsToAssign;
  }
  // @TODO: if no key/value pairs in json, don't make request...maybe do in bailout?
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiUpdateClassroom(classroomId, isJoinCodeEnabled) {
  const ACTION_CREATOR_NAME = 'apiUpdateClassroom';
  const updateJson = {
    is_join_code_enabled: +isJoinCodeEnabled,
  };
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOMS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiSaveGoal(goalTitle, passageIds, callback) {
  const ACTION_CREATOR_NAME = 'apiSaveGoal';
  const updateJson = {};
  updateJson.goal_title = goalTitle;
  updateJson.passage_ids = passageIds;
  const endpoint = `${Globals.apiUrl}/goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    if (response.payload.new_goal_id) {
      // this should always be true, but in case there's a network error, check the conditional
      dispatch(setActiveGoalId(response.payload.new_goal_id));
      callback?.();
    }
  };
}

export function apiSavePublicGoal(publicGoalId, preferredTranslationId) {
  const ACTION_CREATOR_NAME = 'apiSavePublicGoal';
  const insertJson = {
    public_goal_id: publicGoalId,
    translation_id: preferredTranslationId,
  };
  const endpoint = `${Globals.apiUrl}/user_goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(insertJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    if (response.payload.new_goal_id) {
      // this should always be true, but in case there's a network error, check the conditional
      dispatch(setActiveGoalId(response.payload.new_goal_id));
    }
  };
}

export function apiDeleteGoal(goalId) {
  const ACTION_CREATOR_NAME = 'apiDeleteGoal';
  const endpoint = `${Globals.apiUrl}/goals/${goalId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function addCompletedLesson(lesson) {
  return {
    type: ADD_COMPLETED_LESSON,
    lesson,
  };
}

export function removeCompletedLesson(uuid) {
  return {
    type: REMOVE_COMPLETED_LESSON,
    uuid,
  };
}

export function updateGoalSettings(updatedSettings) {
  return {
    type: UPDATE_GOAL_SETTINGS,
    updatedSettings,
  };
}

export function setNotificationPreference(channelId, isOn) {
  return {
    type: SET_NOTIFICATION_PREFERENCE,
    channelId,
    isOn,
  };
}

export function setPassageOfTheDayNotificationPreference(channelId, isOn) {
  return {
    type: SET_NOTIFICATION_PREFERENCE,
    channelId,
    isOn,
  };
}

export function setScheduledStreakReminderIds(ids) {
  return {
    type: SET_SCHEDULED_STREAK_REMINDER_IDS,
    ids,
  };
}

export function setScheduledDailyGoalsReminderIds(ids) {
  return {
    type: SET_SCHEDULED_DAILY_GOALS_REMINDER_IDS,
    ids,
  };
}

export function setScheduledPassageOfTheDayNotificationIds(ids) {
  return {
    type: SET_SCHEDULED_PASSAGE_OF_THE_DAY_NOTIFICATION_IDS,
    ids,
  };
}

export function setDailyReminderTime(formattedTime) {
  return {
    type: SET_DAILY_REMINDER_TIME,
    formattedTime,
  };
}

export function setPassageOfTheDayNotificationTime(formattedTime) {
  return {
    type: SET_PASSAGE_OF_THE_DAY_NOTIFICATION_TIME,
    formattedTime,
  };
}

// @MARK: fundraising
export function fetchOrganizations() {
  const ACTION_CREATOR_NAME = 'fetchOrganizations';
  const endpoint = `${Globals.apiUrl}/organizations`; // @TODO: "?publicOnly=1" should probbly be included
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_ORGANIZATIONS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchOrganization(organizationSlug) {
  const ACTION_CREATOR_NAME = 'fetchOrganization';
  const endpoint = `${Globals.apiUrl}/organizations/${organizationSlug}`; // @TODO: "?withCampaigns=1" should probbly be included
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_ORGANIZATION),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchPledges(campaignId) {
  const ACTION_CREATOR_NAME = 'fetchPledges';
  const endpoint = `${Globals.apiUrl}/campaigns/${campaignId}/pledges`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(true), // @TODO: shouldn't be true...jwt needs to be included, but currently only configured for mobile
        types: getFetchTypes(endpoint, SET_PLEDGES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchPledgeForToken(token) {
  const ACTION_CREATOR_NAME = 'fetchPledgeForToken';
  const endpoint = `${Globals.apiUrl}/pledges/${token}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(true), // @TODO: shouldn't be true...jwt needs to be included, but currently only configured for mobile
        types: getFetchTypes(endpoint, SET_PLEDGE),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchParticipants(campaignId) {
  const ACTION_CREATOR_NAME = 'fetchParticipants';
  const endpoint = `${Globals.apiUrl}/campaigns/${campaignId}/participants`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(true), // @TODO: shouldn't be true...jwt needs to be included, but currently only configured for mobile
        types: getFetchTypes(endpoint, SET_PARTICIPANTS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function inviteParticipants(campaignId, participants) {
  const ACTION_CREATOR_NAME = 'inviteParticipants';
  const endpoint = `${Globals.apiUrl}/campaigns/${campaignId}/participants`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify({participants}),
        headers: {
          ...await getRequestHeaders(), // @TODO: shouldn't be true...jwt needs to be included, but currently only configured for mobile
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_PARTICIPANTS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchUser(displayName, localeCode) { // @TODO: put in common/actions?
  const ACTION_CREATOR_NAME = 'fetchUser';
  let getArgs = `?tz_offset=${new Date().getTimezoneOffset()}`; // @TODO: use a lib (built-in?) to create getArgs without building manually
  if (displayName) {
    getArgs = `${getArgs}&display_name=${displayName}`;
  }
  if (localeCode) {
    getArgs = `${getArgs}&locale_code=${localeCode}`;
  }
  const endpoint = `${Globals.apiUrl}/user${getArgs}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_OAK_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

// @TODO: would love to subscribe to queries to get updates in real-time if using another device...however, probably not critical right now
export function fetchBooks() {
  // @TODO: don't re-fetch if already in redux...maybe make api call with a timestamp of last updated, and back end can handle it as needed
  const ACTION_CREATOR_NAME = 'fetchBooks';
  const endpoint = `${Globals.apiUrl}/books`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_BOOKS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchBookNames() {
  // @TODO: don't re-fetch if already in redux...maybe make api call with a timestamp of last updated, and back end can handle it as needed
  const ACTION_CREATOR_NAME = 'fetchBookNames';
  const endpoint = `${Globals.apiUrl}/book_names`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_BOOK_NAMES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchChapters() {
  // @TODO: don't re-fetch if already in redux...maybe make api call with a timestamp of last updated, and back end can handle it as needed
  const ACTION_CREATOR_NAME = 'fetchChapters';
  const endpoint = `${Globals.apiUrl}/chapters`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CHAPTERS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchTranslations() {
  // @TODO: don't re-fetch if already in redux...maybe make api call with a timestamp of last updated, and back end can handle it as needed
  const ACTION_CREATOR_NAME = 'fetchTranslations';
  const endpoint = `${Globals.apiUrl}/translations`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_TRANSLATIONS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchGoals() {
  const ACTION_CREATOR_NAME = 'fetchGoals';
  const endpoint = `${Globals.apiUrl}/goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchGoalPreview(previewGoalId) {
  const ACTION_CREATOR_NAME = 'fetchGoalPreview';
  const endpoint = `${Globals.apiUrl}/public_goals/${previewGoalId}/preview`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOAL_PREVIEW),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchUpdatedVerses() {
  const ACTION_CREATOR_NAME = 'fetchUpdatedVerses';
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const globalReduxStore = getState();
    if (Object.keys(globalReduxStore.verses).length) { // @TODO: this shouldn't do the actual fetching...only a ping to see if there's stuff to fetch
      // no reason to call this on first open or if there are no verses in the redux store
      const endpoint = `${Globals.apiUrl}/translations/${Object.keys(globalReduxStore.verses).join(',')}/updated_verses?verses_last_updated_timestamp=${globalReduxStore.versesLastUpdatedTimestamp}`;
      const response = await dispatch({
        [RSAA]: {
          endpoint,
          method: GET,
          headers: await getRequestHeaders(),
          types: getFetchTypes(endpoint, UPDATE_VERSES),
        },
      });
      if (!response.error) {
        dispatch(setVersesLastUpdated());
      }
    }
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function setVersesLastUpdated() {
  return {
    type: SET_VERSES_LAST_UPDATED,
  };
}

export function toggleMinigameActive(minigameId) {
  return {
    type: TOGGLE_MINIGAME_ACTIVE,
    minigameId,
  };
}

export function setActiveGoalId(goalId) {
  return {
    type: SET_ACTIVE_GOAL_ID,
    goalId,
  };
}

// @MARK: settings
export function setPreferredTranslationId(preferredTranslationId) {
  return {
    type: SET_PREFERRED_TRANSLATION_ID,
    preferredTranslationId,
  };
}

export function setPreferredScale(preferredScale) {
  return {
    type: SET_PREFERRED_SCALE,
    preferredScale,
  };
}

export function setPreferredVerseSelectionMethod(preferredVerseSelectionMethod) {
  return {
    type: SET_PREFERRED_VERSE_SELECTION_METHOD,
    preferredVerseSelectionMethod,
  };
}

export function setBookSortMethod(sortMethod) {
  return {
    type: SET_BOOK_SORT_METHOD,
    sortMethod,
  };
}

export function addWrinkly101(translationId) {
  const ACTION_CREATOR_NAME = 'addWrinkly101';
  const formData = new FormData();
  formData.append('translation_id', translationId);
  const endpoint = `${Globals.apiUrl}/wrinkly_101`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    dispatch({// @TODO: if this fails, need to alert user
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function uploadProfilePicture(base64Image, callback) {
  const ACTION_CREATOR_NAME = 'uploadProfilePicture';
  const formData = new FormData();
  formData.append('file', base64Image);
  const endpoint = `${Globals.apiUrl}/user/profile_picture`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PUT,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''), // @TODO: need a success action type? probably not...
      },
    });
    callback();
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

// @MARK: fundraising
export function saveSponsorAndPledge(displayName, email, campaignId, centsPerWrinkle, maxDonationCents, stripePaymentMethodId) {
  const ACTION_CREATOR_NAME = 'saveSponsorAndPledge';
  const formData = new FormData();
  formData.append('display_name', displayName);
  formData.append('email', email);
  formData.append('campaign_id', campaignId);
  formData.append('cents_per_wrinkle', centsPerWrinkle);
  formData.append('max_donation_cents', maxDonationCents);
  formData.append('stripe_payment_method_id', stripePaymentMethodId);
  const endpoint = `${Globals.apiUrl}/pledges`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(true), // @TODO: it's possible the user is logged in...jwt optional?
        types: getFetchTypes(endpoint, ''), // @TODO: save something in redux instead of optimistic rendering of success message?
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchOrganizationCampaigns() {
  const ACTION_CREATOR_NAME = 'fetchOrganizationCampaigns';
  const endpoint = `${Globals.apiUrl}/organization_campaigns`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ORGANIZATION_CAMPAIGNS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchParticipantCampaigns() {
  const ACTION_CREATOR_NAME = 'fetchParticipantCampaigns';
  const endpoint = `${Globals.apiUrl}/participant_campaigns`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_PARTICIPANT_CAMPAIGNS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function joinFundraisingCampaign(invitationCode, callback) {
  const ACTION_CREATOR_NAME = 'joinFundraisingCampaign';
  const endpoint = `${Globals.apiUrl}/join_campaign`;
  const formData = new FormData();
  formData.append('invitation_code', invitationCode);
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_PARTICIPANT_CAMPAIGNS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    callback(response.error, response.payload.message);
  };
}

// @MARK: wrinkles
export function fetchWrinkles() {
  const ACTION_CREATOR_NAME = 'fetchWrinkles';
  const endpoint = `${Globals.apiUrl}/wrinkles`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_WRINKLES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

// @MARK: friends
export function fetchFriends() {
  const ACTION_CREATOR_NAME = 'fetchFriends';
  const endpoint = `${Globals.apiUrl}/friends`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_FRIENDS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchPublicUser(userId) {
  const ACTION_CREATOR_NAME = 'fetchPublicUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true, {userId}));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, PUBLIC_USERS_SEARCHED), // @TODO: this used to be FOLLOW_USER...make sure there are no regressions (especially in web)
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function followUser(userId) {
  const ACTION_CREATOR_NAME = 'followUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}/follow`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true, {userId}));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, FOLLOW_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function unfollowUser(userId) {
  const ACTION_CREATOR_NAME = 'unfollowUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}/unfollow`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, UNFOLLOW_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function blockUser(userId) {
  const ACTION_CREATOR_NAME = 'blockUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}/block`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, BLOCK_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function unblockUser(userId) {
  const ACTION_CREATOR_NAME = 'unblockUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}/unblock`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, UNBLOCK_USER),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function reportUser(userId, reason) {
  const ACTION_CREATOR_NAME = 'reportUser';
  const endpoint = `${Globals.apiUrl}/users/${userId}/report`;
  const formData = new FormData();
  formData.append('reason', reason);
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
    dispatch(fetchFriends());
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function setLastViewedFollowersTimestamp(timestamp) {
  return {
    type: SET_LAST_VIEWED_FOLLOWERS_TIMESTAMP,
    timestamp,
  };
}

export function reportTest(passageId, rawText, diffs, reportNotes) {
  const ACTION_CREATOR_NAME = 'reportTest';
  const formData = new FormData();
  formData.append('passage_id', passageId);
  formData.append('raw_text', rawText);
  formData.append('diffs', JSON.stringify(diffs));
  formData.append('report_notes', reportNotes);
  const endpoint = `${Globals.apiUrl}/report_test`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function setTestDraft(passageId, text, msSpent) {
  return {
    type: SET_TEST_DRAFT,
    passageId,
    text,
    msSpent,
  };
}

export function clearTestDraft(passageId) {
  return {
    type: CLEAR_TEST_DRAFT,
    passageId,
  };
}

export function fetchPublicGoals() {
  const ACTION_CREATOR_NAME = 'fetchPublicGoals';
  const endpoint = `${Globals.apiUrl}/public_goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_PUBLIC_GOALS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

// @TODO: let setTimestamp = 0;
// @TODO: let dispatchCount = 0;
export function fetchVerses(translationId, bookId, chapterNum, missingVerseNums, endpoint) {
  // @TODO: dispatchCount++;
  // @TODO: console.log({dispatchCount});
  const ACTION_CREATOR_NAME = 'fetchVerses';
  endpoint = endpoint || `${Globals.apiUrl}/translations/${translationId}/verses`; // @TODO: this should be paginated?
  if (missingVerseNums?.length) {
    endpoint = `${Globals.apiUrl}/translations/${translationId}/books/${bookId}/chapters/${chapterNum}/verses/${missingVerseNums.join(',')}`;
  }
  return async (dispatch, getState) => {
    // @TODO: dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_VERSES, false, null, ACTION_CREATOR_NAME), // @TODO: toast network errors
        bailout: (state) => {
          // only bail if previous request is in flight (errors should be retried, and ignore successes because they're probably old...not ignoring success causes issue with clearing cache and refetching active passages in same session)
          return !!Object.values(state.fetches).find((f) => f.endpoint === endpoint && f.status === FETCH_REQUEST_OBJECT.status);
        },
      },
    });
    if (response?.payload.next_page) { // @NOTE: `response` will be `undefined` if `bailout` above is executed, so do a null-check here
      dispatch(fetchVerses(translationId, bookId, chapterNum, missingVerseNums, response.payload.next_page));
    } else if (response) {
      // this is the last call in this request
      if (!Object.values(getState().fetches).find((f) => f.actionCreatorName === ACTION_CREATOR_NAME && f.status === FETCH_REQUEST_OBJECT.status)) {
        // there are no more verse requests in flight (even for other passages), so we can update the timestamp to force a re-render for components that need the verse(s)
        dispatch(setLastFetchVersesTimestamp());
        // @TODO: setTimestamp++;
        // @TODO: console.log({setTimestamp});
      }
    }
    // @TODO: dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function setLastFetchVersesTimestamp() {
  return {
    type: SET_LAST_FETCH_VERSES_TIMESTAMP,
    timestamp: Date.now(),
  };
}

export function addToReadingHistory(chapterId) {
  return {
    type: ADD_TO_READING_HISTORY,
    chapterId,
  };
}

export function removeFromReadingHistory(chapterId) {
  return {
    type: REMOVE_FROM_READING_HISTORY,
    chapterId,
  };
}

export function clearReadingHistory() {
  return {
    type: CLEAR_READING_HISTORY,
  };
}

export function clearLocalVersesForTranslationId(translationId) {
  return {
    type: CLEAR_VERSES_CACHE,
    translationId: translationId, // @TODO: there's a reason why i'm ignoring this eslint warning i think...just can't remember why...if it's null, the key is missing instead of only the value being null, but that doesn't seem to apply here...
  };
}

export function fetchLocales() {
  const endpoint = `${Globals.apiUrl}/locales`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_LOCALES),
      },
    });
  };
}

export function fetchI18n(localeCode, includeArchived) {
  const ACTION_CREATOR_NAME = 'fetchI18n';
  const endpoint = `${Globals.apiUrl}/i18n/${localeCode}?include_archived=${includeArchived ? 1 : 0}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_I18N),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    dispatch(setI18nUpdated());
  };
}

export function fetchI18nNotes(localeCode) {
  const endpoint = `${Globals.apiUrl}/i18n/notes?locale_code=${localeCode}`;
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_I18N_NOTES),
      },
    });
  };
}

export function addI18nNote(i18nId, upvoted, notes) {
  const endpoint = `${Globals.apiUrl}/i18n/notes`;
  const formData = new FormData();
  formData.append('i18n_id', i18nId);
  if (upvoted !== null) {
    formData.append('upvoted', upvoted ? 1 : 0);
  }
  if (notes) {
    formData.append('notes', notes);
  }
  return async (dispatch, getState) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, UPDATE_I18N_NOTES),
      },
    });
  };
}

export function updateI18nValue(i18nId, newValue) {
  const formData = new FormData();
  formData.append('new_value', newValue);
  const endpoint = `${Globals.apiUrl}/i18n/${i18nId}`;
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PUT,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_I18N),
      },
    });
  };
}

export function saveNewTranslations(newTranslationsStringifiedJson) {
  const endpoint = `${Globals.apiUrl}/i18n`;
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: newTranslationsStringifiedJson,
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_NEW_TRANSLATIONS_RETURN_VALUE),
      },
    });
  };
}

export function postAdditionalDonation(token, additionalDonationCents) {
  const updateJson = {};
  updateJson.token = token;
  updateJson.additional_donation_cents = additionalDonationCents;
  const endpoint = `${Globals.apiUrl}/pledges`;
  return async (dispatch, getState) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_PLEDGE),
      },
    });
  };
}

export function setReadingBookmarkChapterId(chapterId) {
  return {
    type: SET_READING_BOOKMARK_CHAPTER_ID,
    chapterId,
  };
}

export function setSubGoalActiveSelections(goalId, selectedSubGoalIds) {
  return {
    type: SET_SUB_GOAL_SELECTION,
    goalId,
    dataType: 'active', // @TODO: magic?
    selectedSubGoalIds,
  };
}

export function setSubGoalReviewSelections(goalId, selectedSubGoalIds) {
  return {
    type: SET_SUB_GOAL_SELECTION,
    goalId,
    dataType: 'review', // @TODO: magic? and better name than `dataType`
    selectedSubGoalIds,
  };
}

export function setSubGoalReviewPercentage(goalId, reviewPercentage) {
  return {
    type: SET_SUB_GOAL_REVIEW_PERCENTAGE,
    goalId,
    dataType: 'reviewPercentage', // @TODO: magic?
    reviewPercentage,
  };
}

export function upsertYearInReview(year) {
  const ACTION_CREATOR_NAME = 'upsertYearInReview';
  const endpoint = `${Globals.apiUrl}/year_in_review/${year}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_YEAR_IN_REVIEW_STATS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchAllPassagesOfTheDay() {
  const ACTION_CREATOR_NAME = 'fetchAllPassagesOfTheDay';
  const endpoint = `${Globals.apiUrl}/admin/all_passages_of_the_day`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ALL_PASSAGES_OF_THE_DAY),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function addNewPassagesOfTheDay(passagesText) {
  const ACTION_CREATOR_NAME = 'addNewPassagesOfTheDay';
  const endpoint = `${Globals.apiUrl}/admin/passages_of_the_day`;
  const formData = new FormData();
  formData.append('passages_text', passagesText);
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ALL_PASSAGES_OF_THE_DAY),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchPassageOfTheDay() {
  const ACTION_CREATOR_NAME = 'fetchPassageOfTheDay';
  const endpoint = `${Globals.apiUrl}/passage_of_the_day?user_date=${dateFnsFormat(new Date(), 'yyyy-MM-dd')}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_PASSAGE_OF_THE_DAY),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchMyClassroomsStudent() {
  const ACTION_CREATOR_NAME = 'fetchMyClassrooms';
  const endpoint = `${Globals.apiUrl}/student_classrooms`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CLASSROOMS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

// @MARK: schools
export function fetchMyClassroomsTeacher() {
  const ACTION_CREATOR_NAME = 'fetchMyClassrooms';
  const endpoint = `${Globals.apiUrl}/classrooms`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CLASSROOMS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function assignGoalToStudent(classroomId, goalId, studentId) {
  const ACTION_CREATOR_NAME = 'assignGoalToStudent';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/students/${studentId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ALL_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

/* @TODO: need this? or just fetchMyClassroom?
export function fetchClassroom(classroomId) {
  const ACTION_CREATOR_NAME = 'fetchClassroom';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ADD_CLASSROOMS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}
*/

export function fetchClassroomGoals(classroomId) {
  const ACTION_CREATOR_NAME = 'fetchClassroomGoals';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_ALL_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchClassroomStudents(classroomId) {
  const ACTION_CREATOR_NAME = 'fetchClassroomStudents';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/students`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CLASSROOM_STUDENTS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function inviteStudents(classroomId, studentNames) { // @TODO: or by email
  const ACTION_CREATOR_NAME = 'inviteStudents';
  const insertJson = {
    student_names: studentNames,
  };
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/students`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(insertJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOM_STUDENTS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function joinClassroom(joinCode, callback) {
  const ACTION_CREATOR_NAME = 'joinClassroom';
  const endpoint = `${Globals.apiUrl}/classrooms/join`;
  const formData = new FormData();
  formData.append('join_code', joinCode);
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CLASSROOMS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
    callback(response.error, response.payload.message);
  };
}

export function updateClassroomStudent(classroomId, classroomStudentId, updateFields) {
  const ACTION_CREATOR_NAME = 'joinClassroom';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/students/${classroomStudentId}`;
  const formData = new FormData();
  if (updateFields.status) {
    formData.append('status', status);
  }
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CLASSROOM_STUDENTS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchPassage(passageId) {
  const ACTION_CREATOR_NAME = 'fetchPassage';
  const endpoint = `${Globals.apiUrl}/passages/${passageId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_PASSAGE),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function createModule(classroomId, goalId, moduleName, dateAvailable, dateDeadline) {
  const ACTION_CREATOR_NAME = 'createModule';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/modules`;
  const insertJson = {};
  insertJson.name = moduleName;
  if (dateAvailable !== undefined) { // null is a valid value
    insertJson.date_available = _jsDateToMysqlDate(dateAvailable);
  }
  if (dateDeadline !== undefined) { // null is a valid value
    insertJson.date_deadline = _jsDateToMysqlDate(dateDeadline);
  }
  // @TODO: assignment order
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(insertJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function updateModule(classroomId, goalId, moduleId, moduleName, dateAvailable, dateDeadline, status, assignmentIds) {
  const ACTION_CREATOR_NAME = 'updateModule';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/modules/${moduleId}`;
  const updateJson = {};
  if (moduleName) {
    updateJson.name = moduleName;
  }
  if (dateAvailable !== undefined) { // null is a valid value
    updateJson.date_available = _jsDateToMysqlDate(dateAvailable);
  }
  if (dateDeadline !== undefined) { // null is a valid value
    updateJson.date_deadline = _jsDateToMysqlDate(dateDeadline);
  }
  if (status) {
    updateJson.status = status;
  }
  if (assignmentIds) {
    updateJson.assignment_order = assignmentIds;
  }
  // @TODO: deadline
  // @TODO: assignment order
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function deleteModule(classroomId, goalId, moduleId) {
  const ACTION_CREATOR_NAME = 'deleteModule';
  const formData = new FormData();
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/modules/${moduleId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function createAssignment(classroomId, goalId, moduleId, assignmentName, dateAvailable, dateDeadline, status, type, assignmentSettings) {
  const ACTION_CREATOR_NAME = 'createAssignment';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/assignments`;
  const insertJson = {};
  insertJson.module_id = moduleId;
  insertJson.name = assignmentName;
  if (dateAvailable !== undefined) { // null is a valid value
    insertJson.date_available = _jsDateToMysqlDate(dateAvailable);
  }
  if (dateDeadline !== undefined) { // null is a valid value
    insertJson.date_deadline = _jsDateToMysqlDate(dateDeadline);
  }
  if (status) {
    insertJson.status = status;
  }
  if (type) {
    insertJson.type = type;
  }
  Object.keys(assignmentSettings).forEach((key) => {
    insertJson[key] = assignmentSettings[key];
  });
  // @TODO: other fields
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: JSON.stringify(insertJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function updateAssignment(classroomId, goalId, assignmentId, assignmentName, dateAvailable, dateDeadline, status, type, assignmentSettings) {
  const ACTION_CREATOR_NAME = 'updateAssignment';
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/assignments/${assignmentId}`;
  const updateJson = {};
  if (assignmentName) {
    updateJson.name = assignmentName;
  }
  if (dateAvailable !== undefined) { // null is a valid value
    updateJson.date_available = _jsDateToMysqlDate(dateAvailable);
  }
  if (dateDeadline !== undefined) { // null is a valid value
    updateJson.date_deadline = _jsDateToMysqlDate(dateDeadline);
  }
  if (status) {
    updateJson.status = status;
  }
  if (type) {
    updateJson.type = type;
  }
  Object.keys(assignmentSettings).forEach((key) => {
    updateJson[key] = assignmentSettings[key];
  });
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function deleteAssignment(classroomId, goalId, moduleId, assignmentId) {
  const ACTION_CREATOR_NAME = 'deleteAssignment';
  const formData = new FormData();
  formData.append('module_id', moduleId);
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/assignments/${assignmentId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_GOAL),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchMinigames() {
  const ACTION_CREATOR_NAME = 'fetchMinigames';
  const endpoint = `${Globals.apiUrl}/minigames`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_MINIGAMES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchVersionSupport() {
  const ACTION_CREATOR_NAME = 'fetchVersionSupport';
  const endpoint = `${Globals.apiUrl}/version_support`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, SET_VERSION_SUPPORT),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchAdminWhatsNew() {
  const ACTION_CREATOR_NAME = 'fetchAdminWhatsNew';
  const endpoint = `${Globals.apiUrl}/admin/whats_new`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_WHATS_NEW_NOTES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function adminSaveNewWhatsNewNote(releaseNotes, releaseDate, appVersion, isIosSupported, isAndroidSupported, isWebSupported, link) {
  const ACTION_CREATOR_NAME = 'adminSaveNewWhatsNewNotes';
  const formData = new FormData();
  formData.append('release_notes', releaseNotes);
  formData.append('release_date', _jsDateToMysqlDate(releaseDate));
  formData.append('app_version', appVersion);
  formData.append('is_ios', +isIosSupported);
  formData.append('is_android', +isAndroidSupported);
  formData.append('is_web', +isWebSupported);
  formData.append('link', link);
  const endpoint = `${Globals.apiUrl}/admin/whats_new`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_WHATS_NEW_NOTES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiUpdateWhatsNewNote(whatsNewId, updatedValues) {
  const ACTION_CREATOR_NAME = 'apiUpdateWhatsNewNote';
  const updateJson = {};
  const fields = Object.keys(updatedValues);
  if (fields.includes('appVersion')) {
    updateJson.app_version = updatedValues.appVersion;
  }
  if (fields.includes('releaseDate')) {
    updateJson.release_date = dateFnsFormat(updatedValues.releaseDate, 'yyyy-MM-dd');
  }
  if (fields.includes('isIos')) {
    updateJson.is_ios = +updatedValues.isIos;
  }
  if (fields.includes('isAndroid')) {
    updateJson.is_android = +updatedValues.isAndroid;
  }
  if (fields.includes('isWeb')) {
    updateJson.is_web = +updatedValues.isWeb;
  }
  if (fields.includes('releaseNotes')) {
    updateJson.release_notes = updatedValues.releaseNotes;
  }
  if (fields.includes('link')) {
    updateJson.link = updatedValues.link;
  }
  if (fields.includes('isSupported')) {
    updateJson.is_supported = updatedValues.isSupported;
  }
  // @TODO: if no key/value pairs in json, don't make request
  const endpoint = `${Globals.apiUrl}/admin/whats_new/${whatsNewId}`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_WHATS_NEW_NOTES),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function fetchTags() { // @TODO: admin only
  const ACTION_CREATOR_NAME = 'fetchTags';
  const endpoint = `${Globals.apiUrl}/goals/tags`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_TAGS),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function apiUpdateGoalTags(classroomId, goalId, tags) {
  const ACTION_CREATOR_NAME = 'apiUpdateGoalTags';
  const updateJson = {
    tags,
  };
  const endpoint = `${Globals.apiUrl}/classrooms/${classroomId}/goals/${goalId}/tags`;
  return async (dispatch, getState) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: JSON.stringify(updateJson),
        headers: {
          ...await getRequestHeaders(),
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        types: getFetchTypes(endpoint, SET_CLASSROOM_GOAL_METADATA),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function addToSuperSearchHistory(searchText) {
  return {
    type: ADD_TO_SUPER_SEARCH_HISTORY,
    searchText,
  };
}

export function removeFromSuperSearchHistory(searchText) {
  return {
    type: REMOVE_FROM_SUPER_SEARCH_HISTORY,
    searchText,
  };
}

export function clearSuperSearchHistory() {
  return {
    type: CLEAR_SUPER_SEARCH_HISTORY,
  };
}

export {
  fetchChatMessages,
  fetchWhatsNew,
  getRequestHeaders,
  logout,
  markAllMessagesRead,
  saveFcmToken,
  saveInfoToRedux,
  sendChatMessage,
  sendMessage,
  setFeedbackChatDraftText,
  setLightDarkMode,
  setWhatsNewLastReadTimestamp,
  setWhatsNewLastReadVersion,
};
