/*
 * Ryan O'Dowd
 * 2022-07-29
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  Alert,
} from '@mui/material';
import {
  saveSponsorAndPledge,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';
import {
  useStripe,
} from '@stripe/react-stripe-js';

const PaymentStatus = (props) => {
  const dispatch = useDispatch();

  const [_isComponentVisible, setIsComponentVisible] = useState(false);
  const [_setupIntentStatus, setSetupIntentStatus] = useState(null);

  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search); // @TODO: replace query-string with URLSearchParams
    const clientSecret = urlParams.get('setup_intent_client_secret');
    if (!clientSecret) {
      setIsComponentVisible(false);
      return;
    } else {
      setIsComponentVisible(true);
    }

    stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
      // @TODO: log to slack if setupIntent.status !== 'succeeded';
      setSetupIntentStatus(setupIntent.status);

      const displayName = urlParams.get('displayName');
      const email = urlParams.get('email');
      const campaignId = urlParams.get('campaignId');
      const pledgeCents = urlParams.get('pledgeCents');
      const maxDonationCents = urlParams.get('maxDonationCents');

      // ensure that a refresh doesn't cause duplicate pledges
      history.replaceState(null, '', location.pathname);

      dispatch(saveSponsorAndPledge(
        displayName,
        email,
        campaignId,
        pledgeCents,
        maxDonationCents,
        setupIntent.payment_method,
      )); // @TODO: show loading indicator during call
    });
  }, [stripe, dispatch]);

  let alertContent = null;
  let otherStyles = {};
  switch (_setupIntentStatus) {
  case 'succeeded': {
    alertContent = (
      <div>
        <h3 style={styles.thankYou}>Thank you for sponsoring!</h3>
        <p>Your payment information has been captured successfully.</p>
        {/* @TODO: <p>{"You can track progress here on the organization's page throughout the entire capaign, and at the end of the campaign, we'll send a report summarizing the results.  Please check your inbox for final confirmation and additional information."}</p> */}
        <p>{"At the end of the campaign, we'll send a report summarizing the results.  Please check your inbox for final confirmation and additional information."}</p>
      </div>
    );
    break;
  }
  case 'processing': {
    alertContent = (
      <div>
        <p>{"Processing payment details. We'll update you when processing is complete."}</p>
      </div>
    );
    break;
  }
  case 'requires_payment_method': {
    otherStyles = styles.errorAlert;
    alertContent = (
      <div>
        <p>Failed to capture payment details.  Please try another payment method.</p>
      </div>
    );
    break;
  }
  default: {
    break;
  }
  }

  if (_isComponentVisible) {
    return (
      <Alert
        style={{...styles.container, ...otherStyles}}
        icon={false}
        onClose={() => setSetupIntentStatus(null)}
      >
        {alertContent}
      </Alert>
    );
  } else {
    return null;
  }
};

export default PaymentStatus;
