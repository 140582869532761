/*
 * Ryan O'Dowd
 * 2024-08-19
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
// @TODO: import 'react-multi-email/style.css';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  apiUpdateClassroom,
  inviteStudents,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Globals from '../../../Globals';
import PropTypes from 'prop-types';
/* @TODO:
import {
  ReactMultiEmail,
} from 'react-multi-email';
import isEmail from 'validator/lib/isEmail';
*/
import styles from './styles';

const InviteDialog = (props) => {
  const dispatch = useDispatch();

  const [_isAdvancedInviteOpen, setIsAdvancedInviteOpen] = useState(false);
  const [_studentInivitationNamesAndEmails, setStudentInivitationNamesAndEmails] = useState([]);

  const classroom = useSelector((state) => state.classrooms[props.classroomId] || {}); // @TODO: shouldn't need `{}`

  return (
    <Dialog
      open={props.isOpen}
      style={styles.dialog}
      aria-labelledby='alert-dialog-title-todo'
      aria-describedby='alert-dialog-description-todo'
      // @TODO: fullScreen={Globals.isMobile}
      onClose={props.onClose}
    >
      <DialogTitle id='alert-dialog-title-todo'>
        <span style={styles.titleAndAction}>
          Invite students
          <Button
            color='primary'
            variant='outlined'
            dense={true}// @TODO: not working
            autoFocus={false}
            onClick={() => setIsAdvancedInviteOpen((curr) => !curr)}
          >
            {_isAdvancedInviteOpen ? 'Invite with link' : 'Advanced invitations'}
          </Button>
        </span>
      </DialogTitle>
      <DialogContent style={styles.dialogContent}>
        {!_isAdvancedInviteOpen &&
          <div>
            <p>{'Inviting students with a public join code is the easiest way to get started.  Students can enter the classroom code in their accounts to join (side menu -> Groups).  For finer control of join codes, please select "advanced invitations".'}</p>
            <div style={styles.joinCodeContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={classroom.is_join_code_enabled}
                    onChange={(e) => dispatch(apiUpdateClassroom(props.classroomId, +e.target.checked))}
                  />
                }
                label='Invite students to join via public join code:'
              />
              <div style={!classroom.is_join_code_enabled ? styles.disabled : {}}>
                <code style={{...styles.joinCode, ...(!classroom.is_join_code_enabled ? styles.disabled : {})}}>{classroom.join_code}</code>
                <IconButton
                  aria-controls='copy-join-code'
                  aria-haspopup='true'
                  disabled={!classroom.is_join_code_enabled}
                  onClick={(event) => {
                    navigator.clipboard.writeText(classroom.join_code);
                  }}
                >
                  <ContentCopyIcon color={Globals.colors.gray} />
                </IconButton>
              </div>
            </div>
          </div>
        }

        {_isAdvancedInviteOpen &&
          <div>
            <p>{'Students can be invited manually with their own unique join codes.  This is helpful for smaller classrooms or to ensure that only invited students can join.  For bulk invitations, you can click on the "invite with link" button.'}</p>
            <p>Please enter the names of the students you would like to invite.  Names must be entered in the format <code style={styles.code}>FirstName LastName</code>. Multiple students should be separated by a comma (<code style={styles.code}>,</code>) or semicolon (<code style={styles.code}>;</code>).</p>
            {/* @TODO: show email preview */}
            {/* @TODO: optional personal note that teacher can attach */}
            {/* @TODO:
            <ReactMultiEmail// @TODO: input field is ugly
              style={styles.emails}
              // @TODO: placeholder='email-address-1;email-address-2'
              emails={_studentInivitationNamesAndEmails}
              onChange={(emails) => setStudentInivitationNamesAndEmails(emails)}
              validateEmail={(email) => {
                return isEmail(email);
              }}
              getLabel={_getLabel}
            />
            <p style={styles.legend}>Invitations will be sent to all <span style={{...styles.legendText, ...styles.newEmail}}>new addresses</span>, reminders will be sent to <span style={{...styles.legendText, ...styles.alreadyInvitedEmail}}>existing addresses</span>, and <span style={{...styles.legendText, ...styles.alreadyAcceptedEmail}}>accepted invitations</span> will be skipped.</p>{/* @TODO: only show legend if some are yellow or red (and even then, only show if present) /}
            */}
            <TextField
              value={_studentInivitationNamesAndEmails}
              onChange={(e) => setStudentInivitationNamesAndEmails(e.target.value)}
              multiline={true}
              minRows={1}
              maxRows={6}
              placeholder='John Doe, Jane Smith, etc.'
              fullWidth
              label='Student names'
              variant='standard'
            />
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='text'
          autoFocus={false}
          onClick={props.onClose}
        >
          Close
        </Button>

        {_isAdvancedInviteOpen &&
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            disabled={!_studentInivitationNamesAndEmails.length}
            onClick={() => {
              /* @TODO:
              const studentEmails = _studentInivitationNamesAndEmails.split(/[\s,;]+/).map((s) => s.trim()).filter((s) => s.length && isEmail(s));
              if (studentEmails.length !== (_studentInivitationNamesAndEmails.match(/@/g) || []).length) {
                // @TODO: warn teacher that there are invalid emails
                return;
              }
              */
              const studentNames = _studentInivitationNamesAndEmails.split(/[,;]/g).map((s) => s.trim()).filter((s) => s.length);
              dispatch(inviteStudents(props.classroomId, studentNames)); // @TODO: or by email
              props.onClose();
              // @TODO: clear text input
            }}
          >
            Add students
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

InviteDialog.propTypes = {
  schoolId: PropTypes.number.isRequired,
  classroomId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteDialog;
