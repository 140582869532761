/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    borderRadius: 8,
    margin: 4,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Globals.colors.primary,
  },
  generalFeedbackContainer: {
    borderColor: Globals.colors.incorrectText,
  },
  upvotedContainer: {
    borderColor: Globals.colors.accentDark,
  },
  downvotedContainer: {
    borderColor: Globals.colors.incorrect,
  },
  aboveFold: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  translations: {
    flex: 1,
    flexDirection: 'column',
    cursor: 'pointer',
  },
  generalFeedbackTitle: {
    fontWeight: '600',
    fontSize: 20,
  },
  newNoteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 16,
    paddingTop: 8,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Globals.colors.lightGray,
  },
  saveButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  translationRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  locale: {
    fontWeight: '600',
    color: Globals.colors.primaryExtraDark,
    marginRight: 8,
  },
  highlightedText: {
    backgroundColor: Globals.colors.accent,
  },

  notesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Globals.colors.primary,
    marginTop: 4,
    padding: 8,
  },
  updateDialogContent: {
    width: 400,
  },
  updatedText: {
    marginTop: 32,
    width: '100%',
  },
};

export default styles;
