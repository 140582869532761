/**
 * Ryan O'Dowd
 * 2022-11-28
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  de,
  enUS as en,
  es,
} from 'date-fns/locale'; // @TODO: support more englishes?
import React from 'react';
import Text from './common/components/OakText';

const i18n = (locale) => { // @TODO: this should be an object that throws an error if a key is missing (one of the supported languages) // @TODO: make sure error is thrown even when i18n is moved to the back end
  // @TODO: deprecated the copyright notices in i18n db
  // @TODO: can deprecate i18n('selectFirstVerse');
  // @TODO: can deprecate i18n('selectLastVerse');
  const words = {
    impactBody: {
      en: (msSpent, versesReviewed, styles) => <Text style={styles}>Wrinkly users have spent over {msSpent} reviewing more than {versesReviewed}.</Text>,
      es: (msSpent, versesReviewed, styles) => <Text style={styles}>Usuarios de Wrinkly gastaban más de {msSpent} mientras revisaba más de {versesReviewed}.</Text>,
      de: (msSpent, versesReviewed, styles) => <Text style={styles}>Wrinkly-Benutzer haben über {msSpent} damit verbracht, mehr als {versesReviewed} zu rezensieren.</Text>,
    },
    yearInReviewLessons: { // @TODO: include longest streak?
      en: (numLessonsJsx, daysWithLessonsJsx, styles) => <Text style={styles}>You completed {numLessonsJsx} in {daysWithLessonsJsx}.</Text>, // @TODO: {Math.floor(lessonsPerDay) > 1 ? `  (That's ${Math.floor(lessonsPerDay) !== Math.ceil(lessonsPerDay) && 'more than '}` : null}<Text style={styles.statText}>{Math.floor(lessonsPerDay)}</Text> lessons per day!)
      // @TODO: es
    },
    yearInReviewTime: {
      en: (msSpentJsx, numVersesJsx, peanutsEarnedJsx, styles) => <Text style={styles}>You spent {msSpentJsx} reviewing more than {numVersesJsx} and earned {peanutsEarnedJsx}{".  Great job spending time in God's Word!"}</Text>,
      // @TODO: es
    },
    yearInReviewGoalsAndWrinkles: {
      en: (numGoalsWorkedOnJsx, numWrinklesEarnedJsx, numWrinklesEarned, numWrinklesEarnedUnits, numWrinklesInProgress, styles) => {
        return (
          <Text style={styles}>
            You worked on {numGoalsWorkedOnJsx} this year{!numWrinklesEarned ? '.' : <> and earned {numWrinklesEarnedJsx}.  That&apos;s {numWrinklesEarned} memorized {numWrinklesEarnedUnits}!</>}
            {!!numWrinklesInProgress && <>  (And you have {numWrinklesInProgress} in progress...keep up the good work!)</>}
          </Text>
        );
      },
      // @TODO: es
    },
    yearInReviewMiscFriends: {
      en: (numFriends, numFriendsJsx, styles) => <Text style={styles}>Great job with accountability; {numFriendsJsx} are using Wrinkly with you.  Share your year-end review with them!</Text>, // @TODO: make this interactive, reword, and also have alternative text if _stats.num_friends === 0
      // @TODO: es
    },
    yearInReviewMiscDays: {
      en: (daysWithLessonsJsx, styles) => <Text style={styles}>You completed a lesson on {daysWithLessonsJsx} this year!</Text>,
      // @TODO: es
    },
    yearInReviewShareMessage: {
      en: "I've been using Wrinkly to memorize Scripture.  See what I accomplished in 2023!",
      // @TODO: es
    },
    yearInReviewLifetime: { // @TODO: include longest streak? include current streak?
      en: (userJoinYear, numLifetimePeanutsJsx, numLifetimeWrinklesJsx, styles) => <Text style={styles}>Since joining Wrinkly on {userJoinYear}, you have earned a total of {numLifetimePeanutsJsx} and {numLifetimeWrinklesJsx}.</Text>,
      // @TODO: es
    },
    yearInReviewGlobal: {
      en: (numMsSpentJsx, numVersesReviewedJsx, styles) => <Text style={styles}>In total, Wrinkly users have collectively spent over {numMsSpentJsx} reviewing more than {numVersesReviewedJsx}.</Text>,
      // @TODO: es
    },
    // @NOTE: local development only; nothing in here should make it to prod: {
    updateDisabledOffline: {
      en: 'Phone is offline; please try again later.',
      // @TODO: es
    },
    updateDisabledUpdateInProgress: {
      en: 'Goal update in progress...',
      // @TODO: es
    },
    help: {
      en: 'Help',
      es: 'Ayuda',
    },
    streakRepair: {
      en: 'Streak repair', // @TODO: make sure this doesn't already exist
      // @TODO: es
    },
    finishAssignment: {
      en: 'Finish', // @TODO: would prefer something less academic than "assignment" or "lesson" for users who aren't in a classroom
    },
    plans: {
      en: 'Plans',
    },
    myMemoryGoals: {
      en: 'My memory goals',
    },
    myReadingPlans: {
      en: 'My reading plans',
    },
    myGroupGoals: {
      en: 'My group goals', // @TODO: would like a different name than this...
    },
    dailyGoals: {
      en: 'Daily goals',
    },
    readingHistory: {
      en: 'Reading history',
    },
    clearHistory: {
      en: 'Clear history',
    },
    passageOfTheDayBonus: {
      en: 'Passage of the day bonus',
    },
    addFriends: {
      en: 'Add friends',
    },
    readNow: {
      en: 'Read now',
    },
    availableFrom: {
      en: 'Available from',
    },
    modules: {
      en: 'Modules',
    },
    assignments: {
      en: 'Assignments',
    },
    passageOfTheDaySettings: {
      en: 'Passage of the day settings',
    },
    passageOfTheDayNotifications: {
      en: 'Passage of the day notifications',
    },
    passageOfTheDayNotificationsDescription: {
      en: 'Receive daily passage of the day notifications',
    },
    noPassageOfTheDay: {
      en: "Can't fetch passage of the day...please try again later.",
    },
    saveDisabledSaveInProgress: {
      en: 'Creating goal...',
    },
    updateWrinkly: {
      en: 'Update Wrinkly',
    },
    forceUpdateText: {
      en: "We're constantly making updates to make Wrinkly the most helpful app for memorizing and studying Scripture.  To ensure the best experience, please tap the button below to update to the latest version.",
    },
    versesLeveledUp: {
      en: 'Verses leveled up',
    },
    expiredLink: {
      en: 'Link expired; please request a new one.',
    },
    restoreGoal: {
      en: 'Restore',
    },
    archiveGoal: {
      en: 'Archive',
    },
    archiveGoalTitle: {
      en: 'Archive goal?',
    },
    archiveGoalBody: {
      en: `Archived goals can still be accessed in the "Completed goals" tab but won't show up in "All goals" lists.`,
    },
    archiveGoalConfirm: {
      en: 'Yes, archive goal',
    },
    fullyRepaired: {
      en: 'Fully repaired',
    },
    // @NOTE: local development only; nothing in here should make it to prod: }
  };

  const localeWords = {}; // @TODO: oak frozen object?
  Object.entries(words).forEach(([key, value]) => {
    localeWords[key] = value[locale] || value.en; // @TODO: return en if that locale doesn't exist
  });

  switch (locale) { // @TODO: abstract with Globals.supportedLocales?
  case 'es': {
    localeWords.locale = es;
    break;
  }
  case 'de': {
    localeWords.locale = de;
    break;
  }
  default: {
    localeWords.locale = en;
  }
  }

  return localeWords;
};

export default i18n;

export const i18nUnitsTime = {
  es: {
    millisecond: 'milisegundo',
    second: 'segundo',
    minute: 'minuto',
    hour: 'hora',
  },
  de: {
    millisecond: 'Millisekunde',
    second: 'Sekunde',
    minute: 'Minute',
    hour: 'Stunde',
  },
};
