/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  errorTextWrapper: {
    paddingTop: 32,
  },
  errorText: {
    color: Globals.colors.primaryExtraDark,
    margin: 16,
    fontSize: 20,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  h1: {
    margin: 0,
  },
  titleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  logoLink: {
    position: 'absolute',
    top: -24, // @TODO: dynamic
    right: 0,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 80,
    right: 0,
  },
  organizationName: {
    fontWeight: 'bold',
  },
  campaignWrapper: {
    marginLeft: 16,
  },
  campaignSeparator: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Globals.colors.primaryExtraLight,
    marginTop: 32,
  },
  pastCampaignSeparator: {
    borderTopColor: Globals.colors.accentDark,
  },
  campaignName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  campaignDate: {
    fontSize: 14,
    marginTop: -16,
    color: Globals.colors.accent, // @TODO: not very visible on light backgrounds
  },
  pastCampaignDate: {
    color: Globals.colors.primaryDark,
  },
  learnMoreLink: {
    fontSize: '0.9em',
    color: Globals.colors.accentLight, // @TODO: not very visible on light backgrounds
  },
  pastLearnMoreLink: {
    color: Globals.colors.primary,
  },
  currentCampaignsTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  campaignAction: {
    marginRight: 8,
  },
};

export default styles;
