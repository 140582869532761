// @TODO: fine-tune copy
/*
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import ContactForm from '../../common/components/ContactForm';
import DownloadLinks from '../../components/DownloadLinks';
import {
  Grid,
} from '@mui/material';
import {
  Link,
} from 'react-router-dom';
import React from 'react';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';

// @TODO: update all copy in here to be about schools, not fundraising
const Schools = (props) => {
  const _renderBulletPoint = (title, body, lightBackground) => { // @TODO: abstract this with fundraising
    return <span style={styles.bulletPoint}><span style={{...styles.title, ...(lightBackground ? styles.lightBackground : {})}}>{title}</span><br />{body}</span>;
  };

  return (
    <WebPageStub>
      <WebsiteSection
        isDarkBackground={true}
        title='Wrinkly in the classroom'
      >
        <p>{"Create Bible goals for students using Wrinkly's learning management system for schools.  Designed specifically with Scripture memorization in mind, Wrinkly also helps with reading plans and custom curriculum development."}</p>
      </WebsiteSection>

      <WebsiteSection
        title='Built for teachers'
      >{/* @TODO: make sure this looks good on mobile */}
        <div style={styles.inner}>
          <Grid container spacing={2} style={styles.sideBySide}>
            <Grid item xs={12} sm={6} style={styles.bulletPointsWrapper}>
              {_renderBulletPoint('Create', 'Develop custom curriculum for your students; assign Bible readings, verse lessons, memory tests, and custom content.', true)}
              {_renderBulletPoint('Track', 'Manage classroom membership, set deadlines, follow student progress, and generate reports to maximize student support and to encourage parent involvement.', true)}
              {_renderBulletPoint('Fundraise', <span>Raising money for a campus project, scholarships, missions, or another cause?  Wrinkly can help track donors and participants to raise money for every verse memorized.  See <Link to='/fundraising'>our fundraising page</Link> for more information.</span>, true)}
            </Grid>
            <Grid item xs={12} sm={6} style={styles.organizationContactWrapper}>
              <span style={styles.contactHeader}>Contact us</span>
              <div style={styles.contactWrapper}>
                <ContactForm
                  leadingText={<span>{"If you are a school teacher, administrator, or parent interested in using Wrinkly in the classroom, we'd love to hear from you.  Please fill out "}<a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/forms/d/e/1FAIpQLSc9ABMzGoHVFEo3NFD7UJIQNMs6LAlSW6HAxoxVYV-xsIwRkA/viewform?usp=sf_link'>this survey</a>{' or use the form below.  We appreciate your input!'}</span>}
                  textStyles={styles.contactText}
                />
              </div>
            </Grid>
          </Grid>
          <div style={styles.downloadCtaWrapper}>
            <h2>Download Wrinkly</h2>
            <span style={styles.downloadLinks}>
              <DownloadLinks />
            </span>
          </div>
        </div>
      </WebsiteSection>

      {/* @TODO:
      <WebsiteSection
        title='For students'
      >
        <div style={styles.bulletPointsWrapper}>
          {_renderBulletPoint('Always free.', 'Students never pay for access to Wrinkly.', true)}
          {_renderBulletPoint('Use Wrinkly to learn Bible verses.', 'Each verse memorized during the campaign will earn money for your organization.', true)}
        </div>
      </WebsiteSection>
      */}
    </WebPageStub>
  );
};

export default Schools;
