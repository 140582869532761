/**
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  lectureText: {
    color: Globals.colors.primaryExtraDark,
    whiteSpace: 'pre-wrap',
  },
};

export default styles;
