/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
} from 'react';
import {
  fetchWebStatsMsSpent,
  fetchWebStatsNumVersesReviewed,
  // @TODO: setUser,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ContactForm from '../../common/components/ContactForm';
import DownloadLinks from '../../components/DownloadLinks';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';
import utilities from '../../utilities';

const LandingPage = (props) => {
  const dispatch = useDispatch();
  const i18n = utilities.useI18n();

  const msSpent = useSelector((state) => state.webStatsMsSpent);
  const versesReviewed = useSelector((state) => state.webStatsNumVersesReviewed);

  // @TODO: const showBeta = new URLSearchParams(window.location.search).get('beta');

  useEffect(() => { // @TODO: usnsubscribe? should we return from this useEffect
    dispatch(fetchWebStatsMsSpent()); // @TODO: this shouldn't be a live stat...maybe update every month or so, but i don't want people calling this api on every page load
    dispatch(fetchWebStatsNumVersesReviewed()); // @TODO: this shouldn't be a live stat...maybe update every month or so, but i don't want people calling this api on every page load
    /* @TODO:
    firebase.auth().getRedirectResult().then((result) => {
      if (result.user) {
        firebase.analytics().logEvent('login', { // @TODO: abstract with other calls to robots
          displayName: result.user.displayName,
          authType: result.credential.providerId.replace('.com', ''), // google.com, facebook.com
        });
        // @TODO: firestore.setUser(result.user.uid);
        // @TODO: dispatch(setUser(result.user));
      }
    }).catch((error) => {
      // @TODO: better error handling
      console.log(error);
    });
    */
  }, [dispatch]);

  return (
    <WebPageStub>
      <WebsiteSection
        isDarkBackground={true}
        title='Memorize Bible verses more easily'
      >
        <p>{"Interact with God's Word in a new way."}</p>
        <span style={styles.downloadLinks}>
          <DownloadLinks />
        </span>
      </WebsiteSection>

      <WebsiteSection
        title='How it works'
      >
        <p>Wrinkly aims to make Scripture memory as effective and engaging as possible. Using multiple strategies for learning and memorization, Wrinkly is a fun and powerful tool.</p>
        <p>{"Read, play games, and test your memory as you engage with God's Word."}</p>
        <div style={styles.imagesWrapper}>
          <img style={styles.image} width={205} height={444} src='/img/store_presence/1.png' alt='track' />
          <img style={styles.image} width={205} height={444} src='/img/store_presence/2.png' alt='play' />
          <img style={styles.image} width={205} height={444} src='/img/store_presence/3.png' alt='listen' />
          <img style={styles.image} width={205} height={444} src='/img/store_presence/4.png' alt='earn' />
        </div>
      </WebsiteSection>

      <WebsiteSection
        isDarkBackground={true}
        title='Purpose'
      >
        <div style={styles.missionAndVisionWrapper}>
          <span><span style={styles.accentTitle}>Mission: </span>Increase engagement with God’s Word through memorization.</span>
          <span><span style={styles.accentTitle}>Vision: </span>Improve Bible access, literacy, and fluency with intentionality, joy, and excellence.</span>
          <span style={styles.statsWrapper}>
            <span style={styles.accentTitle}>Impact:&nbsp;</span>
            {i18n('impactBody',
              <span style={styles.statText}>{utilities.humanReadableTime(msSpent)}</span>,
              <span style={styles.statText}>{utilities.humanReadableNumber(versesReviewed)} {i18n('verses').toLocaleLowerCase()}</span>,
            )}
          </span>
        </div>
      </WebsiteSection>

      <WebsiteSection
        title='Contact us'
      >
        <div style={styles.contactAndDownloadWrapper}>
          <div style={styles.contactWrapper}>
            <ContactForm leadingText='Have a question or an idea?  Want to join our mailing list?  Please send us a message!' />
          </div>
          <div style={styles.downloadCtaWrapper}>
            <h2>Download now</h2>
            <span style={styles.downloadLinks}>
              <DownloadLinks />
            </span>
          </div>
        </div>
      </WebsiteSection>
    </WebPageStub>
  );
};

export default LandingPage;
