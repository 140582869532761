/**
 * Ryan O'Dowd
 * 2022-12-19
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  store,
} from '../Store';
import utilities from '../utilities';

export default class AssignmentSettings {
  constructor(isAdmin, type, assignmentName, passage, verseMemoryNumLessonsToComplete, verseMemoryGameTypesEnabled, verseMemoryGameDifficulty, verseMemoryMustMemorize, lectureText, videoUrl) {
    this.isAdmin = isAdmin;
    this.type = type;
    this.assignmentName = assignmentName;
    this.passage = passage;
    this.verseMemoryNumLessonsToComplete = verseMemoryNumLessonsToComplete;
    this.verseMemoryGameTypesEnabled = verseMemoryGameTypesEnabled;
    this.verseMemoryGameDifficulty = verseMemoryGameDifficulty;
    this.verseMemoryMustMemorize = verseMemoryMustMemorize;
    this.lectureText = lectureText;
    this.videoUrl = videoUrl;
  }

  _isValidPassage() {
    return this.passage && this.passage.book_id && this.passage.chapter_number && this.passage.start_verse_number && this.passage.end_verse_number;
  }

  validateAndMarshal() { // @TODO: more specific exceptions with better error messages
    if (!this.assignmentName && !this.isAdmin) {
      throw new Error('Assignment name is required');
    } else if (this.type === 'verse_memory') {
      if (!this._isValidPassage()) {
        // all other fields are optional
        throw new Error('Passage is required');
      }
      return {
        passage: this.passage,
        verseMemoryNumLessonsToComplete: this.verseMemoryNumLessonsToComplete,
        verseMemoryGameTypesEnabled: Object.entries(this.verseMemoryGameTypesEnabled).filter(([key, value]) => value).map(([key]) => +key),
        verseMemoryGameDifficulty: this.verseMemoryGameDifficulty === 'choice' ? null : this.verseMemoryGameDifficulty,
        verseMemoryMustMemorize: this.verseMemoryMustMemorize,
      };
    } else if (this.type === 'reading') {
      if (!this._isValidPassage()) {
        // all other fields are optional
        throw new Error('Passage is required');
      }
      return {
        passage: this.passage,
      };
    } else if (this.type === 'lecture') {
      if (!this.lectureText) {
        throw new Error('Text is required');
      }
      return {
        lectureText: this.lectureText,
      };
    } else if (this.type === 'video') {
      if (!this.videoUrl) {
        throw new Error('URL is required');
      }
      return {
        videoUrl: this.videoUrl,
      };
    }
  }
}
