/*
 * Ryan O'Dowd
 * 2022-07-23
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, {
  useMemo,
  useState,
} from 'react';
import ButtonGroup from '../../../common/components/OakButtonGroup';
import DownloadLinks from '../../../components/DownloadLinks';
import Globals from '../../../Globals';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import styles from './styles';
import utilities from '../../../utilities';

const JoinDialog = (props) => {
  const [_userType, setUserType] = useState(null);
  const [_pledgeCents, setPledgeCents] = useState(null);
  const [_maxDonationCents, setMaxDonationCents] = useState(null);
  const [_displayNameText, setDisplayNameText] = useState('');
  const [_emailText, setEmailText] = useState('');
  const [_emailTextFieldError, setEmailTextFieldError] = useState(null);
  const [_stripeFormError, setStripeFormError] = useState('');
  const [_pledgeCentsError, setPledgeCentsError] = useState(null);
  const [_maxDonationCentsError, setMaxDonationCentsError] = useState(null);
  const [_isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const maxVersesSponsored = useMemo(() => {
    return Math.floor(_maxDonationCents / _pledgeCents);
  }, [_maxDonationCents, _pledgeCents]);

  const _renderInnerContent = () => {
    if (!_userType) {
      return (
        <div>
          <p>This campaign is seeking participants to memorize Bible verses and donors to sponsor them.  Please select how you would like to participant in this campagin.</p>
          <span style={styles.userTypeButtons}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => setUserType('participant')}
            >
              Memorize
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => setUserType('sponsor')}
            >
              Sponsor
            </Button>
          </span>
        </div>
      );
    }

    if (_userType === 'participant') {
      return (
        <React.Fragment>
          <div style={styles.joinBody}>
            {props.campaign.public_code
              // @TODO: create a button that's a deeplink into wrinkly if on mobile or that prompts login if on web to join automatically
              ? <p style={styles.joinText}>{'Download Wrinkly, navigate to "Fundraising", and enter code '}<span style={styles.code}>{props.campaign.public_code}</span> to join this campaign.</p>
              : <p>Please contact <span style={styles.organizationName}>{props.organization.name}</span> for your unique code to join this campaign.</p>
            }
            <span style={styles.downloadLinksWrapper}>
              <DownloadLinks />
            </span>
          </div>
        </React.Fragment>
      );
    }

    // else this is a sponsor
    return (
      <React.Fragment>
        <span style={styles.formSection}>
          <span style={styles.sectionHeader}>Personal info
            <Tooltip title='Name/email at which you will recieve pledge confirmation, receipt, and campaign updates.'>
              <InfoIcon style={styles.infoIcon} />
            </Tooltip>
          </span>
          <span style={styles.nameEmailWrapper}>
            <TextField
              style={styles.nameTextField}
              value={_displayNameText}
              onChange={(e) => setDisplayNameText(e.target.value)}
              placeholder=''
              fullWidth
              autoFocus={true}
              label='Name'
              variant='standard'
            />
            <TextField
              style={styles.emailTextField}
              required={true}
              error={!!_emailTextFieldError}
              value={_emailText}
              onChange={(e) => {
                setEmailTextFieldError(null);
                setEmailText(e.target.value);
              }}
              placeholder=''
              fullWidth
              autoCapitalize='none'
              label='Email address'
              variant='standard'
              helperText={_emailTextFieldError}
            />
            {/* @TODO: checkbox for "keep me anonymous" */}
          </span>
        </span>

        <span style={styles.formSection}>
          <span style={styles.sectionHeader}>Pledge amount per verse
            <Tooltip title='Average pledge amounts vary dramatically between campaigns depending on the number, ages, and commitment levels of the participants.  We recommend a higher pledge amount for younger groups and shorter campaigns.  Regardless of the selected amount per verse, please be mindful of the amount of your maximum donation.'>
              <InfoIcon style={styles.infoIcon} />
            </Tooltip>
          </span>
          <span style={styles.sectionContent}>
            <ButtonGroup
              containerStyles={styles.paymentButtonGroup}
              options={[
                {
                  title: '$1',
                  value: 100,
                },
                {
                  title: '$2',
                  value: 200,
                },
                {
                  title: '$5',
                  value: 500,
                },
                {
                  title: '$10',
                  value: 1000,
                },
                {
                  title: '$20',
                  value: 2000,
                },
              ]}
              includeCustomCurrencyInput={true}
              selectedValue={_pledgeCents}
              onSelect={(newValue) => {
                setPledgeCentsError(null);
                setPledgeCents(newValue);
              }}
            />
            <span style={styles.formError}>{_pledgeCentsError}</span>
          </span>
        </span>

        <span style={styles.formSection}>
          <span style={styles.sectionHeader}>Maximum total donation
            <Tooltip title='The maximum you will be charged, regardless of how many verses are memorized; once this maximum has been reached, your pledge will not be applied to additional verses memorized.'>
              <InfoIcon style={styles.infoIcon} />
            </Tooltip>
          </span>
          <span style={styles.sectionContent}>
            <ButtonGroup
              containerStyles={styles.paymentButtonGroup}
              options={[ // @TODO: definitely do A/B testing with these amounts
                {
                  title: '$20',
                  value: 2000,
                },
                {
                  title: '$50',
                  value: 5000,
                },
                {
                  title: '$100',
                  value: 10000,
                },
                {
                  title: '$500',
                  value: 50000,
                },
                {
                  title: '$1000',
                  value: 100000,
                },
                // @TODO: custom input/amount
              ]}
              includeCustomCurrencyInput={true}
              selectedValue={_maxDonationCents}
              onSelect={(newValue) => {
                setMaxDonationCentsError(null);
                setMaxDonationCents(newValue);
              }}
            />
            <span style={styles.formError}>{_maxDonationCentsError}</span>
          </span>
        </span>

        {!!(_maxDonationCents && _pledgeCents) &&
          <React.Fragment>
            <span style={styles.formSection}>
              <span style={styles.sectionHeader}>Impact</span>
              <span style={styles.sectionContent}>
                <span>A pledge of <span style={styles.specialText}>{utilities.prettyCurrency(_maxDonationCents)}</span> at <span style={styles.specialText}>{utilities.prettyCurrency(_pledgeCents)}/verse</span> will sponsor <span style={styles.specialText}>{maxVersesSponsored} memorized Bible verses</span>{'!'}</span>
              </span>
            </span>

            <span style={styles.formSection}>
              <span style={styles.sectionHeader}>Payment information
                <Tooltip title='You will not be charged until your specified maximum is reached or until the end of the campaign, whichever comes first.'>{/* @TODO: better word than maxiumum */}
                  <InfoIcon style={styles.infoIcon} />
                </Tooltip>
              </span>
              <span style={styles.sectionContent}>
                {/* @TODO: while loading
                <p>Payment being processed. Please do not navigate from this page or close the dialog.  You will be redirected momentarily.</p>
                <div style={styles.paypalProgressWrapper}>
                  <CircularProgress />
                </div>
                */}
                <div>
                  <PaymentElement />
                  {/* @TODO:
                    one final confirm dialog (or maybe just checkbox) to agree to pay up to specified amount if enough verses are memorized
                  */}
                  <Button
                    style={styles.submitButton}
                    color='secondary'
                    disabled={_isSubmitDisabled}
                    fullWidth={true}
                    variant='contained'
                    onClick={async () => {
                      if (!stripe || !elements) { // @TODO: need this?
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return;
                      }

                      let errorsExist = false;
                      // @TODO: set error if pledge is greater than max donation
                      if (!_emailText) {
                        errorsExist = true;
                        setEmailTextFieldError('Please provide a valid email address.');
                      }
                      if (!_pledgeCents) {
                        errorsExist = true;
                        setPledgeCentsError('Please select a pledge amount per verse memorized.');
                      }
                      if (!_maxDonationCents) {
                        errorsExist = true;
                        setMaxDonationCentsError('Please select a maximum pledge amount.');
                      }
                      if (_pledgeCents < 10) {
                        errorsExist = true;
                        setPledgeCentsError('Minimum pledge is $0.10/verse.');
                      }
                      if (_maxDonationCents < 100) {
                        errorsExist = true;
                        setMaxDonationCentsError('Minimum total is $1.00.');
                      }

                      if (errorsExist) {
                        return;
                      }

                      setIsSubmitDisabled(true);
                      const {error} = await stripe.confirmSetup({
                        // `Elements` instance that was used to create the Payment Element
                        elements,
                        confirmParams: {
                          return_url: `${Globals.webUrl}/organizations/${props.organization.slug}?setup_intent_client_secret=${props.stripeClientSecret}&displayName=${_displayNameText.replace(/\+/gi, '%2B') || 'Anonymous'}&email=${_emailText.replace(/\+/gi, '%2B')}&campaignId=${props.campaign.id}&pledgeCents=${_pledgeCents}&maxDonationCents=${_maxDonationCents}`,
                        },
                      });

                      if (error) {
                        setIsSubmitDisabled(false);
                        // This point will only be reached if there is an immediate error when
                        // confirming the payment. Show error to your customer (for example, payment
                        // details incomplete)
                        setStripeFormError(error.message);
                      } else {
                        // Your customer will be redirected to your `return_url`. For some payment
                        // methods like iDEAL, your customer will be redirected to an intermediate
                        // site first to authorize the payment, then redirected to the `return_url`.
                      }
                    }}
                  >
                    {_isSubmitDisabled ? <CircularProgress /> : 'Submit'}
                  </Button>

                  {_stripeFormError && <p style={styles.formError}>{_stripeFormError}</p>}
                </div>
              </span>
            </span>
          </React.Fragment>
        }
      </React.Fragment>
    );
  };

  return (
    <Dialog
      style={styles.container}
      open={true}
      fullScreen={Globals.isMobile}// @TODO: doesn't look good on low-res monitors
      onClose={() => props.onClose()}
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.fullTitleWrapper}>
          <span style={styles.mainTitle}>Join {props.campaign.name}</span>
          <span style={styles.subTitle}>{props.organization.name}</span>
        </span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        {_renderInnerContent()}
      </DialogContent>
      <DialogActions>
        {_userType &&
          <Button
            color='primary'
            onClick={() => {
              setUserType(null); // @TODO: will need conditionals if there's more than one step left
            }}
          >
            Back
          </Button>
        }
        <Button
          color='primary'
          onClick={() => props.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

JoinDialog.propTypes = {
  campaign: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  stripeClientSecret: PropTypes.string.isRequired,
};

export default JoinDialog;
