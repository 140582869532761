/*
 * Ryan O'Dowd
 * 2021-12-21
 * © Copyright 2021 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  innerContainer: { // @TODO: abstract with home
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: Globals.colors.primaryExtraDark,
    alignItems: 'center',
    width: 800,
    maxWidth: '100%',
    height: '100%',
    margin: 'auto',
    marginBottom: 64,
  },
  innerWithPadding: { // @TODO: abstract with home
    padding: 16,
  },
  searchBarWrapperWeb: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 16,
  },
  searchBarWeb: {
    maxWidth: 325,
  },
  emptyScreenText: { // @TODO: should be in commonStyles?
    color: Globals.colors.gray,
    marginBottom: 20,
    fontSize: 16,
    textAlign: 'center',
  },
  sectionHeader: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontWeight: '800',
    fontSize: 24,
    marginTop: 16,
    marginBottom: 8,
  },
  faqWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  question: {
    color: Globals.colors.primary,
    fontSize: 18,
  },
  faqBody: {
    textAlign: 'left',
  },
  horizontalDivider: {
    backgroundColor: Globals.colors.lightGray,
    width: '100%',
    height: 2,
    margin: 8,
    marginTop: 16,
    alignSelf: 'center',
  },
  otherInfoWrapper: {
    marginBottom: 32,
  },

  paragraph: {
    marginBottom: 8,
  },
  link: {
  },
  bold: {
    fontWeight: '800',
  },
};

export default styles;
