/*
 * Ryan O'Dowd
 * 2019-06-08
 * © Copyright 2019 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import About from '../../containers/About';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ArticleIcon from 'mdi-react/NewspaperVariantOutlineIcon';
// @TODO: import Brightness6Icon from 'mdi-react/Brightness6Icon'; // @TODO: maybe use ThemeLightDarkIcon instead?
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import DownloadLinks from '../DownloadLinks';
// @TODO: import FeedbackChat from '../../containers/FeedbackChat';
// @TODO: import ForumIcon from 'mdi-react/ForumIcon';
import Globals from '../../Globals';
import HeadphonesIcon from 'mdi-react/HeadphonesIcon';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import OakAuth from '../../common/containers/OakAuth';
import PostOutlineIcon from 'mdi-react/PostOutlineIcon';
import PropTypes from 'prop-types';
import {
  logout,
  // @TODO: setLightDarkMode,
} from '../../actions';
import styles from './styles';

const Header = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [_isAuthOpen, setIsAuthOpen] = useState(!!location.state?.isAuthOpen || window.location.pathname === '/finish-email-sign-in');
  const [_redirectUrl, setRedirectUrl] = useState(location.state?.redirectUrl);
  // @TODO: const [_feedbackChatIsOpen, setFeedbackChatIsOpen] = useState(false);
  const [_aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);
  const [_moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [_accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [_profilePictureUrl, setProfilePictureUrl] = useState('/img/avatar.png');

  const user = useSelector((state) => state.oakUser);
  const organizationCampaigns = useSelector((state) => state.organizationCampaigns);
  const myClassroom = useSelector((state) => Object.values(state.classrooms).pop()); // @TODO: what if user has more than one classroom?

  useEffect(() => {
    // we've set component state variables with data from location.state, so can delete those now
    window.history.replaceState({}, '');
  }, [location.state]);

  useEffect(() => {
    if (user) {
      fetch(`${Globals.apiUrl}/users/${user.username}/profile_picture_url`).then(async (response) => { // @TODO: cache this
        if (response.status === 200) {
          const url = await response.text();
          setProfilePictureUrl(url);
        }
      });
    }
  }, [user]);

  return (
    <div style={styles.container}>
      <div style={styles.headerInner}>
        <a
          style={styles.logoAndName}
          href='/'
        >
          <img style={styles.logo} src='/img/logo.png' alt='logo' />
          {!Globals.isMobile && <h1 style={styles.appTitle}>Wrinkly</h1>}
        </a>
        {props.navigationTabs &&
          <div style={{...styles.navigationTabsWrapper, ...(Globals.isMobile ? styles.navigationTabsWrapperMobile : {})}}>
            {props.navigationTabs}
          </div>
        }
        <div style={styles.buttons}>
          {!user
            ? (
              <Button
                style={styles.loginButton}
                onClick={() => setIsAuthOpen(true)}
              >
                Sign in
              </Button>
            ) : (
              <React.Fragment>
                {/* @TODO:
                <Tooltip title='Feedback chat'>
                  <IconButton
                    onClick={() => {
                      setFeedbackChatIsOpen((curr) => !curr);
                    }}
                  >
                    <ForumIcon color='white' />
                  </IconButton>
                </Tooltip>
                */}
                <span>
                  <Tooltip title='Account'>
                    <IconButton
                      aria-controls='account-options-menu'
                      aria-haspopup='true'
                      onClick={(event) => setAccountMenuAnchorEl(event.currentTarget)}
                    >
                      <AccountCircleIcon color='white' />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id='account-options-menu'
                    anchorEl={_accountMenuAnchorEl}
                    keepMounted
                    open={!!_accountMenuAnchorEl}
                    onClose={() => setAccountMenuAnchorEl(null)}
                  >
                    <MenuItem
                      key='account'
                      onClick={() => {}}
                    >
                      <img style={styles.userImage} src={_profilePictureUrl} alt='profile' />
                      <span style={styles.userInfoWrapper}>
                        <span>{user.display_name}</span>
                        <span style={styles.usernameText}>{user.username}</span>
                        <span style={styles.emailText}><em>{user.auth_provider_info}</em></span>
                      </span>
                    </MenuItem>
                    {!!myClassroom &&
                      <MenuItem
                        key='classroom'
                        component={Link}
                        to={`/schools/${myClassroom.school.id}/classrooms/${myClassroom.id}`}
                      >
                        My classroom
                      </MenuItem>
                    }
                    {!!organizationCampaigns.length &&
                      <MenuItem
                        key='campaigns'
                        component={Link}
                        to='/campaigns'
                      >
                        My campaigns
                      </MenuItem>
                    }
                    <MenuItem
                      key='logout'
                      onClick={() => {
                        dispatch(logout());
                        setAccountMenuAnchorEl(null);
                      }}
                    >
                      Sign out
                    </MenuItem>
                    {Globals.adminAuthIds.includes(user.auth_id) &&
                      <span>
                        <Divider />
                        <div style={styles.menuSectionHeader}>Admin</div>
                        <MenuItem
                          key='admin'
                          style={styles.nested(1)}
                          component={Link}
                          to='/admin'
                        >{/* @TODO: maybe replace with https://github.com/webzep/mui-nested-menu someday (same with multiple classrooms) */}
                          Admin dashboard
                        </MenuItem>
                        <MenuItem
                          key='oakwood_classroom'
                          style={styles.nested(1)}
                          component={Link}
                          to='/schools/1/classrooms/1'
                        >
                          Oakwood classroom
                        </MenuItem>
                      </span>
                    }
                  </Menu>
                </span>
                {/* @TODO:
                {_feedbackChatIsOpen && <FeedbackChat onClose={() => setFeedbackChatIsOpen(false)} />}
                */}
              </React.Fragment>
            )
          }
          <Tooltip title='Help'>
            <IconButton
              component={Link}
              to='/help'
            >
              <HelpCircleIcon color='white' />
            </IconButton>
          </Tooltip>
          <span>
            <Tooltip title='More options'>
              <IconButton
                aria-controls='more-options-menu'
                aria-haspopup='true'
                onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}
              >
                <DotsVerticalIcon color='white' />
              </IconButton>
            </Tooltip>
            <Menu
              id='more-options-menu'
              anchorEl={_moreMenuAnchorEl}
              open={!!_moreMenuAnchorEl}
              onClose={() => setMoreMenuAnchorEl(null)}
            >
              {/* @TODO:
              <MenuItem
                key='lightDarkMode'
                value='toggleLightDarkMode'
                onClick={() => {
                  // @TODO: props.setLightDarkMode(props.lightDarkMode === 'dark' ? 'light' : 'dark');
                  setMoreMenuAnchorEl(null);
                }}
              >
                <Brightness6Icon /><span style={styles.iconMenuItemText}>{' Toggle light/dark mode'}</span>
              </MenuItem>
              */}
              <MenuItem
                key='about'
                value='about'
                onClick={() => {
                  setAboutDialogIsOpen(true);
                  setMoreMenuAnchorEl(null);
                }}
              >
                <InformationIcon /><span style={styles.iconMenuItemText}>{' About'}</span>
              </MenuItem>
              <MenuItem
                key='articles'
                value='articles'
                onClick={() => {
                  navigate('/articles');
                  setMoreMenuAnchorEl(null);
                }}
              >
                <PostOutlineIcon /><span style={styles.iconMenuItemText}>{' Article'}</span>
              </MenuItem>
              <MenuItem
                key='podcast'
                value='podcast'
                onClick={() => {
                  window.open('https://pca.st/8fgqsyos', '_blank');
                  setMoreMenuAnchorEl(null);
                }}
              >
                <HeadphonesIcon /><span style={styles.iconMenuItemText}>{' Podcast'}</span>
              </MenuItem>
              <MenuItem
                key='faith.tools'
                value='faith.tools'
                onClick={() => {
                  window.open('https://faith.tools/posts/a-peek-behind-the-curtain-wrinkly-scripture-memorization/', '_blank');
                  setMoreMenuAnchorEl(null);
                }}
              >
                <ArticleIcon /><span style={styles.iconMenuItemText}>{' faith.tools'}</span>
              </MenuItem>
              <MenuItem
                key='download'
                value='download'
              >
                <DownloadLinks />
              </MenuItem>
            </Menu>
          </span>
          <OakAuth
            appName='Wrinkly'
            onClose={() => setIsAuthOpen(false)}
            onSuccess={() => {
              if (_redirectUrl) {
                setTimeout(() => {
                  // @TODO: seems like there's a race condition for setting the loggged-in user?
                  navigate(_redirectUrl);
                }, 100);
              }
            }}
            isOpen={_isAuthOpen}
          />
        </div>
      </div>
      {_aboutDialogIsOpen && <About onClose={() => setAboutDialogIsOpen(false)} />}
    </div>
  );
};

Header.propTypes = {
  // @TODO: lightDarkMode: PropTypes.string.isRequired,
  // @TODO: setLightDarkMode: PropTypes.func.isRequired,

  centerComponent: PropTypes.node,
  navigationTabs: PropTypes.node,
  redirectUrl: PropTypes.string,
};

export default Header;
