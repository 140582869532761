/*
 * Ryan O'Dowd
 * 2024-07-15
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Alert from '../../../common/components/OakAlert';
import OakTable from '../../../common/components/OakTable';
import PropTypes from 'prop-types';
import {
  apiUpdateClassroomGoal,
} from '../../../actions';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const PublishButton = (props) => {
  const dispatch = useDispatch();

  const activeClassroomStudents = useSelector((state) => state.classroomStudents[props.classroomId] || []).filter((student) => student.status === 'active');

  const [_isGoalAssignmentDialogOpen, setIsGoalAssignmentDialogOpen] = useState(false);
  const [_userIdsToAssign, setUserIdsToAssign] = useState(activeClassroomStudents.map((cs) => cs.user.id));

  const studentTableRows = activeClassroomStudents.map((cs) => {
    return [ // @TODO: update ids
      {
        id: `${cs.id}_display_name`,
        content: (
          <span style={styles.checkboxAndName}>
            {/* @TODO: default all to be checked */}
            <Checkbox
              checked={_userIdsToAssign.includes(cs.user.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setUserIdsToAssign((curr) => [...curr, cs.user.id]);
                } else {
                  setUserIdsToAssign((curr) => curr.filter((id) => id !== cs.user.id));
                }
              }}
              sx={{
                color: cs.color,
                '&.Mui-checked': {
                  color: cs.color,
                },
              }}
            />
            {cs.user ? cs.user.display_name : cs.display_name}{/* @TODO: if user exists, show cs.display_name on hover for convenience? */}
          </span>
        ),
      }, // @TODO: maybe surround with join date (or invitation date if pending)
      {id: `${cs.id}_date_invited`, content: <Tooltip title={dateFnsFormat(cs.created_at, 'MMM d, yyyy, h:mm a')}>{dateFnsFormat(cs.created_at, 'MMM d')}</Tooltip>},
      {id: `${cs.id}_date_joined`, content: cs.date_joined ? <Tooltip title={dateFnsFormat(cs.date_joined, 'MMM d, yyyy, h:mm a')}>{dateFnsFormat(cs.date_joined, 'MMM d')}</Tooltip> : '-'},
    ];
  });

  return (
    <React.Fragment>
      <Button
        color='secondary'
        variant='contained'
        onClick={() => {
          setIsGoalAssignmentDialogOpen(true);
        }}
      >
        Publish
      </Button>
      <Dialog
        open={_isGoalAssignmentDialogOpen}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        // @TODO: fullScreen={Globals.isMobile}
        onClose={() => setIsGoalAssignmentDialogOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Assign goal to students
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          <Alert
            type='warning'
            text='Ready to publish this goal?  Once published, assignments will be locked, and students will be granted access.  Most assignment settings will remain editable, but passages will be locked.'
          />
          <br />
          <OakTable// @TODO: rows shouldn't have pointer on hover unless they're selectable
            // @TODO: shouldn't be accordion
            tableTitle='Active students'
            open={true}// @TODO: default to false, but have badge for notifications if there are students to approve, for example
            headerRowData={[
              // @TODO: check/uncheck all
              'Name',
              'Date invited',
              'Date joined',
            ]}// @TODO: some of these should be named better and/or have tooltips
            rowData={studentTableRows}
            // @TODO: onRowPress={(row) => setSelectedStudentId(+row[0].id.split('_')[0])}// @TODO: way too hacky
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            autoFocus={false}
            onClick={() => setIsGoalAssignmentDialogOpen(false)}
          >
            Close
          </Button>
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            onClick={() => dispatch(apiUpdateClassroomGoal(props.classroomId, props.goalId, {status: 'published'}, _userIdsToAssign))}
          >
            Publish{_userIdsToAssign.length ? ' and assign' : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

PublishButton.propTypes = {
  classroomId: PropTypes.number.isRequired,
  goalId: PropTypes.number.isRequired,
};

export default PublishButton;
