/**
 * Ryan O'Dowd
 * 2024-07-15
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  checkboxAndName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

export default styles;
