/*
 * Ryan O'Dowd
 * 2019-05-14
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  imagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
  },
  image: {
    borderRadius: 32,
    borderStyle: 'solid',
    borderWidth: 5,
    borderColor: Globals.colors.primaryLight,
    width: 205,
    height: 444,
    margin: 16,
  },
  missionAndVisionWrapper: {
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: Globals.colors.primaryLight,
    borderRadius: 16,
    backgroundColor: Globals.colors.primaryDark,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: 16,
    fontSize: '1.2em',
  },
  accentTitle: {
    color: Globals.colors.accent,
    fontWeight: 'bold',
  },
  statsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  statText: {
    fontWeight: 'bold',
    color: Globals.colors.accent,
  },
  downloadLinks: {
    display: 'flex',
  },

  contactWrapper: {
    minWidth: '50%',
    flex: 1,
  },
  contactAndDownloadWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: window.innerWidth > Globals.mediaQuerySizes.sm ? 'row' : 'column', // @TODO: not responsive...listen to changes to width or use mui grid
    width: '100%',
    justifyContent: 'space-between',
  },
  downloadCtaWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },
};

export default styles;
