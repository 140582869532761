/*
 * Ryan O'Dowd
 * 2024-01-22
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';
// @TODO: remove unused styles

const styles = {
  badgeText: {
    color: Globals.colors.white,
  },
  errorTextWrapper: {
    paddingTop: 32,
  },
  errorText: {
    color: Globals.colors.primaryExtraDark,
    margin: 16,
    fontSize: 20,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  noVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  h1: {
    margin: 0,
    textAlign: 'center',
    color: Globals.colors.primaryExtraDark,
  },
  titleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  logoLink: {
    position: 'absolute',
    top: -24, // @TODO: dynamic
    right: 0,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 80,
    right: 0,
  },
  classroomName: {
    fontWeight: 'bold',
  },
  campaignWrapper: {
    marginLeft: 16,
  },
  campaignSeparator: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Globals.colors.primaryExtraLight,
    marginTop: 32,
  },
  pastCampaignSeparator: {
    borderTopColor: Globals.colors.accentDark,
  },
  campaignName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  campaignDate: {
    fontSize: 14,
    marginTop: -16,
    color: Globals.colors.accent, // @TODO: not very visible on light backgrounds
  },
  pastCampaignDate: {
    color: Globals.colors.primaryDark,
  },
  learnMoreLink: {
    fontSize: '0.9em',
    color: Globals.colors.accentLight, // @TODO: not very visible on light backgrounds
  },
  pastLearnMoreLink: {
    color: Globals.colors.primary,
  },
  currentCampaignsTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  goalsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  sectionTitleAction: {
    marginRight: 8,
  },

  chip: {
    marginBottom: 8,
  },

  joinCode: {
    padding: 6,
    backgroundColor: Globals.colors.accent,
    color: Globals.colors.primaryExtraDark,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: '600',
  },
  dangerous: {
    color: Globals.colors.alert,
  },

  // @MARK: invite dialog
  dialogContent: {
    // @TODO:
    width: 500,
  },
  dialogSubSectionHeaderText: { // @TODO: still used?
    color: Globals.colors.primaryExtraDark,
    marginBottom: 0,
    paddingBottom: 0,
  },
};

export default styles;
