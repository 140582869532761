/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const skeletonDefaults = { // @TODO: abstract with YearInReview/styles.native.js
  height: 16,
  marginBottom: 4,
  opacity: 0.4,
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  verseHeading: { // @TODO: update styles
    // @TODO: $scale: '$globalScale',
    color: Globals.colors.primaryExtraDark,
    paddingBottom: 8,
    fontSize: 18,
    fontWeight: '900',
    fontFamily: Globals.fontFamilies.secondary, // @TODO: not working...might need to include fonts in public/index.html
  },
  verseWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    paddingTop: 0,
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
  },
  verseText: {
    flex: 1,
    color: Globals.colors.primaryExtraDark,
  },
  wrinkleBadgeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 4,
  },
  verseWrapperNoFormatting: {
    paddingBottom: 8,
  },
  selectedVerseWrapper: {
    borderLeftColor: Globals.colors.qqq,
  },
  selectedVerseNumber: {
    color: Globals.colors.qqq,
    // @TODO: fontWeight: Platform.OS === 'ios' ? '900' : 'normal',
  },
  selectedVerseText: {
    textDecorationLine: 'underline',
    color: Globals.colors.qqq,
  },
  verseNumber: { // @TODO: common
    // @TODO: $scale: '$globalScale',
    fontSize: 12,
    position: 'relative',
    top: -4, // @TODO: shouldn't be affected by $globalScale
    // @TODO: color: isLightBackground ? Globals.colors.gray : Globals.colors.lightGray,
  },
  typedVerse: {
    // @TODO: $scale: '$globalScale',
    // @TODO: display: 'flex',
    // @TODO: flexDirection: 'row',
    // @TODO: flexWrap: 'wrap',
    fontSize: 16, // @TODO: toggleable
    color: Globals.colors.primaryDark,
    opacity: 1,
    whiteSpace: 'pre-wrap',
  },
  completedVerseWrapper: {
    borderRightColor: Globals.colors.accent,
  },
  completedVerseText: {
    color: Globals.colors.accent,
  },
  inProgressVerseWrapper: {
    borderRightColor: Globals.colors.complete,
  },
  inProgressVerseText: {
    color: Globals.colors.complete, // @TODO: this needs to be updated in light mode
  },
  unstartedVerseWrapper: {
    borderRightColor: Globals.colors.lightGray, // @TODO: prettier color
  },
  unstartedVerseText: {
  },
  footnoteIndicatorWrapper: {
    display: 'flex',
    flexShrink: 1,
  },
  footnoteIndicator: { // @TODO: combine with verse number styles
    // @TODO: $scale: '$globalScale',
    position: 'relative',
    top: -4, // @TODO: shouldn't be affected by $globalScale
    color: Globals.colors.accent, // @TODO: update
    fontSize: 12,
  },

  skeletonWrapper: {
    paddingHorizontal: 16,
  },
  skeletonHeading: {
    ...skeletonDefaults,
    height: 24,
    marginBottom: 12,
    width: '60%',
  },
  skeletonVerseLine1: { // @TODO: abstract with YearInReview/styles.native.js
    ...skeletonDefaults,
    width: '95%',
    alignSelf: 'flex-end',
  },
  skeletonVerseLine2: {
    ...skeletonDefaults,
  },
  skeletonVerseLine3: {
    ...skeletonDefaults,
    width: '75%',
    marginBottom: 12,
  },

  // @MARK: numbers only styles
  numsOnlyVerseNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    backgroundColor: Globals.colors.primaryDark,
    borderWidth: 4,
    borderColor: Globals.colors.primaryLight,
    borderRadius: 8,
    margin: 4,
    padding: 0,
  },
  verseNumberText: {
    color: Globals.colors.primaryLight,
    fontSize: 14,
    fontWeight: 'bold',
  },
  numsOnlySelectedVerse: {
    borderColor: Globals.colors.qqq,
  },
  numsOnlyCompletedVerse: {
    borderColor: Globals.colors.accent,
  },
  numsOnlyCompletedVerseText: {
    color: Globals.colors.accent,
  },
  numsOnlyInProgressVerse: {
    borderColor: Globals.colors.complete,
  },
  numsOnlyInProgressVerseText: {
    color: Globals.colors.complete,
  },
  numsOnlyUnstartedVerse: {
    borderColor: Globals.colors.lightGray,
  },
  numsOnlyUnstartedVerseText: {
    color: Globals.colors.lightGray,
  },
};

export default styles;
