/*
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  createModule,
  deleteModule,
  updateModule,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import DateRange from '../DateRange';
import Globals from '../../../Globals';
import PropTypes from 'prop-types';
import styles from './styles';

const EditModuleDialog = (props) => {
  const dispatch = useDispatch();

  const [_moduleName, setModuleName] = useState(props.isNew ? '' : props.module.name);
  // @TODO: const [_status, setStatus] = useState(props.module?.status || 'draft');

  const [_dateAvailable, setDateAvailable] = useState(props.module?.date_available ? new Date(props.module.date_available) : null);
  const [_dateDeadline, setDateDeadline] = useState(props.module?.date_deadline ? new Date(props.module.date_deadline) : null);

  const user = useSelector((state) => state.oakUser);
  const isAdmin = Globals.adminAuthIds.includes(user.auth_id);

  const getMinDateAvailable = () => { // @TODO: abstract with EditModuleDialog
    const goalDateAvailable = props.goal.date_available ? new Date(props.goal.date_available) : null;

    return goalDateAvailable;
  };

  const getMaxDateAvailable = () => { // @TODO: abstract with EditModuleDialog
    const goalDateDeadline = props.goal.date_deadline ? new Date(props.goal.date_deadline) : null;
    const moduleDateDeadline = _dateDeadline;

    return new Date(Math.max(...[goalDateDeadline?.getTime(), moduleDateDeadline?.getTime()].filter(Boolean)));
  };

  const getMinDateDeadline = () => { // @TODO: abstract with EditAssignmentDialog
    const moduleDateAvailable = _dateAvailable;
    const goalDateAvailable = props.goal.date_available ? new Date(props.goal.date_available) : null;

    return new Date(Math.max(...[moduleDateAvailable?.getTime(), goalDateAvailable?.getTime()].filter(Boolean)));
  };

  const getMaxDateDeadline = () => { // @TODO: abstract with EditModuleDialog
    const goalDateDeadline = props.goal.date_deadline ? new Date(props.goal.date_deadline) : null;

    return goalDateDeadline;
  };

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>{props.isNew ? 'Create new module' : 'Edit module'}</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <span style={styles.headerText}>Module name</span>
        <span style={styles.nameWrapper}>
          <TextField
            value={_moduleName}
            autoFocus={true}
            fullWidth
            onChange={(e) => setModuleName(e.target.value)}
            placeholder='e.g. "Jesus Feeds the 5000"'
            variant='standard'
          />
        </span>
        <DateRange
          dateAvailable={_dateAvailable}
          dateDeadline={_dateDeadline}
          setDateAvailable={(newValue) => setDateAvailable(newValue)}
          setDateDeadline={(newValue) => setDateDeadline(newValue)}
          minDateAvailable={getMinDateAvailable()}
          maxDateAvailable={getMaxDateAvailable()}
          minDateDeadline={getMinDateDeadline()}
          maxDateDeadline={getMaxDateDeadline()}
        />
        {/* @TODO:
        <FormControl fullWidth>
          <InputLabel id='status'>Module status</InputLabel>
          <Select
            labelId='status'
            value={_status}
            label='Module status'
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value='draft'>Draft</MenuItem>{/* @TODO: only an option if new goal? /}
            <MenuItem value='published'>Published</MenuItem>
            {/* @TODO:
            <MenuItem value='concluded'>Concluded</MenuItem>{/* @TODO: option to autoconcluded after due date /}
            /}
          </Select>
        </FormControl>
        <p>TODO: duplicate module (save to this goal or another goal)</p>
        */}
      </DialogContent>
      <DialogActions>
        {!props.isNew && props.classroomGoal.status !== 'published' &&
          <Button
            color='error'
            onClick={() => {
              // @TODO: confirm with alert after letting teacher know how permanent this is...show how many kids have started (maybe not an option if progress has been made)
              if (window.confirm(`Are you sure you want to delete this module?${props.module.assignments.length ? '  All assignments in this module will be deleted as well.' : ''}`)) { // @TODO: don't need to mention about sub assignments if there are none // @TODO: replace with prettier confirm
                dispatch(deleteModule(props.classroomGoal.classroom_id, props.goal.id, props.module.id));
              }
              props.onClose();
            }}
            variant='text'
          >
            Delete
          </Button>
        }
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        {/* @TODO: button to duplicate module */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            // @TODO: implement back-end call
            if (props.isNew) {
              // @TODO: make sure all required fields are filled (name not required if admin)
              dispatch(createModule(props.classroomGoal.classroom_id, props.goal.id, _moduleName, _dateAvailable, _dateDeadline, 'published')); // @TODO: this should be only fields that have been updated (compare state variables with props.goal)
              // @TODO: save new module to goal.module_ids
            } else {
              dispatch(updateModule(props.classroomGoal.classroom_id, props.goal.id, props.module.id, _moduleName, _dateAvailable, _dateDeadline, 'published')); // @TODO: this should be only fields that have been updated (compare state variables with props.goal)
            }
            props.onClose(); // @TODO: wait for back-end call to complete?
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditModuleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  module: PropTypes.object.isRequired, // @TODO: shape
  goal: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape

  isNew: PropTypes.bool,
};

export default EditModuleDialog;
