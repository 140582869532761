/**
 * Ryan O'Dowd
 * 2024-05-13
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    color: Globals.colors.primaryDark,
    padding: 8,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  bannerText: {
    fontSize: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  icon: {
    flexShrink: 1,
    fontSize: 24,
  },
};

export default styles;
