/*
 * Ryan O'Dowd
 * 2024-05-13
 *
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
import {
  format as dateFnsFormat,
} from 'date-fns';

const Globals = {
  draftText: `This is a draft and won't be available to students until it is published.  If you are ready to publish it, click the "Publish" button.`,
  publishedText: (datePublished) => `Published on ${dateFnsFormat(new Date(datePublished), 'MMMM d, yyyy')} and currently available to students.`,
};

export default Globals;
