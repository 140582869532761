/*
 * Ryan O'Dowd
 * 2021-01-03
 * © Copyright 2020 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const USER_IMAGE_HEIGHT = 64;

const styles = {
  dialog: {
    textAlign: 'left',
  },
  aboutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  firstHeader: {
    marginTop: 0,
  },
  headerText: {
    color: Globals.colors.primary,
    marginBottom: 4,
  },
  aboutText: {
    marginTop: 0,
  },
  imageTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  leftBorder: {
    borderLeft: `4px solid ${Globals.colors.primary}`,
    float: 'left',
  },
  rightBorder: {
    borderRight: `4px solid ${Globals.colors.primary}`,
    float: 'right',
  },
  foo: {
    display: 'initial',
    marginBottom: 8,
  },
  logo: {
    width: 100,
    height: 100,
    alignSelf: 'flex-start',
    padding: 8,
    paddingRight: 16,
    paddingLeft: 16,
  },
  userImageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  developerText: {
    display: 'flex',
    alignItems: 'center',
  },
  userImage: {
    width: USER_IMAGE_HEIGHT,
    height: USER_IMAGE_HEIGHT,
    borderRadius: USER_IMAGE_HEIGHT / 2,
    overflow: 'hidden',
    margin: 8,
  },
  otherInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  accentTitle: {
    color: Globals.colors.primaryDark,
    fontWeight: '800',
    paddingLeft: 16,
  },
  statText: {
    color: Globals.colors.primary,
    fontWeight: '800',
  },
};

export default styles;
