/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, {
  useEffect,
  useMemo,
} from 'react';
// @TODO: import LicensingInfo from '../../components/LicensingInfo';
import PropTypes from 'prop-types';
// @TODO: import SelectionMenu from './SelectionMenu';
import Verse from './Verse';
import styles from './styles';
import utilities from '../../utilities';

const PassageDisplay = (_p) => {
  const props = useMemo(() => {
    return {
      selectedVerseNums: [],
      ..._p,
    };
  }, [_p]);

  const [_selectedVerseNums, onVersePress, clearVerseSelection, selectAllVerses] = utilities.useSelectVerses(props.selectedVerseNums);

  useEffect(() => {
    if (props.onUpdateVerseSelection && _selectedVerseNums.length !== props.selectedVerseNums.length) {
      props.onUpdateVerseSelection(_selectedVerseNums);
    }

    if (props.onSelectionModeToggle) {
      props.onSelectionModeToggle(!!_selectedVerseNums.length);
    }
  }, [props, _selectedVerseNums]);

  const allVerses = utilities.useGetVersesForPassages(props.passages); // @TODO: this doesn't get recalculated when Bible is deleted locally (edge case, but something to consider)
  const passagesVerses = [];
  props.passages.forEach((p) => {
    passagesVerses.push(...(allVerses[p.id] || []));
  });

  return (
    <div style={styles.container}>
      {!props.isPressDisabled &&
        <FormControlLabel
          control={
            <Checkbox
              checked={props.allVersesSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  selectAllVerses(props.passages[0].end_verse_number);
                } else {
                  clearVerseSelection();
                }
              }}
            />
          }
          label='Select all verses'
          // @TODO: tooltip helper
        />
      }
      <div
        ref={props.scrollRef}
        style={{
          ...styles.mainListContainer,
          ...(props.hasSolidBackground ? styles.verseWrapperBackgroundColor : {}),
          ...(props.isReadFeature ? styles.extraBottomPadding : {}), // @TODO: not a fan of hardcoding SelectionMenu's (approximate) height
          ...(props.mainListContainerStyle || {}),
        }}
      >
        <div style={props.verseNumbersOnly ? styles.numsOnlyVersesWrapper : styles.versesWrapper}>{/* @TODO: also have buttons for next/previous chapter...have persistent dash for this? */}
          {passagesVerses.map((verse) => {
            const verseNum = +verse.verseApiId.split('.').pop();
            const isVerseSelected = _selectedVerseNums.includes(verseNum);
            return ( // @TODO: would be nice if these verses wrapped, but worry about that at another time
              <div key={verse.verseApiId}>
                <Verse
                  isLoading={verse.isLoading}
                  isSelected={isVerseSelected}
                  isSelectionModeOn={!!_selectedVerseNums.length}
                  verseApiId={verse.verseApiId}
                  translationId={verse.translationId}
                  showFormatting={props.showFormatting}
                  showFootnotes={props.showFootnotes}
                  showHeadings={props.showHeadings}
                  doesTranslationAffectWrinkleStatus={props.doesTranslationAffectWrinkleStatus}
                  verseNumbersOnly={props.verseNumbersOnly}
                  textColorOverride={props.textColorOverride}
                  showWrinkleBadge={props.showWrinkleBadges}
                  onPressVerse={props.isPressDisabled ? null : () => onVersePress(verseNum)}
                  skipFetch={true}// skip fetching of individual verses because this component (PassageDisplay) is already doing a fetch for the verse(s)
                  numsOnlyStyle={props.numsOnlyStyle}
                  verseTextStyles={props.verseTextStyles}
                />
              </div>
            );
          })}
        </div>
        {/* @TODO:
        {!props.hideLicensing &&
          <div style={styles.licenseBuffer}>
            <LicensingInfo translationId={props.passages[0].translation_id} />{/* @TODO: only works because licensing is displayed only for PassageDisplays that pass in a single passage /}
          </div>
        }
        */}
      </div>
    </div>
  );
};

PassageDisplay.propTypes = {
  passages: PropTypes.array.isRequired, // @TODO: not sure if this should be passed or not
  showFormatting: PropTypes.bool.isRequired,
  showFootnotes: PropTypes.bool.isRequired,
  showHeadings: PropTypes.bool.isRequired,
  doesTranslationAffectWrinkleStatus: PropTypes.bool.isRequired,
  showWrinkleBadges: PropTypes.bool.isRequired,

  hideLicensing: PropTypes.bool,
  scrollRef: PropTypes.object,
  hideCompare: PropTypes.bool,
  hideAddToGoal: PropTypes.bool,
  hideTest: PropTypes.bool,
  hideLesson: PropTypes.bool,
  hideNumbersTextToggle: PropTypes.bool,
  alwaysShowSelectionMenu: PropTypes.bool,
  isReadFeature: PropTypes.bool, // @TODO: replace this...too much coupling with business logic
  isPressDisabled: PropTypes.bool,
  verseNumbersOnly: PropTypes.bool,
  onUpdateVerseSelection: PropTypes.func,
  textColorOverride: PropTypes.string,
  bottomBorderColorOverride: PropTypes.string,
  onSelectionModeToggle: PropTypes.func,
  mainListContainerStyle: PropTypes.object,
  numsOnlyStyle: PropTypes.object,
  verseTextStyles: PropTypes.object,
  selectedVerseNums: PropTypes.arrayOf(PropTypes.number),
  allVersesSelected: PropTypes.bool,
};

export default PassageDisplay;
