/*
 * Ryan O'Dowd
 * 2024-05-17
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    // @TODO: marginBottom: 64,
  },
  headerText: { // @TODO: abstract with edit dialogs
    display: 'flex',
    fontSize: 16,
    fontFamily: Globals.fontFamilies.secondary,
    fontWeight: 'bold',
    color: Globals.colors.primaryDark,
    marginTop: 32,
  },
  infoButton: {
    fontSize: 20,
    marginLeft: 8,
    color: Globals.colors.primary,
  },
  subHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 300,
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  errorText: {
    color: Globals.colors.alert,
  },

  // @TODO: combine with EditGoalDialog/styles.web.js
  adminOptionsWrapper: {
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: Globals.colors.primaryDark,
    borderRadius: 8,
    backgroundColor: Globals.colors.primaryExtraLight,
    padding: 12,
  },
  adminOptionsInner: {
    padding: 12,
  },
};

export default styles;
