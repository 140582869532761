/**
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from './Globals';

const styles = {
  searchBarWrapper: {
    display: 'flex',
    width: 400,
    maxWidth: '100%',
    // @TODO: paddingTop: 8,
    paddingLeft: 16,
    // @TODO: paddingBottom: 8,
    paddingRight: 16,
    // @TODO: marginBottom: 32,
  },
  searchBar: {
    display: 'flex',
    // @TODO: height: 40, // @TODO: maybe this is good for help component only
    fontFamily: Globals.fontFamilies.primary,
  },

  // @TODO: shouldn't need most of the popover styles
  popover: {
    // @TODO: backgroundColor: isDarkMode ? Globals.colors.completeBgDark : Globals.colors.white,
    maxWidth: 300,
    borderRadius: 12,
    borderWidth: 1,
    borderBottomWidth: 1,
    // @TODO: borderColor: isDarkMode ? Globals.colors.white : Globals.colors.primary,
    // @TODO: borderBottomColor: isDarkMode ? Globals.colors.white : Globals.colors.primary,
    // @TODO: would prefer the border to extend around the arrow as well...this prop was removed in v5 of react-native-popover-view
  },
  popoverFullWidth: {
    // @TODO: width: Math.min(800, Dimensions.get('window').width - 16), // @TODO: does this look good on all device sizes?  @TODO: don't hardcode 800 but make smaller than window width (or max width on desktop)
  },
  popoverTitle: {
    // @TODO: $scale: '$globalScale',
    fontSize: 14,
    // @TODO: fontWeight: Platform.OS === 'ios' ? '900' : 'normal',
    // @TODO: color: isDarkMode ? Globals.colors.primaryExtraLight : Globals.colors.primaryExtraDark,
  },
  popoverTitleLarge: {
    // @TODO: $scale: '$globalScale',
    fontSize: 19,
  },
  popoverText: {
    // @TODO: $scale: '$globalScale',
    fontSize: 12,
    // @TODO: color: isDarkMode ? Globals.colors.primaryExtraLight : Globals.colors.primaryExtraDark,
    fontStyle: 'italic',
    marginTop: 8,
  },
  popoverTextLarge: {
    // @TODO: $scale: '$globalScale',
    fontSize: 16,
  },
  popoverButton: {
    marginTop: 8,
  },
};

export default styles;
