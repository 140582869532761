/*
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Accordion,
  AccordionSummary,
  Button,
  Chip,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  apiUpdateGoal,
  fetchBookNames,
  fetchBooks,
  fetchChapters,
  fetchClassroomGoals,
  fetchGoals,
  fetchMinigames,
  // @TODO: fetchSchool,
  fetchTags,
  fetchTranslations,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Alert from '../../common/components/OakAlert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClassroomGoalConstants from './Globals';
import {
  DndProvider,
} from 'react-dnd';
import EditGoalDialog from './EditGoalDialog';
import EditIcon from '@mui/icons-material/Edit';
import EditModuleDialog from './EditModuleDialog';
import Globals from '../../Globals';
import {
  HTML5Backend,
} from 'react-dnd-html5-backend';
import Module from './Module';
import PropTypes from 'prop-types';
import PublishButton from './PublishButton';
// @TODO: import StudentProgressTable from './StudentProgressTable';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';
import update from 'immutability-helper';
import utilities from '../../utilities';

const ClassroomGoal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_isEditGoalDialogOpen, setIsEditGoalDialogOpen] = useState(false);
  const [_isNewModuleDialogOpen, setIsNewModuleDialogOpen] = useState(false);

  const {
    schoolId, // @TODO: maybe this should be an id instead
    classroomId,
    classroomGoalId,
  } = useParams();

  const classroomGoal = useSelector((state) => {
    return state.classroomGoalMetadata[classroomGoalId];
  });
  const goal = useSelector((state) => state.goals[classroomGoal.goal_id]);

  const isAdmin = useSelector((state) => Globals.adminAuthIds.includes(state.oakUser.auth_id));

  useEffect(() => { // @TODO: this should go in App and be called coniditionally if user is logged in
    // @TODO: dispatch(fetchSchool(schoolId));
    dispatch(fetchBooks());
    dispatch(fetchChapters());
    dispatch(fetchTranslations());
    dispatch(fetchBookNames());
    // @TODO: dispatch(fetchGoals());
    dispatch(fetchClassroomGoals(classroomId));
    dispatch(fetchMinigames());
    dispatch(fetchTags());
  }, [dispatch, schoolId, classroomId]);

  const [_modules, setModules] = useState(goal.modules);
  useEffect(() => {
    setModules(goal.modules);
  }, [goal.modules]);

  const moveModule = useCallback((dragIndex, hoverIndex) => { // @TODO: duplicate this for modules
    setModules((prevModules) =>
      update(prevModules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevModules[dragIndex]],
        ],
      }),
    );
  }, []);

  /* @TODO:
  const moveAssignment = useCallback((dragIndex, hoverIndex) => { // @TODO: duplicate this for modules
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, []);
  */

  // @TODO: instead of edit buttons opening new dialogs, maybe just toggle editability of each component in-line
  // @TODO: show warnings at top of whole page if there are any goals, modules, or assignments that are still marked as a draft
  return (
    <WebPageStub
      hideTabs={true}// @TODO: instead of hiding tabs, should we show different tabs for teacher dashboard? i think we should consider it once this single page becomes too complex
    >

      <WebsiteSection
        style={styles.noVerticalPadding}
        isDarkBackground={false}
      >
        <Button
          color='primary'
          variant='text'
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/schools/${schoolId}/classrooms/${classroomId}`)}
        >
          Back to classroom
        </Button>
      </WebsiteSection>

      {/* @TODO:
      {props.classroomGoal.status === 'published' &&
        <WebsiteSection// @TODO: move this to its own component
          isDarkBackground={false}
          title={(
            <span style={styles.currentCampaignsTitleRow}>{/* @TODO: new name /}
              <span>Progress</span>
            </span>
          )}
        >
          <StudentProgressTable classroomId={classroomId} />
        </WebsiteSection>
      }
      */}

      <WebsiteSection
        isDarkBackground={false}
        title={(
          <React.Fragment>
            <span style={styles.publishedStatusWrapper}>
              <Alert
                type={classroomGoal.status === 'draft' ? 'warning' : 'success'}
                leftButton={classroomGoal.status === 'draft' && <PublishButton classroomId={classroomGoal.classroom_id} goalId={goal.id} />}// @TODO: this is the right button
                text={classroomGoal.status === 'draft' ? ClassroomGoalConstants.draftText : ClassroomGoalConstants.publishedText(classroomGoal.date_published)}
              />
            </span>
            <span style={styles.editRow}>
              <span style={styles.fullTitleWrapper}>
                <h1 style={styles.mainTitle}>{goal.title}</h1>
                <span style={styles.subTitle}>{utilities.prettyAvailablityDateRange(goal.date_available, goal.date_deadline)}</span>
              </span>
              <span style={styles.actionsWrapper}>
                <Tooltip title='Edit goal'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() => setIsEditGoalDialogOpen(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </span>
          </React.Fragment>
        )}
      >
        {_modules.map((module, index) => { // @TODO: introduce accordion here (for modules and assignments)
          return (
            <DndProvider
              key={module.id}
              backend={HTML5Backend}
            >
              <Module
                goal={goal}
                classroomGoal={classroomGoal}
                module={module}
                moveModule={moveModule}
                index={index}
                onReorderModules={() => {
                  dispatch(apiUpdateGoal(goal.id, undefined, undefined, undefined, _modules.map((m) => m.id), undefined, undefined, undefined, undefined));
                }}
              />
            </DndProvider>
          );
        })}
        <Paper style={{...styles.accordionWrapper, ...styles.coloredAccordion}}>
          <Accordion
            style={styles.accordionInner}// @TODO: change
            defaultExpanded={false}
            // @TODO: disabled={true}
          >
            <AccordionSummary
              // @TODO: expandIcon={<ExpandMoreIcon />}
              onClick={() => setIsNewModuleDialogOpen(true)}
            >
              <h2 style={styles.moduleName}>+ Add new module</h2>
              {/* @TODO:
              <TextField// @TODO: make this white...label should match (or be close to) existing module names
                value={_newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
                placeholder=''
                label='+ Add new module'// @TODO: improve ui
                variant='standard'
              />
              {_newModuleName &&
                <React.Fragment>
                  <IconButton
                    style={{...styles.editIconButton, ...styles.editIconButtonCheck}}
                    onClick={() => {
                      // @TODO: implement back-end call
                    }}
                    variant='contained'// @TODO:
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    style={{...styles.editIconButton, ...styles.editIconButtonClose}}
                    onClick={() => {
                      setNewModuleName('');
                    }}
                    variant='contained'
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
              */}
            </AccordionSummary>
          </Accordion>
        </Paper>
      </WebsiteSection>
      {_isEditGoalDialogOpen && <EditGoalDialog isAdmin={isAdmin} classroomGoal={classroomGoal} goal={goal} onClose={() => setIsEditGoalDialogOpen(false)} />}
      {_isNewModuleDialogOpen && <EditModuleDialog classroomGoal={classroomGoal} goal={goal} isNew={true} onClose={() => setIsNewModuleDialogOpen(false)} />}
    </WebPageStub>
  );
};

ClassroomGoal.propTypes = {
  // @TODO: implement
};

export default ClassroomGoal;
