/*
 * Ryan O'Dowd
 * 2024-05-24
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  iconMenuItemText: {
    marginLeft: 8,
  },
  tab: {
    color: Globals.colors.primaryExtraLight,
    fontWeight: 'bold',
  },
  selectedTab: {
    border: `2px solid ${Globals.colors.primary}`,
    // @TODO: borderBottom: 'none',
    // @TODO: borderTopLeftRadius: 8,
    // @TODO: borderTopRightRadius: 8,
    borderRadius: 3,
  },
};

export default styles;
