/*
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  apiUpdateClassroomGoal,
  apiUpdateGoal,
  apiUpdateGoalTags,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Alert from '../../../common/components/OakAlert';
import ClassroomGoalConstants from '../Globals';
import DateRange from '../DateRange';
import PropTypes from 'prop-types';
import PublishButton from '../PublishButton';
import {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import styles from './styles';

const EditGoalDialog = (props) => {
  const dispatch = useDispatch();

  const [_goalName, setGoalName] = useState(props.goal.title || '');
  const [_goalType, setGoalType] = useState(props.goal.goal_type);
  const [_goalDescription, setGoalDescription] = useState(props.goal.description || '');
  const [_isPublic, setIsPublic] = useState(props.classroomGoal.is_public !== undefined ? !!props.classroomGoal.is_public : !!props.isAdmin);
  const [_dateAvailable, setDateAvailable] = useState(props.goal.date_available ? new Date(props.goal.date_available) : null);
  const [_dateDeadline, setDateDeadline] = useState(props.goal.date_deadline ? new Date(props.goal.date_deadline) : null);
  // @TODO: const [_status, setStatus] = useState(props.classroomGoal.status);
  const [_canChooseTranslation, setCanChooseTranslation] = useState(props.classroomGoal.can_choose_translation); // @TODO: default to true for public goals and false for classroom goals

  const tags = useSelector((state) => state.tags);
  const [_tags, setTags] = useState(props.classroomGoal.tags.map((t) => tags[t.tag_id].name));

  const filter = createFilterOptions();

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>Edit goal</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <span style={styles.publishedStatusWrapper}>
          <Alert
            type={props.classroomGoal.status === 'draft' ? 'warning' : 'success'}
            leftButton={props.classroomGoal.status === 'draft' && <PublishButton classroomId={props.classroomGoal.classroom_id} goalId={props.goal.id} />}
            text={props.classroomGoal.status === 'draft' ? ClassroomGoalConstants.draftText : ClassroomGoalConstants.publishedText(props.classroomGoal.date_published)}
          />
        </span>

        <span style={styles.headerText}>Goal name</span>
        <span style={styles.nameWrapper}>
          <TextField
            value={_goalName}
            autoFocus={true}
            fullWidth
            onChange={(e) => setGoalName(e.target.value)}
            placeholder=''
            label='Goal name'
            variant='standard'
          />
        </span>

        <br />{/* @TODO: shouldn't need this */}
        <br />{/* @TODO: shouldn't need this */}

        <span style={styles.headerText}>Description</span>
        <span style={styles.nameWrapper}>
          <TextField
            value={_goalDescription}
            autoFocus={true}
            fullWidth
            onChange={(e) => setGoalDescription(e.target.value)}
            placeholder=''
            label='Description'
            variant='standard'
          />
        </span>

        <br />{/* @TODO: shouldn't need this */}

        {props.isAdmin && // @TODO: not for v1 for classrooms...only for public goals right now
          <React.Fragment>
            <span style={styles.headerText}>Translation</span>
            <span style={styles.translationWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!_canChooseTranslation}
                    onChange={(e) => setCanChooseTranslation(e.target.checked)}
                  />
                }
                label='Allow students to choose their preferred Bible translation.'
              />
            </span>
          </React.Fragment>
        }

        <DateRange
          dateAvailable={_dateAvailable}
          dateDeadline={_dateDeadline}
          setDateAvailable={(newValue) => setDateAvailable(newValue)}
          setDateDeadline={(newValue) => setDateDeadline(newValue)}
          hideClear={true}
        />
        {/* @TODO: show warning for non-null dates if this is a public goal (admin only) */}

        <br />{/* @TODO: shouldn't need this */}

        {props.isAdmin &&
          <div style={styles.adminOptionsWrapper}>
            <span style={styles.headerText}>Admin options</span>

            <div style={styles.adminOptionsInner}>
              <span style={styles.headerText}>Private/public</span>
              <span style={styles.nameWrapper}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!_isPublic}
                      onChange={(event) => setIsPublic(event.target.checked)}
                    />
                  }
                  label='Public goal?'
                />
              </span>

              <br />{/* @TODO: shouldn't need this */}

              <span style={styles.headerText}>Goal type</span>
              <span style={styles.nameWrapper}>
                <Select
                  labelId='goalType'
                  value={_goalType}
                  label='Goal type'
                  onChange={(e) => setGoalType(e.target.value)}
                >
                  <MenuItem value='memory'>Memory</MenuItem>
                  <MenuItem value='group'>Group</MenuItem>
                  <MenuItem value='reading'>Reading</MenuItem>
                </Select>
              </span>

              <br />{/* @TODO: shouldn't need this */}

              <span style={styles.headerText}>Tags</span>
              <span style={styles.nameWrapper}>
                <Autocomplete
                  disablePortal
                  id='tag-selection'
                  options={Object.values(tags).map((tag) => tag.name)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(inputValue);
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title; // @TODO: remove?
                  }}
                  multiple={true}
                  value={_tags}
                  onChange={(event, value) => setTags(value)}
                  // @TODO: make sure adding new tags works
                  fullWidth
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label='Tags'
                      />
                    );
                  }}
                />
              </span>
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions>
        {/* @TODO:
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => {
            // @TODO: confirm with alert after letting teacher know how permanent this is...show how many kids have started (maybe not an option if goal is published)
            props.onClose();
          }}
          variant='contained'
        >
          Delete
        </Button>
        */}
        {/* @TODO: button to duplicate goal */}
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            dispatch(apiUpdateGoal(props.goal.id, _goalName, _goalDescription, _goalType, null, null, _dateAvailable, _dateDeadline, undefined, undefined, () => {
              // @TODO: don't like that this is a callback, but it prevents race condition when updating name/description:
              /* @TODO: when editing a goal, there are 3 api calls (patches for goal, goal metadata, and tags), and there's a race condition in updating name/description because `goal` is returned not only in the goal patch, but in the other two as well (but it hasn't been updated yet, so it returns the old goal name) */
              if (JSON.stringify(_tags.map((t) => t.id)) !== JSON.stringify(props.classroomGoal.tags.map((t) => t.id))) {
                dispatch(apiUpdateGoalTags(props.classroomGoal.classroom_id, props.goal.id, _tags));
              }
              const classroomGoalUpdates = {};
              if (_canChooseTranslation !== props.classroomGoal.can_choose_translation) {
                classroomGoalUpdates.canChooseTranslation = _canChooseTranslation;
              }
              if (_isPublic !== props.classroomGoal.is_public) {
                classroomGoalUpdates.isPublic = _isPublic;
              }
              if (Object.keys(classroomGoalUpdates).length) {
                dispatch(apiUpdateClassroomGoal(props.classroomGoal.classroom_id, props.goal.id, classroomGoalUpdates));
              }
              props.onClose(); // @TODO: wait for back-end call to complete?
            })); // @TODO: this should be only fields that have been updated (compare state variables with props.goal)
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditGoalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  goal: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape

  isAdmin: PropTypes.bool,
};

export default EditGoalDialog;
