/*
 * Ryan O'Dowd
 * 2022-09-24
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Card,
  CardActionArea,
  CardContent,
  // @TODO: CardMedia,
} from '@mui/material';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import utilities from '../../../utilities';

const CampaignPreview = (props) => {
  const i18n = utilities.useI18n();

  return (
    // @TODO: there should be a visual indication of active/inactive campaigns...maybe a button to toggle visibility too
    <div style={styles.container}>
      <Card sx={styles.campaignCard}>
        <CardActionArea
          component={Link}
          to={`/campaigns/${props.slug}`}
        >
          {/* @TODO: maybe don't need logos for this page
          <CardMedia
            style={styles.cardMedia}
            component='img'
            height='140'
            image={props.organization.logo_url}
            alt={`${props.name} logo`}
          />
          */}
          <CardContent style={styles.cardContent}>
            {props.isSelected && <span style={styles.selectedIndicator} />}{/* @TODO: this is better as an indicator of whether campaign is active or old (add red too)...maybe invert colors for isSelected */}
            <h3 style={styles.campaignName}>{props.name}</h3>
            <span style={styles.organizationName}>{props.organization.name}</span>
            <span style={styles.startAndEndDates}>{`${dateFnsFormat(new Date(props.start_datetime), 'MMMM d, yyyy', {locale: i18n('locale')})}-${dateFnsFormat(new Date(props.end_datetime), 'MMMM d, yyyy', {locale: i18n('locale')})}`}</span>{/* @TODO: abstract the format string */}
            {/* @TODO: start and end dates */}
            {/* @TODO:
            <p>{props.description}</p>
            */}
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

CampaignPreview.propTypes = {
  // @TODO: add proptypes
  isSelected: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  start_datetime: PropTypes.string.isRequired,
  end_datetime: PropTypes.string.isRequired,
};

export default CampaignPreview;
