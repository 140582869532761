/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  foo: { // @TODO: rename
    flexDirection: 'row',
  },
  headerWrapper: {
    // @TODO: $icale: '$globalScale',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginRight: 8,
  },
  chapterMetadataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passageActionButton: {
    // @TODO: $scale: '$globalScale',
    marginTop: 0,
    marginRight: 4,
    marginBottom: 0,
    marginLeft: 4,
    borderWidth: 1,
    borderColor: Globals.colors.accent,
    backgroundColor: Globals.colors.primaryDark,
  },
  disabledButton: {
    opacity: 0.3,
    borderColor: Globals.colors.gray,
    backgroundColor: Globals.colors.lightGray,
  },
  levelFiveButton: {
    borderColor: Globals.colors.primaryExtraDark,
    backgroundColor: Globals.colors.accent,
  },
  activePassageActionButton: {
    borderColor: Globals.colors.primaryExtraDark,
    backgroundColor: Globals.colors.accent,
  },
  closedTestIcon: {
    borderColor: Globals.colors.lightGray,
    backgroundColor: Globals.colors.gray,
  },
  normalizedScoreWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  normalizedScoreGradient: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 10,
    borderRadius: 4,
  },
  emptyScore: {
    width: 10,
    height: 2,
    backgroundColor: Globals.colors.primaryDark,
  },
  versesWrapper: {
    // @TODO: height: 200, // @TODO: shouldn't have to hardcode this
    // @TODO: height: '100%',
    // @TODO:
    flex: 1,
    // @TODO: maxHeight: 500, // @TODO: better value
    margin: 0,
    padding: 0,
  },
};

export default styles;
