/*
* Ryan O'Dowd
* 2022-03-26
* © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
// @TODO: compat needs to be removed before next major release: https://firebase.google.com/docs/web/modular-upgrade
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import importedFirebase from 'firebase/compat/app';

const env = (window.document.location.host === 'wrink.ly') ? 'prod' : (window.document.location.hostname === 'localhost' ? 'local' : 'dev');
let FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAdaKn_jzLl5v2EEg5mmyaMTZ5QVEUxgsA',
  authDomain: 'wrinkly-dev.firebaseapp.com',
  projectId: 'wrinkly-dev',
  storageBucket: 'wrinkly-dev.appspot.com',
  messagingSenderId: '895606346021',
  appId: '1:895606346021:web:287e5fc66c61bb8aeb0acc',
  measurementId: 'G-8CVV86W6FZ',
};
if (env === 'prod') {
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBtE2Xpe253lnL2MH5Y5iMgNXNiW48p3h4',
    authDomain: 'wrinkly-prod.firebaseapp.com',
    projectId: 'wrinkly-prod',
    storageBucket: 'wrinkly-prod.appspot.com',
    messagingSenderId: '182142003913',
    appId: '1:182142003913:web:5a0abd853d8ae105c9bb18',
    measurementId: 'G-YN7NJ9M7SN',
  };
}

if (!importedFirebase.apps.length) {
  // make sure app is initialized exactly once (instead of every time Globals is imported?)
  importedFirebase.initializeApp(FIREBASE_CONFIG);
}

export default {
  importedFirebase,
  platform: 'web',
  appVersionNumber: '3.5.0', // @TODO: pull this dynamically from something...git tag? something else?
  env,
  isMobile: window.innerWidth < 600,
};
