/**
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../../Globals';

const styles = {
  container: {
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
  },
  infoText: {
    marginTop: 4,
    marginBottom: 4,
  },
};

export default styles;
