/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const WebsiteSection = (_p) => {
  const props = {
    style: {},
    innerStyle: {},
    ..._p,
  };

  const bgXPos = useMemo(() => Math.floor(Math.random() * 100), []);
  const bgYPos = useMemo(() => Math.floor(Math.random() * 100), []);

  return (
    <div
      style={{
        ...styles.container,
        ...styles[props.isDarkBackground ? 'darkBackground' : 'lightBackground'](bgXPos, bgYPos),
        ...props.style,
      }}
    >
      <div style={{...styles.sectionInner, ...props.innerStyle}}>
        <h2>{props.title}</h2>
        {props.children}
      </div>
    </div>
  );
};

WebsiteSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,

  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDarkBackground: PropTypes.bool,
  style: PropTypes.object,
  innerStyle: PropTypes.object,
};

export default WebsiteSection;
