/*
 * Ryan O'Dowd
 * 2019-05-14
 * © Copyright 2012 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  outerContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px)', // @TODO: header height; don't hardcode
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
    textAlign: 'left',
    color: Globals.colors.white,
  },
  tabsWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
  },
};

export default styles;
