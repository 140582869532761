/*
 * Ryan O'Dowd
 * 2022-07-22
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 32,
    textAlign: 'left',
  },
  sectionInner: {
    width: '100%',
    maxWidth: 1000,
  },
  lightBackground: (xPos, yPos) => {
    return {
      color: Globals.colors.primaryExtraDark,
      backgroundImage: `linear-gradient(
        ${Globals.colors.white}cc 0%,
        ${Globals.colors.white}ee 100%
      ), url("/img/jungle_light.png")`,
      backgroundSize: 'cover', // @TODO: would be nice for this to be "cover and zoom" so that the x-pos could be randomized better
      backgroundPosition: `${xPos}% ${yPos}%`,
      backgroundRepeat: 'no-repeat',
    };
  },
  darkBackground: (xPos, yPos) => {
    return {
      color: Globals.colors.primaryExtraLight,
      backgroundImage: `linear-gradient(
        ${Globals.colors.primary}d2 0%,
        ${Globals.colors.primaryDark}ff 100%
      ), url("/img/jungle.png")`,
      backgroundSize: 'cover', // @TODO: would be nice for this to be "cover and zoom" so that the x-pos could be randomized better
      backgroundPosition: `${xPos}% ${yPos}%`,
    };
  },
};

export default styles;
