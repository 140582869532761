/*
 * Ryan O'Dowd
 * 2024-05-18
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  adminSaveNewWhatsNewNote,
  apiUpdateWhatsNewNote,
  fetchAdminWhatsNew,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  AdapterDateFns,
} from '@mui/x-date-pickers/AdapterDateFnsV3';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import {
  DatePicker,
} from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';
import Globals from '../../../Globals';
import LanguageIcon from '@mui/icons-material/Language';
import {
  LocalizationProvider,
} from '@mui/x-date-pickers/LocalizationProvider';
import OakTable from '../../../common/components/OakTable';
import PropTypes from 'prop-types';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const WhatsNewTable = (props) => {
  const dispatch = useDispatch();

  const [_isNewVersionDialogOpen, setIsNewVersionDialogOpen] = useState(false);
  const [_showAll, setShowAll] = useState(false);
  const [_releaseNotesText, setReleaseNotesText] = useState('');
  const [_releaseDate, setReleaseDate] = useState(new Date(Date.now() + 1000 * 60 * 60 * 24)); // default to tomorrow
  const [_appVersionText, setAppVersionText] = useState('');
  const [_linkText, setLinkText] = useState('');
  const [_isIosSupported, setIsIosSupported] = useState(true);
  const [_isAndroidSupported, setIsAndroidSupported] = useState(true);
  const [_isWebSupported, setIsWebSupported] = useState(false);
  const [_whatsNewNoteEditId, setWhatsNewNoteEditId] = useState(null);

  useEffect(() => {
    dispatch(fetchAdminWhatsNew());
  }, [dispatch]);

  const whatsNewNotes = useSelector((state) => state.whatsNewNotes);
  const tableRows = whatsNewNotes.filter((wnn) => _showAll ? true : wnn.is_supported).sort((a, b) => new Date(b.release_date) - new Date(a.release_date)).map((wnn) => {
    return [
      {id: `${wnn.id}_app_version`, content: wnn.app_version},
      {id: `${wnn.id}_release_date`, content: dateFnsFormat(new Date(wnn.release_date.replace('00:00:00', '12:00:00')), 'MMMM d, yyyy')},
      {id: `${wnn.id}_platforms`, content: [!!wnn.is_ios && <AppleIcon key='apple' />, !!wnn.is_android && <AndroidIcon key='android' />, !!wnn.is_web && <LanguageIcon key='web' />].filter(Boolean)},
      {id: `${wnn.id}_release_notes`, content: <span style={styles.releaseNotesWrapper}>{wnn.release_notes.split('\n').map((rn) => <pre key={rn} style={styles.note}>{'* ' + rn}</pre>)}</span>}, // @TODO: add ability to edit this
      {id: `${wnn.id}_link`, content: wnn.link ? <a href={wnn.link}>{wnn.link}</a> : '-'}, // @TODO: format prettier
      {
        id: `${wnn.id}_is_supported`,
        content: (
          <Switch
            checked={!!wnn.is_supported}
            onChange={(event) => dispatch(apiUpdateWhatsNewNote(wnn.id, {isSupported: event.target.checked}))}
          />
        ),
      },
      {
        id: `${wnn.id}_edit`,
        content: (
          <IconButton
            onClick={() => {
              setReleaseNotesText(wnn.release_notes);
              setReleaseDate(new Date(wnn.release_date));
              setAppVersionText(wnn.app_version);
              setLinkText(wnn.link);
              setIsIosSupported(wnn.is_ios);
              setIsAndroidSupported(wnn.is_android);
              setIsWebSupported(wnn.is_web);
              setIsNewVersionDialogOpen(true);
              setWhatsNewNoteEditId(wnn.id);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      },
    ];
  });

  return (
    <div style={styles.container}>
      <div style={styles.sectionWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={_showAll}
              onChange={(e) => setShowAll(e.target.checked)}
              sx={{
                color: Globals.colors.primaryExtraLight,
                '&.Mui-checked': {
                  color: Globals.colors.accent,
                },
              }}
            />
          }
          label='Show all'
        />
      </div>
      <OakTable
        tableTitle='Notes'
        open={true}
        headerRowData={[
          'App version',
          'Release date',
          'Platforms',
          'Release notes',
          'Link',
          'Supported?',
        ]}
        rowData={tableRows}// @TODO: shouldn't be clickable (or if they are, make individually updatable)
        filtersJsx={
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={() => {
              setIsNewVersionDialogOpen(true);
              setWhatsNewNoteEditId(null);
              setReleaseNotesText('');
              setReleaseDate(new Date(Date.now() + 1000 * 60 * 60 * 24)); // default to tomorrow
              setAppVersionText('');
              setLinkText('');
              setIsIosSupported(true);
              setIsAndroidSupported(true);
              setIsWebSupported(false);
            }}
          >
            Add release notes
          </Button>
        }
      />

      <Dialog
        open={_isNewVersionDialogOpen}
        style={styles.dialog}
        fullScreen={Globals.isMobile}
        onClose={() => setIsNewVersionDialogOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Add release notes
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          <TextField// @TODO: validate this value; format and min version
            value={_appVersionText}
            onChange={(e) => setAppVersionText(e.target.value)}
            placeholder='1.2.3'
            label='App version'
            variant='standard'
          />
          <br />
          <TextField
            value={_releaseNotesText}
            onChange={(e) => setReleaseNotesText(e.target.value)}
            multiline={true}
            minRows={3}
            maxRows={6}
            placeholder={`Feature one
Bugfix two
Feature three`}
            fullWidth
            label='Release notes'
            variant='standard'
          />
          <br />
          <TextField// @TODO: validate this value; format and min version
            value={_linkText}
            onChange={(e) => setLinkText(e.target.value)}
            placeholder='https://example.com'
            label='Link (optional)'
            variant='standard'
          />
          <br />
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Release date'
              value={_releaseDate}
              onChange={(newValue) => setReleaseDate(newValue)}
            />
          </LocalizationProvider>
          <br />
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={_isIosSupported} onChange={(e) => setIsIosSupported(e.target.checked)} />} label='iOS' />
            <FormControlLabel control={<Checkbox checked={_isAndroidSupported} onChange={(e) => setIsAndroidSupported(e.target.checked)} />} label='Android' />
            <FormControlLabel control={<Checkbox checked={_isWebSupported} onChange={(e) => setIsWebSupported(e.target.checked)} />} label='Web' />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            autoFocus={false}
            onClick={() => setIsNewVersionDialogOpen(false)}
          >
            Close
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              if (!_releaseNotesText || !_releaseDate || !_appVersionText || (!_isIosSupported && !_isAndroidSupported && !_isWebSupported)) {
                alert('Please fill required fields.');
                return;
              }
              if (_whatsNewNoteEditId) {
                dispatch(apiUpdateWhatsNewNote(_whatsNewNoteEditId, {
                  releaseNotes: _releaseNotesText,
                  releaseDate: _releaseDate,
                  appVersion: _appVersionText,
                  isIos: _isIosSupported,
                  isAndroid: _isAndroidSupported,
                  isWeb: _isWebSupported,
                  link: _linkText,
                }));
              } else {
                dispatch(adminSaveNewWhatsNewNote(_releaseNotesText, _releaseDate, _appVersionText, _isIosSupported, _isAndroidSupported, _isWebSupported, _linkText));
              }
              setIsNewVersionDialogOpen(false);
            }}
          >
            Save release notes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

WhatsNewTable.propTypes = {
  // @TODO: add proptypes
};

export default WhatsNewTable;
