/**
 * Ryan O'Dowd
 * 2024-03-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */

const styles = {
  container: { // @TODO: looks like there's still horizonal margin or padding on home screen passages
    // @TODO: display: 'flex',
    width: '100%',
    height: '100%',
  },
  mainListContainer: {
    // @TODO: width: Dimensions.get('window').width,
    // @TODO: marginTop: 4,
    width: '100%',
    paddingHorizontal: 6,
    paddingTop: 12,
    paddingBottom: 8, // @TODO: look okay on android?
  },
  extraBottomPadding: { // @TODO: not a fan of hardcoding SelectionMenu's (approximate) height
    paddingBottom: 44 + 8, // @TODO: look okay on android?
  },
  // @TODO: abstract these with components/Passage
  versesWrapper: {
    // @TODO: display: 'flex',
    paddingTop: 0,
    marginBotton: 0,
    paddingBottom: 0,
  },
  numsOnlyVersesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  licenseBuffer: {
    marginTop: 16,
    marginBottom: 16,
  },
};

export default styles;
