/*
 * Ryan O'Dowd
 * 2024-05-13
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import CancelIcon from '@mui/icons-material/Cancel';
import Globals from '../../../Globals';
import InfoIcon from '@mui/icons-material/Info';
import {
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Text from '../../../common/components/OakText';
import VerifiedIcon from '@mui/icons-material/Verified';
import styles from './styles';

const OakAlert = (props) => {
  let Icon = InfoIcon;
  let color = Globals.colors.primaryDark;
  let backgroundColor = Globals.colors.primaryExtraLight;
  if (props.type === 'success') {
    Icon = VerifiedIcon;
    color = Globals.colors.accentDark;
    backgroundColor = Globals.colors.accentLight;
  } else if (props.type === 'warning') {
    Icon = ReportProblemIcon;
    color = Globals.colors.warnIncorrect;
    backgroundColor = Globals.colors.warningBg;
  } else if (props.type === 'error') {
    Icon = CancelIcon;
    color = Globals.colors.alertDark;
    backgroundColor = Globals.colors.alertLight;
  }

  return (
    <Paper style={{...styles.container, ...{backgroundColor}}}>
      <span style={styles.contentWrapper}>
        <Icon style={{...styles.icon, ...{color}}} />
        <Text style={{...styles.bannerText, ...{color}}}>{props.text}</Text>
        {props.leftButton}
      </span>
    </Paper>
  );
};

OakAlert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,

  leftButton: PropTypes.object,
};

export default OakAlert;
