/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */

const styles = {
  container: { // @TODO: abstract with home
    maxHeight: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
  },
  image: { // @TODO: abstract with home
    maxHeight: '100%',
    maxWidth: '100%',
  },
};

export default styles;
