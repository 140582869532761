/*
 * Ryan O'Dowd
 * 2023-10-26
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
  useState,
} from 'react';
import DownloadLinks from '../../components/DownloadLinks';
import Globals from '../../Globals';
import ReviewSection from './ReviewSection';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';
import {
  useParams,
} from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../utilities';

const YearInReview = (props) => {
  const i18n = utilities.useI18n();

  const {
    token,
  } = useParams();

  const [_stats, setStats] = useState(undefined);

  const msSpent = useSelector((state) => state.webStatsMsSpent);
  const versesReviewed = useSelector((state) => state.webStatsNumVersesReviewed);

  useEffect(() => {
    fetch(`${Globals.apiUrl}/year_in_review/${token}`).then((response) => {
      if (response.status !== 200) {
        throw new Error('server error'); // @TODO: cleaner
      }
      return response.json();
    }).then((response) => {
      setStats(response.stats);
    }).catch((e) => {
      console.error(e);
      setStats(null);
    });
  }, [token]);

  if (_stats === undefined) {
    return 'loading'; // @TODO: prettier
  }

  if (_stats === null) {
    return 'not found'; // @TODO: prettier
  }

  // @TODO: const lessonsPerDay = _stats.num_lessons / _stats.days_with_lessons;

  return ( // @TODO: wrap in skeleton
    <WebPageStub>{/* @TODO: make sure this looks good on mobile */}
      <WebsiteSection>
        <h1 style={styles.pageTitle}>{_stats.user_name}{"'s 2023 Wrinkly Summary"}</h1>{/* @TODO: i18n */}{/* @TODO: better title */}
      </WebsiteSection>
      <ReviewSection
        title='Lessons'
        imageSource='/img/characters/elephant-success-shadow.png'
      >
        <span style={styles.statsParagraph}>
          {i18n('yearInReviewLessons',
            <span style={styles.statText}>{_stats.num_lessons} {_stats.num_lessons === 1 ? i18n('lesson') : i18n('lessons')}</span>,
            <span style={styles.statText}>{_stats.days_with_lessons} {_stats.days_with_lessons === 1 ? i18n('day') : i18n('days')}</span>,
            // @TODO: {Math.floor(lessonsPerDay) > 1 ? `  (That's ${Math.floor(lessonsPerDay) !== Math.ceil(lessonsPerDay) && 'more than '}` : null}<Text style={styles.statText}>{Math.floor(lessonsPerDay)}</Text> lessons per day!)
          )}
        </span>
        <span style={styles.statsParagraph}>
          {i18n('yearInReviewTime',
            <span style={styles.statText}>{utilities.humanReadableTime(_stats.ms_spent)}</span>,
            <span style={styles.statText}>{utilities.humanReadableNumber(_stats.num_verses)} {i18n('versesLowercase')}</span>,
            <span style={styles.statText}>{utilities.humanReadableNumber(_stats.peanuts_earned)} {i18n('peanuts').toLocaleLowerCase()}</span>,
          )}
        </span>
        {/* @TODO: i18n the rest */}
        {/* @TODO: looks like you preferred {difficulty} difficulty, and your favorite game type is {gametype}
          <p>TODO: pie chart for lesson difficulty</p>
          <p>TODO: pie chart for types of games</p>
        */}
      </ReviewSection>
      <ReviewSection
        isRightAligned={true}
        title='Verses and Goals'
        imageSource='/img/characters/brain-success-shadow-right.png'
      >
        <span style={styles.statsParagraph}>
          {i18n('yearInReviewGoalsAndWrinkles',
            <span style={styles.statText}>{utilities.humanReadableNumber(_stats.num_goals_worked_on)} {_stats.num_goals_worked_on === 1 ? i18n('goal') : i18n('goals').toLocaleLowerCase()}</span>,
            <span style={styles.statText}>{utilities.humanReadableNumber(_stats.num_wrinkles_earned)} {_stats.num_wrinkles_earned === 1 ? i18n('wrinkle') : i18n('wrinkles').toLocaleLowerCase()}</span>,
            _stats.num_wrinkles_earned,
            _stats.num_wrinkles_earned === 1 ? i18n('verse').toLocaleLowerCase() : i18n('verses').toLocaleLowerCase(),
            _stats.num_wrinkles_in_progress ? `${_stats.num_wrinkles_in_progress} ${_stats.num_wrinkles_in_progress === 1 ? i18n('wrinkle') : i18n('wrinkles').toLocaleLowerCase()}` : '',
          )}
        </span>
        {/* @TODO: maybe two more paragraphs here */}
        {/* @TODO:
      </ReviewSection>
      <ReviewSection
        title='Friends'
        imageSource='/img/characters/leopard.jpg'
      >
        <p>TODO: friends</p>
      </ReviewSection>
      <ReviewSection
        // @TODO: isRightAligned={true}
        title='Other'// @TODO: better name
        imageSource='/img/characters/monkeys.png'
      >
      */}
        {!!_stats.num_friends && (
          <span style={styles.statsParagraph}>
            {i18n('yearInReviewMiscFriends',
              _stats.num_friends,
              <span style={styles.statText}>{_stats.num_friends} {_stats.num_friends === 1 ? i18n('friend') : i18n('friends').toLocaleLowerCase()}</span>, // @TODO: make this interactive, reword, and also have alternative text if _stats.num_friends === 0
            )}
          </span>
        )}
        {/* @TODO:
        <span style={styles.statsParagraph}>
          {i18n('yearInReviewMiscDays', <span style={styles.statText}>{_stats.days_with_lessons} {_stats.days_with_lessons === 1 ? i18n('day') : i18n('days')}</span>)}
        </span>
        */}
        {/* @TODO: maybe one more paragraph */}
      </ReviewSection>
      <ReviewSection title='All Users'>
        <span style={styles.statsParagraph}>
          {i18n('yearInReviewGlobal',
            <span style={styles.statText}>{utilities.humanReadableTime(msSpent)}</span>,
            <span style={styles.statText}>{utilities.humanReadableNumber(versesReviewed)} {i18n('verses').toLocaleLowerCase()}</span>,
          )}
        </span>
      </ReviewSection>

      <WebsiteSection
        isDarkBackground={true}
        style={styles.downloadCtaSection}
      >
        <div style={styles.downloadCtaWrapper}>
          <h2>Try Wrinkly</h2>{/* @TODO: make this "share wrinkly" instead and have options to share year in review with social media and stuff */}
          <span style={styles.downloadLinks}>
            <DownloadLinks />
          </span>
        </div>
      </WebsiteSection>
    </WebPageStub>
  );
};

export default YearInReview;
