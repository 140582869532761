/**
 * Ryan O'Dowd
 * 2022-12-26
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  FETCH_ERROR,
  FETCH_ERROR_OBJECT,
  FETCH_REQUEST,
  FETCH_REQUEST_OBJECT,
  FETCH_SUCCESS_OBJECT,
  LOGOUT,
  REMOVE_FETCH,
  SET_CHAT_MESSAGES,
  SET_FEEDBACK_DRAFT_TEXT,
  SET_LIGHT_DARK_MODE,
  SET_OAK_USER,
  SET_SIGN_IN_INFO,
  SET_WHATS_NEW_LAST_READ_TIMESTAMP,
  SET_WHATS_NEW_LAST_READ_VERSION,
  SET_WHATS_NEW_NOTES,
  UPDATE_FETCH,
  UPDATE_LOADING_STATE,
} from './actions';
import Globals, {
  firebase,
} from '../Globals';

export function fetches(state = {}, action) { // @TODO: use this...toast network failures to user (and maybe other stuff too)
  switch (action.type) {
  case FETCH_REQUEST:
    return {
      ...state,
      [action.meta.requestId]: {
        ...FETCH_REQUEST_OBJECT,
        ...action.meta,
        isRead: true, // don't need to toast this, just show to devs in header
        message: 'In flight',
      },
    };
  case FETCH_ERROR: {
    // @TODO: need better error handling...i'm currently getting no feedback if whole flask app is down...
    const message = (!action.payload || !action.payload.message || action.payload.message === 'Failed to fetch') ? 'An error has occurred, please try again. If the error continues, please contact support.' : action.payload.message;
    return {
      ...state,
      [action.meta.requestId]: {
        ...FETCH_ERROR_OBJECT,
        ...action.meta,
        message: action.meta.skipMessage ? '' : message,
        isRead: false,
      },
    };
  }
  case REMOVE_FETCH: {
    const {[action.requestId]: _omit, ...otherFetches} = state;
    return otherFetches;
  }
  case UPDATE_FETCH: {
    return {
      ...state,
      [action.fetchRequest.requestId]: {
        ...action.fetchRequest,
      },
    };
  }
  default: { // TODO: Fetch success should have it's own case, but we can't because the action type is different for all of them
    if (action.meta && action.meta.requestId) {
      // this is a successful fetch...update the status
      return {
        ...state,
        [action.meta.requestId]: {
          ...FETCH_SUCCESS_OBJECT,
          ...action.meta,
          message: (action.meta.responseStatus === 200 && action.payload === undefined) ? 'Success' : action.payload.message,
          isSuccessfullFetch: (action.meta.responseStatus === 200 && action.payload === undefined) ? true : action.payload.status === 'success',
          isRead: false,
        },
      };
    }
    return state;
  }
  }
}

export function oakUser(state = null, action) {
  switch (action.type) {
  case SET_OAK_USER: {
    if (action.payload.user && ['ios', 'android'].includes(Globals.platform)) {
      firebase.crashlytics().setAttribute('user_id', `${action.payload.user.id}` || '<unknown>');
      firebase.crashlytics().setAttribute('name', action.payload.user.display_name || '<unknown>');
      firebase.crashlytics().setAttribute('username', action.payload.user.username || '<unknown>');
    }
    return action.payload.user;
  }
  case LOGOUT:
    return null;
  default:
    return state;
  }
}

export function settingsLightDarkMode(state = 'light', action) {
  switch (action.type) {
  case SET_LIGHT_DARK_MODE:
    return action.lightDarkMode;
  default:
    return state;
  }
}

export function signInInfo(state = {}, action) {
  // temporary credentials for email- or phone-auth users in case they kill the app during the sign-in process
  switch (action.type) {
  case SET_SIGN_IN_INFO: {
    return {
      email: action.email,
      phoneNumber: action.phoneNumber,
      firstName: action.firstName,
      lastName: action.lastName,
    };
  }
  default:
    return state;
  }
}

export function feedbackChatDraftText(state = '', action) {
  switch (action.type) {
  case SET_FEEDBACK_DRAFT_TEXT:
    return action.feedbackChatDraftText;
  default:
    return state;
  }
}

export function whatsNewNotes(state = [], action) {
  switch (action.type) {
  case SET_WHATS_NEW_NOTES:
    return action.payload.whats_new_notes;
  default:
    return state;
  }
}

export function whatsNewLastReadTimestamp(state = 0, action) {
  switch (action.type) {
  case SET_WHATS_NEW_LAST_READ_TIMESTAMP:
    return action.timestamp;
  default:
    return state;
  }
}

export function whatsNewLastReadVersion(state = Globals.appVersionNumber, action) {
  switch (action.type) {
  case SET_WHATS_NEW_LAST_READ_VERSION:
    return action.version;
  default:
    return state;
  }
}

export function chatMessages(state = [], action) {
  switch (action.type) {
  case SET_CHAT_MESSAGES:
    return action.payload.chats;
  default:
    return state;
  }
}

export function loadingStates(state = {}, action) {
  switch (action.type) {
  case UPDATE_LOADING_STATE: {
    if (action.isLoading) {
      return {
        ...state,
        [action.resource]: action.metadata || true,
      };
    }

    // resource is not loading
    const {
      [action.resource]: _,
      ...newState
    } = state;

    return newState;
  }
  default:
    return state;
  }
}
