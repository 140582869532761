/*
 * Ryan O'Dowd
 * 2024-01-22
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  assignGoalToStudent,
  fetchBookNames,
  fetchBooks,
  fetchClassroomGoals,
  fetchClassroomStudents,
  updateClassroomStudent,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import Globals from '../../Globals';
import GoalPreview from './GoalPreview';
import InviteDialog from './InviteDialog';
import NewGoalDialog from './NewGoalDialog';
import OakTable from '../../common/components/OakTable';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';

const Classroom = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const schoolId = +params.schoolId;
  const classroomId = +params.classroomId;

  const [_studentOverflowMenuAnchorEl, setStudentOverflowMenuAnchorEl] = useState(null);
  const [_selectedStudentId, setSelectedStudentId] = useState(null);
  const [_show404, setShow404] = useState(false);
  const [_isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [_isUnassignedGoalsDialogOpen, setIsUnassignedGoalsDialogOpen] = useState(false);

  const [_checkboxStatuses, setCheckboxStatuses] = useState({});

  const [_isNewGoalDialogOpen, setIsNewGoalDialogOpen] = useState(false);

  const user = useSelector((state) => state.oakUser);
  const isAdmin = Globals.adminAuthIds.includes(user.auth_id);

  const classroom = useSelector((state) => state.classrooms[classroomId] || {}); // @TODO: shouldn't need `{}`
  const classroomStudents = useSelector((state) => state.classroomStudents[classroomId] || []).filter((student) => ['active', 'invited'].includes(student.status)); // @TODO: shouldn't need `[]`
  const classroomGoalMetadata = useSelector((state) => Object.values(state.classroomGoalMetadata).filter((cg) => {
    return cg.classroom_id === classroomId;
  }), (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue));
  const goalIdsForClassroom = classroomGoalMetadata.map((cg) => cg.goal_id);
  const goals = useSelector((state) => {
    const goalsForClassroom = {};
    goalIdsForClassroom.forEach((goalId) => {
      goalsForClassroom[goalId] = state.goals[goalId];
    });
    return goalsForClassroom;
  }, (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue));

  useEffect(() => {
    setTimeout(() => setShow404(true), 4000);
  }, []);

  useEffect(() => { // @TODO: this should go in App and be called coniditionally if user is logged in
    // @TODO: dispatch(fetchSchool(schoolId));
    dispatch(fetchBooks());
    dispatch(fetchBookNames());
    dispatch(fetchClassroomGoals(classroomId));
    dispatch(fetchClassroomStudents(classroomId));
  }, [dispatch, classroomId]);

  const _getLabel = (email, index, removeEmail) => {
    let style = styles.newEmail;
    let tooltipText = '';
    if (classroomStudents.filter((p) => !p.user_id).map((p) => `${p.invitation_name} <${p.invitation_email}`).includes(email)) { // @TODO: implement this
      style = styles.alreadyInvitedEmail;
      tooltipText = 'An invitation has already been sent to this email address, so a reminder will be sent instead.';
    } else if (classroomStudents.filter((p) => p.user_id).map((p) => p.invitation_email).includes(email)) { // @TODO: implement this
      style = styles.alreadyAcceptedEmail;
      tooltipText = 'An invitation has already been accepted by this email address, so it will be skipped.';
    }
    return (
      <Tooltip key={`${index}-${email}`} title={tooltipText}>
        <div data-tag key={`${index}-${email}`} style={{...styles.legendText, ...styles.emailInputText, ...style}}>
          {email}
          <span data-tag-handle onClick={() => removeEmail(index)}>{/* @TODO: better styling here */}
            ×
          </span>
        </div>
      </Tooltip>
    );
  };

  const studentTableRows = classroomStudents.map((cs) => { // @TODO: convert these to accordion? when expanded, show all info for just this student (and class average)
    let numUnassignedPublishedGoals = 0;
    if (cs.status === 'active') {
      numUnassignedPublishedGoals = classroomGoalMetadata.filter((cg) => { // @TODO: nesting this migh not be performant, but it also migh tnot make a difference
        if (cg.status !== 'published') {
          return false;
        }
        return !cg.student_ids_assigned?.includes(cs.user_id);
      }).length;
    }

    return [ // @TODO: update ids
      {
        id: `${cs.id}_display_name`,
        content: (
          <span>
            {/* @TODO:
            <Checkbox
              checked={!!_checkboxStatuses[cs.id]}// must be a bool to prevent uncontrolled warning
              onChange={() => {
                setCheckboxStatuses((curr) => {
                  const next = {...curr};
                  next[cs.id] = !curr[cs.id];
                  return next;
                });
              }}
              sx={{
                color: cs.color,
                '&.Mui-checked': {
                  color: cs.color,
                },
              }}
            />
            */}
            {cs.user ? cs.user.display_name : cs.display_name}{/* @TODO: if user exists, show cs.display_name on hover for convenience? */}
          </span>
        ),
      }, // @TODO: maybe surround with join date (or invitation date if pending)
      {id: `${cs.id}_date_invited`, content: cs.join_code ? <Tooltip title={dateFnsFormat(cs.created_at, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(cs.created_at, 'MMM d')}</span></Tooltip> : '-'}, // @TODO: replace '-' with tooltip about joining via public code?
      {id: `${cs.id}_date_joined`, content: cs.date_joined ? <Tooltip title={dateFnsFormat(cs.date_joined, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(cs.date_joined, 'MMM d')}</span></Tooltip> : '-'},
      {
        id: `${cs.id}_status`,
        content: (
          <span>
            {cs.status === 'invited' &&
              <Tooltip title='Invitation pending: please have student enter this code in their account to join the classroom.'><span style={styles.joinCode}>{cs.join_code}</span></Tooltip>
            }
            {cs.status === 'active' &&
              <span>active</span>
            }{/* @TODO: prettier status column */}
            {cs.status === 'rejected' &&
              <span>invitation removed</span>
            }{/* @TODO: prettier status column */}
          </span>
        ),
      },
      {
        id: `${cs.id}_menu`,
        content: (
          (cs.status === 'invited' || !!numUnassignedPublishedGoals) && (// @TODO: temporary since invited students are the only ones with menu options right now
            <span>
              <IconButton
                aria-controls='account-options-menu'
                aria-haspopup='true'
                onClick={(event) => {
                  setStudentOverflowMenuAnchorEl(event.currentTarget);
                  setSelectedStudentId(cs.id);
                }}
              >
                <Badge
                  badgeContent={
                    <span style={styles.badgeText}>
                      {numUnassignedPublishedGoals}
                    </span>
                  }
                  color='error'
                  invisible={!numUnassignedPublishedGoals}
                >
                  <DotsVerticalIcon color={Globals.colors.gray} />
                </Badge>
              </IconButton>
              <Menu// @TODO: not able to dismiss this by clicking away
                id='account-options-menu'
                anchorEl={_studentOverflowMenuAnchorEl}
                open={_selectedStudentId === cs.id && !!_studentOverflowMenuAnchorEl}
                onClose={() => {
                  setStudentOverflowMenuAnchorEl(null);
                  setSelectedStudentId(null);
                }}
              >{/* @TODO: most menuitems can be conditionally rendered */}
                {/* @TODO:
                <MenuItem
                  key='TODOa'
                  onClick={() => {}}// @TODO: implement
                >
                  Email parent
                </MenuItem>
                <MenuItem
                  key='TODOb'
                  onClick={() => {}}// @TODO: implement
                >
                  Resend invite
                </MenuItem>
                <MenuItem
                  key='TODOc'
                  onClick={() => {}}// @TODO: implement
                >
                  Approve/reject join code
                </MenuItem>
                */}
                {!!numUnassignedPublishedGoals &&
                  <MenuItem
                    key={`${cs.id}_unassigned_goals`}
                    onClick={() => {
                      setStudentOverflowMenuAnchorEl(null);
                      setIsUnassignedGoalsDialogOpen(true);
                    }}
                  >
                    <Badge
                      badgeContent={
                        <span style={styles.badgeText}>
                          {numUnassignedPublishedGoals}
                        </span>
                      }
                      color='error'
                      invisible={!numUnassignedPublishedGoals}
                    >
                      View unassigned goals
                    </Badge>
                  </MenuItem>
                }
                {cs.status === 'invited' &&
                  <MenuItem
                    key={`${cs.id}_remove_invite`}
                    onClick={() => {
                      dispatch(updateClassroomStudent(classroomId, cs.id, {status: 'rejected'}));
                      setStudentOverflowMenuAnchorEl(null);
                      setSelectedStudentId(null);
                    }}
                  >
                    <span style={styles.dangerous}>Remove pending invitation</span>
                  </MenuItem>
                }
                {/* @TODO: is this something i want to allow once invite is accepted?
                {cs.status === 'active' &&
                  <MenuItem
                    key={`${cs.id}_remove_student`}
                    onClick={() => {}}// @TODO: implement
                  >
                    <span style={styles.dangerous}>Remove from classroom</span>
                  </MenuItem>
                }
                */}
              </Menu>
            </span>
          )
        ),
      },
    ];
  });

  if (!classroom.school.name && _show404) {
    return (
      <WebPageStub>
        <div style={styles.errorTextWrapper}>
          <p style={{...styles.errorText, ...styles.bold}}>This classroom could not be found.</p>
          <p style={styles.errorText}>Please check back later or visit the <Link to='/fundraising'>fundraising page</Link> to search partners.</p>{/* @TODO: update */}
        </div>
      </WebPageStub>
    );
  }

  return (
    <WebPageStub
      hideTabs={true}// @TODO: instead of hiding tabs, should we show different tabs for teacher dashboard? i think we should consider it once this single page becomes too complex
    >
      {/* @TODO: do we want school logos here?
      <WebsiteSection
        title={
          <span style={styles.titleWrapper}>
            {(!Globals.isMobile && school.logo_url) &&
              <a
                style={styles.logoLink}
                href={classroom.school?.website_url}
              >
                <img
                  style={styles.logo}
                  src={classroom.school.logo_url}
                  alt={`${classroom.school.name} logo`}
                />
              </a>
            }
          </span>
        }// @TODO: update...use suspense for this?
      >
        <p>{classroom.school.description}</p>{/* @TODO: update /}
      </WebsiteSection>
      */}

      <WebsiteSection
        style={styles.noVerticalPadding}
        isDarkBackground={false}
      >
        {/* @TODO:
        <Button
          color='primary'
          variant='text'
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/schools/${schoolId}`)}
        >
          Back to school
        </Button>
        */}
        <h1 style={styles.h1}>{classroom.school.name || 'Loading...'}<br />{classroom.name || 'Loading...'}{/* @TODO: update */}</h1>
      </WebsiteSection>

      {classroomId !== 1 &&
        <WebsiteSection
          isDarkBackground={false}
          title={(
            <span style={styles.currentCampaignsTitleRow}>{/* @TODO: new name */}
              <span>Roster</span>
            </span>
          )}
        >
          <OakTable// @TODO: rows shouldn't have pointer on hover unless they're selectable
            tableTitle='Students'
            open={true}// @TODO: default to false, but have badge for notifications if there are students to approve, for example
            headerRowData={[
              <span key='checkbox_and_name'>
                {/* @TODO:
                <Checkbox
                  checked={Object.values(_checkboxStatuses).filter((v) => v).length === classroomStudents.length}
                  indeterminate={Object.values(_checkboxStatuses).some((v) => v) && !Object.values(_checkboxStatuses).every((v) => v)}
                  onChange={(event) => {
                    const indeterminate = Object.values(_checkboxStatuses).some((v) => v);
                    setCheckboxStatuses((curr) => {
                      const next = {};
                      classroomStudents.forEach((cs) => {
                        next[cs.id] = !indeterminate;
                      });
                      return next;
                    });
                  }}
                / > */} Name
              </span>,
              'Date invited',
              'Date joined',
              'Status',
              '',
            ]}// @TODO: some of these should be named better and/or have tooltips
            rowData={studentTableRows}
            // @TODO: onRowPress={(row) => setSelectedStudentId(+row[0].id.split('_')[0])}// @TODO: way too hacky
            filtersJsx={
              <Button
                variant='contained'
                size='small'
                color='secondary'
                onClick={() => setIsInviteDialogOpen(true)}
              >
                Invite students
              </Button>
            }
          />

          <Dialog
            open={_isUnassignedGoalsDialogOpen}
            style={styles.dialog}
            aria-labelledby='alert-dialog-title-todo'
            aria-describedby='alert-dialog-description-todo'
            // @TODO: fullScreen={Globals.isMobile}
            onClose={() => setIsUnassignedGoalsDialogOpen(false)}
          >
            <DialogTitle id='alert-dialog-title-todo'>
              Students with unassigned goals
            </DialogTitle>
            <DialogContent style={styles.dialogContent}>
              {classroomGoalMetadata.filter((cg) => cg.status === 'published').map((cg) => { // @TODO: order in reverse-chronological order
                const goal = goals[cg.goal_id];
                const unassignedStudents = classroomStudents.filter((cs) => {
                  if (cs.status !== 'active') {
                    return false;
                  }

                  return !cg.student_ids_assigned?.includes(cs.user_id);
                });

                if (!unassignedStudents.length) {
                  return null;
                }

                const unassignedStudentsTableRows = unassignedStudents.map((cs) => { // @TODO: convert these to accordion? when expanded, show all info for just this student (and class average)
                  return [ // @TODO: update ids
                    {
                      id: `${cs.id}_display_name`,
                      content: (
                        <span>
                          {cs.user ? cs.user.display_name : cs.display_name}{/* @TODO: if user exists, show cs.display_name on hover for convenience? */}
                        </span>
                      ),
                    }, // @TODO: maybe surround with join date (or invitation date if pending)
                    {
                      id: `${cs.id}_assign_button`,
                      content: (
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => dispatch(assignGoalToStudent(classroomId, goal.id, cs.user_id))}
                        >
                          Assign goal
                        </Button>
                      ),
                    },
                  ];
                });

                return (
                  <OakTable// @TODO: rows shouldn't have pointer on hover unless they're selectable
                    key={goal.id}
                    tableTitle={goal.title}
                    // @TODO: tableSubtitle={goal.date_deadline}
                    open={true}// @TODO: default to false, but have badge for notifications if there are students to approve, for example
                    headerRowData={[
                      'Name',
                      '',
                    ]}// @TODO: some of these should be named better and/or have tooltips
                    rowData={unassignedStudentsTableRows}
                    // @TODO: onRowPress={(row) => setSelectedStudentId(+row[0].id.split('_')[0])}// @TODO: way too hacky
                    filtersJsx={
                      <Button
                        variant='contained'
                        size='small'
                        color='secondary'
                        onClick={() => unassignedStudents.forEach((cs) => dispatch(assignGoalToStudent(classroomId, goal.id, cs.user_id)))}
                      >
                        Assign all
                      </Button>
                    }
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='text'
                autoFocus={false}
                onClick={() => setIsUnassignedGoalsDialogOpen(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <InviteDialog
            schoolId={schoolId}
            classroomId={classroomId}
            isOpen={_isInviteDialogOpen}
            onClose={() => setIsInviteDialogOpen(false)}
          />

        </WebsiteSection>
      }

      <WebsiteSection
        isDarkBackground={true}
        title={(
          <span style={styles.currentCampaignsTitleRow}>{/* @TODO: new name */}
            <span>Goals</span>
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={() => setIsNewGoalDialogOpen(true)}
              startIcon={<AddIcon />}
            >
              New Goal
            </Button>
          </span>
        )}
      >
        <div style={styles.goalsWrapper}>
          {classroomGoalMetadata.map((cg) => <GoalPreview key={cg.id} classroomGoalMetadata={cg} goal={goals[cg.goal_id]} schoolId={schoolId} classroomId={classroomId} />)}{/* @TODO: on click, open edit dialog or new page? */}
        </div>
      </WebsiteSection>

      {/* @TODO:
      <WebsiteSection
        isDarkBackground={false}
        title={(
          <span style={styles.currentCampaignsTitleRow}>{/* @TODO: new name /}
            <span>Resources</span>
          </span>
        )}
      >
        <div>
          TODO: links to help docs
          TODO: info about fundraising campaign
        </div>
      </WebsiteSection>
      */}

      {/* @TODO: if no campaigns for this org, display something like "coming soon?" */}

      {/* @TODO: note if this school has private campaigns "log in to view more (if you're a participant)" */}

      {/* @TODO:
      <WebsiteSection
        title='Results'// @TODO: dyanmic ("progress", "results", and something if it hasn't started yet)
      >
        <p>{"TODO: statistics (nothing too specific though...no money amounts, just maybe wrinkles? or peanuts? or hours and verses reviewed like on the landing page? that's probably the best option...but definitely wrinkles so that sponsors can track it) (if none available yet, hide this section? show something else?)"}</p>
        <p>TODO: testimonials</p>
      </WebsiteSection>
      */}
      <NewGoalDialog
        isOpen={_isNewGoalDialogOpen}
        onClose={() => setIsNewGoalDialogOpen(false)}
        schoolId={schoolId}
        classroomId={classroomId}
      />
    </WebPageStub>
  );
};

export default Classroom;
