/*
 * Ryan O'Dowd
 * 2024-05-24
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import Globals from '../../Globals';
import HandCoinIcon from 'mdi-react/HandCoinIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import {
  Link,
} from 'react-router-dom';
import MenuIcon from 'mdi-react/MenuIcon';
import SchoolIcon from 'mdi-react/SchoolIcon';
import styles from './styles';

const TABS = [
  {
    value: '',
    label: 'Overview',
    linkTo: '/',
    icon: <HomeIcon />,
  },
  {
    value: 'schools',
    label: 'Schools',
    linkTo: '/schools',
    icon: <SchoolIcon />,
  },
  {
    value: 'fundraising',
    label: 'Fundraising',
    linkTo: '/fundraising',
    icon: <HandCoinIcon />,
  },
];

const NavigationTabs = (props) => {
  const initialTabValue = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const [_tabValue, setTabValue] = useState(TABS.map((tab) => tab.value).includes(initialTabValue) ? initialTabValue : false); // @TODO: should be a 404 if it's not / or in TABS @TODO: this isn't always accurate (for example, /organizations/../../..)

  const [_navMenuAnchorEl, setNavMenuAnchorEl] = useState(null);

  if (Globals.isMobile) {
    return (
      <span>
        <Tooltip title='More options'>
          <IconButton
            aria-controls='more-options-menu'
            aria-haspopup='true'
            onClick={(event) => setNavMenuAnchorEl(event.currentTarget)}
          >
            <MenuIcon color='white' />
          </IconButton>
        </Tooltip>
        <Menu
          id='navigation-menu'
          anchorEl={_navMenuAnchorEl}
          keepMounted
          open={!!_navMenuAnchorEl}
          onClose={() => setNavMenuAnchorEl(null)}
        >
          {TABS.map((tab) => {
            return (
              <MenuItem
                key={tab.value}
                component={Link}
                to={tab.linkTo}
                onClick={() => setNavMenuAnchorEl(null)}
              >
                {tab.icon}<span style={styles.iconMenuItemText}>{` ${tab.label}`}</span>
              </MenuItem>
            );
          })}
        </Menu>
      </span>
    );
  }

  return (
    <Tabs
      centered={true}
      value={_tabValue}
      onChange={(event, newValue) => setTabValue(newValue)}
      textColor='secondary'
      indicatorColor={null}
    >
      {TABS.map((tab) => {
        return (
          <Tab
            key={tab.value}
            sx={{...styles.tab, ...(_tabValue === tab.value ? styles.selectedTab : {})}}
            component={Link}
            value={tab.value}
            label={tab.label}
            to={tab.linkTo}
          />
        );
      })}
    </Tabs>
  );
};

export default NavigationTabs;
