/*
 * Ryan O'Dowd
 * 2023-10-26
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import WebsiteSection from '../../../components/WebsiteSection';
import styles from './styles';

const ReviewSection = (props) => {
  const statJsx = (
    <span>
      <span style={styles.statWrapper}>
        {props.children}
      </span>
    </span>
  );

  return ( // @TODO: wrap in skeleton
    <WebsiteSection
      title={props.title}
      // @TODO: isDarkBackground={props.isRightAligned}
      style={styles.websiteSectionOverride}
      innerStyle={props.isRightAligned ? styles.rightWrapper : styles.leftWrapper}
    >{/* @TODO: make sure this looks good on mobile */}
      <span style={styles.contentWrapper}>
        {!props.isRightAligned && statJsx}
        <span style={styles.imageWrapper}>
          <img style={styles.image} src={props.imageSource} />
        </span>
        {props.isRightAligned && statJsx}
      </span>
    </WebsiteSection>
  );
};

ReviewSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  imageSource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,

  isRightAligned: PropTypes.bool,
};

export default ReviewSection;
