/*
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  createAssignment,
  deleteAssignment,
  updateAssignment,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Alert from '../../../common/components/OakAlert';
import AssignmentSettings from '../../../classes/AssignmentSettings';
import DateRange from '../DateRange';
import Globals from '../../../Globals';
import PassageSelection from '../../PassageSelection';
import PropTypes from 'prop-types';
import {
  styled,
} from '@mui/system';
import styles from './styles';

const EditAssignmentDialog = (props) => {
  const dispatch = useDispatch();

  const [_assignmentName, setAssignmentName] = useState(props.isNew ? '' : props.assignment.name);
  const [_dateAvailable, setDateAvailable] = useState(props.assignment?.date_available ? new Date(props.assignment.date_available) : null);
  const [_dateDeadline, setDateDeadline] = useState(props.assignment?.date_deadline ? new Date(props.assignment.date_deadline) : null);
  // @TODO: const [_status, setStatus] = useState(props.assignment?.status || 'draft');
  const [_type, setType] = useState(props.assignment?.assignment_type || '');

  const minigames = useSelector((state) => state.minigames);
  const initialPassage = useSelector((state) => state.passages[props.assignment?.assignment_object?.passage_id] || null);
  const [_passage, setPassage] = useState(initialPassage);
  const [_verseMemoryNumLessonsToComplete, setVerseMemoryNumLessonsToComplete] = useState(props.assignment?.assignment_object?.num_lessons_to_complete || 3);

  const _getInitialGameTypesEnabled = () => {
    const initialEnabled = {};
    Object.values(minigames).forEach((m) => {
      initialEnabled[m.id] = props.assignment?.assignment_object?.game_types_enabled?.includes(m.id) || false;
    });

    return initialEnabled;
  };
  const [_verseMemoryGameTypesEnabled, setVerseMemoryGameTypesEnabled] = useState(_getInitialGameTypesEnabled());
  const [_verseMemoryGameDifficulty, setVerseMemoryGameDifficulty] = useState(props.assignment?.assignment_object?.game_difficulty || 'choice');
  const [_verseMemoryMustMemorize, setVerseMemoryMustMemorize] = useState(props.assignment?.assignment_object?.must_memorize || 0);
  const [_lectureText, setLectureText] = useState(props.assignment?.assignment_object?.text || '');
  const [_videoUrl, setVideoUrl] = useState(props.assignment?.assignment_object?.url || '');

  const [_validationError, setValidationError] = useState('');

  const user = useSelector((state) => state.oakUser);
  const isAdmin = Globals.adminAuthIds.includes(user.auth_id);

  const getMinDateAvailable = () => { // @TODO: abstract with EditModuleDialog
    const goalDateAvailable = props.goal.date_available ? new Date(props.goal.date_available) : null;
    const moduleDateAvailable = props.module.date_available ? new Date(props.module.date_available) : null;
    // @TODO: disallow picking a date before this.date_deadline (in the case of picking deadline and then availablity)

    return new Date(Math.max(...[goalDateAvailable?.getTime(), moduleDateAvailable?.getTime()].filter(Boolean)));
  };

  const getMaxDateAvailable = () => { // @TODO: abstract with EditModuleDialog
    const assignmentDateDeadline = _dateDeadline;
    const goalDateDeadline = props.goal.date_deadline ? new Date(props.goal.date_deadline) : null;
    const moduleDateDeadline = props.module.date_deadline ? new Date(props.module.date_deadline) : null;
    // @TODO: disallow picking a date before this.date_deadline (in the case of picking deadline and then availablity)

    return new Date(Math.min(...[goalDateDeadline?.getTime(), moduleDateDeadline?.getTime(), assignmentDateDeadline?.getTime()].filter(Boolean)));
  };

  const getMinDateDeadline = () => { // @TODO: abstract with EditModuleDialog
    const assignmentDateAvailable = _dateAvailable || new Date(0);
    const goalDateAvailable = props.goal.date_available ? new Date(props.goal.date_available) : null;
    const moduleDateAvailable = props.module.date_available ? new Date(props.module.date_available) : null;

    return new Date(Math.max(...[goalDateAvailable?.getTime(), moduleDateAvailable?.getTime(), assignmentDateAvailable?.getTime()].filter(Boolean)));
  };

  const getMaxDateDeadline = () => { // @TODO: abstract with EditModuleDialog
    const goalDateDeadline = props.goal.date_deadline ? new Date(props.goal.date_deadline) : null;
    const moduleDateDeadline = props.module.date_deadline ? new Date(props.module.date_deadline) : null;

    return new Date(Math.min(...[goalDateDeadline?.getTime(), moduleDateDeadline?.getTime()].filter(Boolean)));
  };

  const _getDescriptionText = (type) => {
    if (type === 'verse_memory') {
      return 'Students must complete lessons and/or memorize the selected verses.'; // @TODO: update
    } else if (type === 'reading') {
      return 'Select verses for students to read.'; // @TODO: update
    } else if (type === 'lecture') {
      return 'Upload custom curriculum text for students to read.'; // @TODO: update
    } else if (type === 'video') {
      return 'Students must watch this video.'; // @TODO: update
    }
  };

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>{props.isNew ? 'Create new assignment' : 'Edit assignment'}</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        {!!_type &&
          <Alert
            type='info'
            text={_getDescriptionText(_type)}
          />
        }

        {props.isNew &&
          <span>
            <span style={styles.headerText}>Assignment type</span>
            <span style={styles.nameWrapper}>
              <FormControl fullWidth>
                <InputLabel id='type'>Assignment type</InputLabel>
                <Select
                  labelId='type'
                  value={_type}
                  label='Assignment type'
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value='verse_memory'>Verse Memory</MenuItem>
                  <MenuItem value='reading'>Reading</MenuItem>
                  <MenuItem value='lecture'>Lecture</MenuItem>
                  {/* @TODO:
                  <MenuItem value='video'>Video</MenuItem>
                  */}
                </Select>
              </FormControl>
            </span>
          </span>
        }

        {!!_type &&
          <React.Fragment>
            <span style={styles.headerText}>Assignment name</span>
            <span style={styles.nameWrapper}>
              <TextField
                value={_assignmentName}
                autoFocus={true}
                fullWidth
                onChange={(e) => setAssignmentName(e.target.value)}
                placeholder='e.g. "My first minigame"'// @TODO: better placeholder
                variant='standard'
              />
            </span>
            <DateRange
              dateAvailable={_dateAvailable}
              dateDeadline={_dateDeadline}
              setDateAvailable={(newValue) => setDateAvailable(newValue)}
              setDateDeadline={(newValue) => setDateDeadline(newValue)}
              minDateAvailable={getMinDateAvailable()}
              maxDateAvailable={getMaxDateAvailable()}
              minDateDeadline={getMinDateDeadline()}
              maxDateDeadline={getMaxDateDeadline()}
            />
            {/* @TODO:
            <FormControl>
              <InputLabel id='status'>Assignment status</InputLabel>
              <Select
                labelId='status'
                value={_status}
                label='Assignment status'
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value='draft'>Draft</MenuItem>{/* @TODO: only an option if new goal? /}
                <MenuItem value='published'>Published</MenuItem>
                <MenuItem value='concluded'>Concluded</MenuItem>{/* @TODO: option to autoconcluded after due date /}
              </Select>
            </FormControl>
            */}
            {_type === 'verse_memory' &&
              <div>
                <span style={styles.headerText}>Assignment settings</span>
                <span style={styles.subHeaderWrapper}>
                  <span style={styles.headerText}>Number of lessons to complete</span>
                  <span style={styles.nameWrapper}>
                    <FormGroup>
                      <NumberInput// @TODO: too wide
                        value={_verseMemoryNumLessonsToComplete}
                        onChange={(event, val) => {
                          setVerseMemoryNumLessonsToComplete(val);
                          if (val === 0) {
                            setVerseMemoryGameTypesEnabled(_getInitialGameTypesEnabled());
                            setVerseMemoryGameDifficulty('choice');
                          }
                        }}
                        min={0}
                        shiftMultiplier={5}
                      />{/* @TODO: tooltip helper */}
                    </FormGroup>
                  </span>

                  <span style={styles.headerText}>Available games</span>
                  <span style={styles.nameWrapper}>
                    <FormGroup>
                      {Object.values(minigames).map((minigame) => {
                        return (
                          <FormControlLabel
                            key={minigame.id}
                            control={
                              <Checkbox
                                disabled={_verseMemoryNumLessonsToComplete === 0}
                                checked={_verseMemoryGameTypesEnabled[minigame.id]}
                                onChange={(e) => setVerseMemoryGameTypesEnabled({..._verseMemoryGameTypesEnabled, [minigame.id]: e.target.checked})}
                              />
                            }
                            label={minigame.name}
                          />
                        );
                      })}
                      {/* @TODO: tooltip helper */}
                    </FormGroup>
                  </span>

                  <span style={styles.headerText}>Lesson difficulty</span>
                  <span style={styles.nameWrapper}>
                    <FormControl>
                      <InputLabel id='type'>Lesson difficulty</InputLabel>
                      <Select
                        labelId='type'
                        value={_verseMemoryGameDifficulty || ''}
                        label='Lesson difficulty'
                        disabled={_verseMemoryNumLessonsToComplete === 0}
                        onChange={(e) => setVerseMemoryGameDifficulty(e.target.value)}
                      >
                        <MenuItem value='choice'>Student choice</MenuItem>
                        <MenuItem value='easy'>Easy</MenuItem>
                        <MenuItem value='medium'>Medium</MenuItem>
                        <MenuItem value='hard'>Hard</MenuItem>
                      </Select>
                    </FormControl>
                  </span>

                  <span style={styles.headerText}>Memorization</span>
                  <span style={styles.nameWrapper}>
                    <FormControlLabel control={<Checkbox checked={!!_verseMemoryMustMemorize} onChange={(e) => setVerseMemoryMustMemorize(e.target.checked)} />} label='Require memorization' />{/* @TODO: tooltip helper */}
                  </span>
                </span>

                <span style={styles.passageWrapper}>
                  {/* @TODO: setting to require specific translation...add this to PassageSelection */}
                  <PassageSelection isPassageLocked={props.classroomGoal.status !== 'draft' && !props.isNew} passage={_passage} onSelect={(p) => setPassage(p)} />
                </span>
              </div>
            }
            {_type === 'reading' &&
              <div>
                <span style={styles.headerText}>Passage</span>
                <span style={styles.nameWrapper}>
                  <PassageSelection isPassageLocked={props.classroomGoal.status !== 'draft' && !props.isNew} passage={_passage} onSelect={(p) => setPassage(p)} />
                </span>
              </div>
            }
            {_type === 'lecture' &&
              <div>
                <span style={styles.headerText}>Lecture text</span>
                <span style={{...styles.nameWrapper, ...styles.lectureTextWrapper}}>
                  <TextField
                    value={_lectureText}
                    onChange={(e) => setLectureText(e.target.value)}
                    fullWidth
                    multiline
                    placeholder='Lecture text'// @TODO: better placeholder
                    variant='standard'
                  />
                </span>
              </div>
            }
            {/* @TODO:
            {_type === 'video' &&
              <div>
                _videoUrl, setVideoUrl
                // @TODO: video preview
              </div>
            }
            <p>TODO: move assignment to a different module</p>
            */}
          </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <p style={styles.errorText}>{_validationError}</p>
        {!props.isNew && props.classroomGoal.status === 'draft' &&
          <Button
            color='error'
            onClick={() => {
              // @TODO: confirm with alert after letting teacher know how permanent this is...show how many kids have started (maybe not an option if progress has been made)
              if (window.confirm('Are you sure you want to delete this assignment?')) { // @TODO: replace with prettier confirm
                dispatch(deleteAssignment(props.classroomGoal.classroom_id, props.goal.id, props.module.id, props.assignment.id));
              }
              props.onClose();
            }}
            variant='text'
          >
            Delete
          </Button>
        }
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        {/* @TODO: button to duplicate assignment */}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            let assignmentSettings = null;
            try {
              assignmentSettings = new AssignmentSettings(isAdmin, _type, _assignmentName, _passage, _verseMemoryNumLessonsToComplete, _verseMemoryGameTypesEnabled, _verseMemoryGameDifficulty, _verseMemoryMustMemorize, _lectureText, _videoUrl).validateAndMarshal();
            } catch (e) {
              setValidationError(e.message);
              return;
            }
            if (props.isNew) {
              // @TODO: make sure all required fields are filled
              dispatch(createAssignment(props.classroomGoal.classroom_id, props.goal.id, props.module.id, _assignmentName, _dateAvailable, _dateDeadline, 'published', _type, assignmentSettings)); // @TODO: this should be only fields that have been updated (compare state variables with props.goal)
            } else {
              dispatch(updateAssignment(props.classroomGoal.classroom_id, props.goal.id, props.assignment.id, _assignmentName, _dateAvailable, _dateDeadline, 'published', _type, assignmentSettings)); // @TODO: this should be only fields that have been updated (compare state variables with props.goal)
            }
            props.onClose(); // @TODO: wait for back-end call to complete?
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

// @TODO: move to other component
const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

// @TODO: move theme stuff outside of here
const StyledInputRoot = styled('div')(
  ({theme}) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInputElement = styled('input')(
  ({theme}) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`,
);

const StyledButton = styled('button')(
  ({theme}) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }
  & .arrow {
    transform: translateY(-1px);
  }
`,
);

EditAssignmentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  module: PropTypes.object.isRequired, // @TODO: shape
  goal: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape

  assignment: PropTypes.object, // @TODO: shape

  isNew: PropTypes.bool,
};

export default EditAssignmentDialog;
