/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Globals from '../../../Globals';
import PropTypes from 'prop-types';
import styles from './styles';

const OakTable = (props) => {
  const [_pageNum, setPageNum] = useState(0);
  const [_numRowsPerPage, setRowsPerPage] = useState(10);

  return (
    <Paper style={styles.container}>
      <Accordion
        style={styles.containerInner}
        defaultExpanded={props.open || props.tableTitle === 'Participant progress'}// @TODO: title is too much business logic
        // @TODO: defaultExpanded={true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <h3 style={styles.h3}>{props.tableTitle}</h3>
          <h5 style={styles.h5}>{props.tableSubtitle}</h5>
        </AccordionSummary>
        <AccordionDetails style={styles.accordionDetails}>
          <TableContainer component={Paper}>{/* @TODO: allow sorting by column values */}
            <Table
              // @TODO: sx={{minWidth: 650}}
              size='small'
              aria-label={props.tableTitle}
            >
              {props.headerRowData &&
                <TableHead>
                  <TableRow>
                    {props.headerRowData.map((headerCellText, index) => {
                      if (index === 0) {
                        return <TableCell key={headerCellText.key || headerCellText} style={styles.headerCell}>{headerCellText}</TableCell>; // @TODO: should all cells have key in props?
                      }

                      return <TableCell key={headerCellText.key || headerCellText} style={styles.headerCell} align='right'>{headerCellText}</TableCell>; // @TODO: should all cells have key in props?
                    })}
                  </TableRow>
                </TableHead>
              }
              <TableBody>
                {props.rowData.slice(_pageNum * _numRowsPerPage, _pageNum * _numRowsPerPage + _numRowsPerPage).map((row) => {
                  return (
                    <TableRow
                      key={`${row[0].id}_row`}
                      style={row.onRowPress ?? styles.pressableRow}
                      sx={
                        {
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                          '&:nth-of-type(odd)': {
                            backgroundColor: Globals.colors.extraLightGray,
                          },
                        }
                      }
                      onClick={() => {
                        props.onRowPress?.(row);
                      }}
                    >
                      {row.map((cell, index) => {
                        if (index === 0) {
                          return <TableCell key={cell.id} style={cell.styles || {}} {...cell.tableCellProps}>{cell.content}</TableCell>;
                        }

                        return <TableCell key={cell.id} align='right' style={cell.styles || {}} {...cell.tableCellProps}>{cell.content}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
                {props.totalsRowData &&
                  <TableRow
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    {props.totalsRowData.map((row, index) => {
                      if (index === 0) {
                        return <TableCell key={row.id} style={styles.totalCell}>{row.content}</TableCell>;
                      }

                      return <TableCell key={row.id} style={styles.totalCell} align='right'>{row.content}</TableCell>;
                    })}
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <div style={styles.filtersWrapper}>
            <div style={styles.filtersLeft}>
              {props.filtersJsx}
            </div>
            <div style={styles.filtersRight}>
              <TablePagination
                style={{...styles.pagination, ...(props.hidePagination ? styles.notVisible : {})}}
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={props.rowData.length}
                rowsPerPage={_numRowsPerPage}
                page={_pageNum}
                onPageChange={(event, newPageNum) => setPageNum(newPageNum)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(+event.target.value);
                  setPageNum(0);
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

OakTable.propTypes = {
  rowData: PropTypes.array.isRequired,
  tableTitle: PropTypes.string.isRequired,

  tableSubtitle: PropTypes.string,
  onRowPress: PropTypes.func,
  filtersJsx: PropTypes.object,
  headerRowData: PropTypes.array,
  hidePagination: PropTypes.bool,
  open: PropTypes.bool,
  totalsRowData: PropTypes.array,
};

OakTable.displayName = 'OakTable';
export default OakTable;
