/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px - 35px - 114px)', // @TODO: header and footer heights; check mobile; don't hardcode; no idea what the 114px is
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    color: Globals.colors.primaryExtraDark,
    // @TODO: marginBottom: 64,
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 32,
    paddingRight: 12,
    paddingBottom: 32,
    paddingLeft: 12,
  },
  sectionInner: {
    width: '100%',
    maxWidth: 1000,
  },
  campaignPreviewsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    // @TODO: overflowX: 'auto', // @TODO: make sure this works if there are more than 3 campaigns
  },
};

export default styles;
